import React from "react";
import { Link, LinkProps } from "wouter";

export const SmartLink: React.FC<LinkProps> = ({ href, children, ...rest }) => {
  if (typeof href === "string" && href.indexOf("://") > -1) {
    return (
      <a href={href} target="_blank">
        {children}
      </a>
    );
  }

  return (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    <Link href={href} {...(rest as any)}>
      {children}
    </Link>
  );
};

import { type ClassValue, clsx } from "clsx";
import { twMerge } from "tailwind-merge";

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

export const filterProps = (
  data: { [k: string]: unknown },
  filterKey?: string[]
): { [p: string]: unknown } =>
  Object.fromEntries(
    Object.entries(data).filter(([key, value]) => (filterKey ? !filterKey.includes(key) : value))
  );

export const parseJwt = (token: string) => {
  try {
    return JSON.parse(atob(token.split(".")[1]));
  } catch (e) {
    return null;
  }
};

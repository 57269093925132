import { IconProps } from ".";

export const AppleIcon: React.FC<IconProps> = (props) => {
  return (
    <svg viewBox="0 0 10 12" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M9.68063 4.09121C9.60938 4.14521 8.35155 4.83768 8.35155 6.37744C8.35155 8.15842 9.9521 8.78848 10 8.80408C9.99263 8.84248 9.74573 9.66697 9.15612 10.5071C8.63039 11.2463 8.08132 11.9844 7.24604 11.9844C6.41076 11.9844 6.1958 11.5104 5.23154 11.5104C4.29186 11.5104 3.95775 12 3.19371 12C2.42968 12 1.89657 11.3159 1.28363 10.4758C0.57364 9.48935 0 7.9568 0 6.50225C0 4.16922 1.55263 2.93189 3.0807 2.93189C3.89264 2.93189 4.56946 3.45275 5.07923 3.45275C5.56443 3.45275 6.32109 2.90069 7.24481 2.90069C7.59489 2.90069 8.85272 2.93189 9.68063 4.09121ZM6.80629 1.91299C7.18831 1.47015 7.45854 0.855686 7.45854 0.241224C7.45854 0.156016 7.45117 0.069607 7.4352 0C6.81366 0.0228023 6.07419 0.40444 5.6283 0.909691C5.27822 1.29853 4.95148 1.91299 4.95148 2.53585C4.95148 2.62946 4.96745 2.72307 4.97482 2.75308C5.01413 2.76028 5.078 2.76868 5.14187 2.76868C5.69955 2.76868 6.40093 2.40384 6.80629 1.91299Z"
        fill="currentColor"
      />
    </svg>
  );
};

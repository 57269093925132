import { useCallback } from "react";
import env from "@/env";
import { useUserTimezone } from "@/hooks/useUserTimezone";
import { formatDateTime, formatDateTimeInTimeZone } from "@/utils/date";

export const useFormattedDateTime = () => {
  const userTimezone = useUserTimezone();

  const formattedDateTime = useCallback(
    (dateTime: unknown) => {
      return env.USER_TIMEZONE_ENABLED
        ? formatDateTimeInTimeZone(dateTime, userTimezone)
        : formatDateTime(dateTime);
    },
    [userTimezone]
  );

  return formattedDateTime;
};

import { PayIcon } from "@/components/Icons/PayIcon";
import { useUserStore } from "@/store/user";
import { BalanceType } from "@/types/user";
import { toLocaleString } from "@/utils/number";
import { cn } from "@/utils/styles";
import { useEffect, useMemo } from "react";
import { IoCoin } from "../IoCoin/IoCoin";

type UserBalanceType = {
  type: BalanceType;
  className?: string;
};

export const UserBalance = ({ type, className = "" }: UserBalanceType) => {
  const balances = useUserStore((store) => store.balances);
  const fetchBalances = useUserStore((store) => store.fetchBalances);
  const balance = useMemo(() => {
    const newBalance = balances?.[type];
    return typeof newBalance === "number" ? toLocaleString(newBalance, 2) : 0;
  }, [balances, type]);

  useEffect(() => {
    (async () => {
      await fetchBalances();
    })();
  }, [fetchBalances]);

  return (
    <div className={cn("flex flex-row items-center gap-2", className)}>
      {type === BalanceType.CLOUD ? (
        <>
          <PayIcon className="h-[8px]" />
          <span className="text-sm font-medium text-cyan">{balance}</span>
        </>
      ) : (
        <>
          <IoCoin />
          <span className="text-sm font-medium text-blue-light-300">{balance}</span>
        </>
      )}
    </div>
  );
};

// $IO <span className="text-gray">14,480.000</span> $SOL{" "}
// <span className="text-gray">501.010</span> $USDC{" "}
// <span className="text-gray">5,114.01</span>

export const DISABLE_INCOMPLETE_FUNCTIONALITY = false;

export const BOX_SHADOW =
  "0px 0px 4px 0px rgba(16, 24, 40, 0.03), 0px 0px 8px 0px rgba(16, 24, 40, 0.04), 0px 0px 3px 0px rgba(0, 0, 0, 0.09)";

export const OPERATING_SYSTEM_OPTIONS = [
  {
    value: "mac",
    label: "Mac OS"
  },
  {
    value: "windows",
    label: "Windows"
  },
  {
    value: "linux",
    label: "Linux"
  }
];

export const DEFAULT_PAGINATION_LIMIT = 20;

export const DEFAULT_ROUTE_AFTER_LOGIN = "/id/account/io-elements";

export const REGISTRATION_ENABLED = false;

export const RESET_PASSWORD_ENABLED = false;

export const MAP_GLOBE_ENABLED = false;

export const REFRESH_REQUEST_ON_INTERVAL_DELAY = 1000 * 90;

export const REFRESH_INFERENCE_REQUEST_ON_INTERVAL_DELAY = 1000 * 10;

export const GRAPH_TYPE_TAB_ITEMS = [
  {
    value: "block_rewards",
    label: "Block Rewards"
  },
  {
    value: "earnings",
    label: "Earnings"
  }
] as {
  value: string;
  label: string;
}[];

export const DURATION_TYPE_TAB_ITEMS = [
  {
    value: "daily",
    label: "Daily"
  },
  {
    value: "monthly",
    label: "Monthly"
  }
] as {
  value: string;
  label: string;
}[];

export const DAILY_EARNING_GRAPH_DAYS_COUNT = 14;

export const TIMEZONES = {
  "Pacific/Midway": "(GMT-11:00) Midway Island",
  "Pacific/Pago_Pago": "(GMT-11:00) Pago Pago",
  "Pacific/Honolulu": "(GMT-10:00) Hawaii",
  "America/Juneau": "(GMT-9:00) Alaska",
  "America/Los_Angeles": "(GMT-7:00) Pacific Time (US & Canada)",
  "America/Tijuana": "(GMT-7:00) Tijuana",
  "America/Denver": "(GMT-6:00) Mountain Time (US & Canada)",
  "America/Phoenix": "(GMT-6:00) Arizona",
  "America/Chihuahua": "(GMT-6:00) Chihuahua",
  "America/Mazatlan": "(GMT-6:00) Mazatlan",
  "America/Chicago": "(GMT-5:00) Central Time (US & Canada)",
  "America/Regina": "(GMT-5:00) Saskatchewan",
  "America/Mexico_City": "(GMT-5:00) Mexico City",
  "America/Monterrey": "(GMT-5:00) Monterrey",
  "America/Guatemala": "(GMT-5:00) Guatemala",
  "America/New_York": "(GMT-4:00) Eastern Time (US & Canada)",
  "America/Indiana/Indianapolis": "(GMT-4:00) Indiana (East)",
  "America/Bogota": "(GMT-4:00) Bogota",
  "America/Lima": "(GMT-4:00) Lima",
  "America/Halifax": "(GMT-3:00) Atlantic Time (Canada)",
  "America/Caracas": "(GMT-3:00) Caracas",
  "America/La_Paz": "(GMT-3:00) La Paz",
  "America/Santiago": "(GMT-3:00) Santiago",
  "America/St_Johns": "(GMT-2:30) Newfoundland",
  "America/Sao_Paulo": "(GMT-2:00) Brasilia",
  "America/Argentina/Buenos_Aires": "(GMT-2:00) Buenos Aires",
  "America/Godthab": "(GMT-2:00) Greenland",
  "Atlantic/South_Georgia": "(GMT-2:00) Mid-Atlantic",
  "Atlantic/Azores": "(GMT-1:00) Azores",
  "Atlantic/Cape_Verde": "(GMT-1:00) Cape Verde Is.",
  "Europe/Dublin": "(GMT+0:00) Dublin",
  "Europe/Lisbon": "(GMT+0:00) Lisbon",
  "Europe/London": "(GMT+0:00) London",
  "Africa/Monrovia": "(GMT+0:00) Monrovia",
  "Atlantic/Reykjavik": "(GMT+0:00) Reykjavik",
  UTC: "(GMT+0:00) UTC",
  "Europe/Amsterdam": "(GMT+1:00) Amsterdam",
  "Europe/Belgrade": "(GMT+1:00) Belgrade",
  "Europe/Berlin": "(GMT+1:00) Berlin",
  "Europe/Bratislava": "(GMT+1:00) Bratislava",
  "Europe/Brussels": "(GMT+1:00) Brussels",
  "Europe/Budapest": "(GMT+1:00) Budapest",
  "Europe/Copenhagen": "(GMT+1:00) Copenhagen",
  "Europe/Ljubljana": "(GMT+1:00) Ljubljana",
  "Europe/Madrid": "(GMT+1:00) Madrid",
  "Europe/Paris": "(GMT+1:00) Paris",
  "Europe/Prague": "(GMT+1:00) Prague",
  "Europe/Rome": "(GMT+1:00) Rome",
  "Europe/Sarajevo": "(GMT+1:00) Sarajevo",
  "Europe/Skopje": "(GMT+1:00) Skopje",
  "Europe/Stockholm": "(GMT+1:00) Stockholm",
  "Europe/Vienna": "(GMT+1:00) Vienna",
  "Europe/Warsaw": "(GMT+1:00) Warsaw",
  "Europe/Zagreb": "(GMT+1:00) Zagreb",
  "Europe/Athens": "(GMT+2:00) Athens",
  "Europe/Bucharest": "(GMT+2:00) Bucharest",
  "Africa/Cairo": "(GMT+2:00) Cairo",
  "Africa/Harare": "(GMT+2:00) Harare",
  "Europe/Helsinki": "(GMT+2:00) Helsinki",
  "Europe/Istanbul": "(GMT+2:00) Istanbul",
  "Asia/Jerusalem": "(GMT+2:00) Jerusalem",
  "Europe/Kiev": "(GMT+2:00) Kyiv",
  "Europe/Minsk": "(GMT+2:00) Minsk",
  "Europe/Riga": "(GMT+2:00) Riga",
  "Europe/Sofia": "(GMT+2:00) Sofia",
  "Europe/Tallinn": "(GMT+2:00) Tallinn",
  "Europe/Vilnius": "(GMT+2:00) Vilnius",
  "Asia/Baghdad": "(GMT+3:00) Baghdad",
  "Asia/Kuwait": "(GMT+3:00) Kuwait",
  "Africa/Nairobi": "(GMT+3:00) Nairobi",
  "Asia/Riyadh": "(GMT+3:00) Riyadh",
  "Europe/Moscow": "(GMT+3:00) Moscow",
  "Asia/Tehran": "(GMT+3:30) Tehran",
  "Asia/Baku": "(GMT+4:00) Baku",
  "Europe/Volgograd": "(GMT+4:00) Volgograd",
  "Asia/Muscat": "(GMT+4:00) Muscat",
  "Asia/Tbilisi": "(GMT+4:00) Tbilisi",
  "Asia/Yerevan": "(GMT+4:00) Yerevan",
  "Asia/Kabul": "(GMT+4:30) Kabul",
  "Asia/Karachi": "(GMT+5:00) Karachi",
  "Asia/Tashkent": "(GMT+5:00) Tashkent",
  "Asia/Kolkata": "(GMT+5:30) Chennai, Kolkata, Mumbai, New Delhi",
  "Asia/Kathmandu": "(GMT+5:45) Kathmandu",
  "Asia/Almaty": "(GMT+6:00) Almaty",
  "Asia/Dhaka": "(GMT+6:00) Dhaka",
  "Asia/Yekaterinburg": "(GMT+6:00) Ekaterinburg",
  "Asia/Rangoon": "(GMT+6:30) Yangon (Rangoon)",
  "Asia/Bangkok": "(GMT+7:00) Bangkok",
  "Asia/Jakarta": "(GMT+7:00) Jakarta",
  "Asia/Novosibirsk": "(GMT+7:00) Novosibirsk",
  "Asia/Chongqing": "(GMT+8:00) Chongqing",
  "Asia/Hong_Kong": "(GMT+8:00) Hong Kong",
  "Asia/Krasnoyarsk": "(GMT+8:00) Krasnoyarsk",
  "Asia/Kuala_Lumpur": "(GMT+8:00) Kuala Lumpur",
  "Australia/Perth": "(GMT+8:00) Perth",
  "Asia/Shanghai": "(GMT+8:00) Shanghai",
  "Asia/Singapore": "(GMT+8:00) Singapore",
  "Asia/Taipei": "(GMT+8:00) Taipei",
  "Asia/Ulaanbaatar": "(GMT+8:00) Ulaan Bataar",
  "Asia/Urumqi": "(GMT+8:00) Urumqi",
  "Asia/Irkutsk": "(GMT+9:00) Irkutsk",
  "Asia/Seoul": "(GMT+9:00) Seoul",
  "Asia/Tokyo": "(GMT+9:00) Tokyo",
  "Australia/Adelaide": "(GMT+9:30) Adelaide",
  "Australia/Darwin": "(GMT+9:30) Darwin",
  "Asia/Yakutsk": "(GMT+10:00) Yakutsk",
  "Australia/Brisbane": "(GMT+10:00) Brisbane",
  "Australia/Canberra": "(GMT+10:00) Canberra",
  "Pacific/Guam": "(GMT+10:00) Guam",
  "Australia/Hobart": "(GMT+10:00) Hobart",
  "Australia/Melbourne": "(GMT+10:00) Melbourne",
  "Pacific/Port_Moresby": "(GMT+10:00) Port Moresby",
  "Australia/Sydney": "(GMT+10:00) Sydney",
  "Asia/Vladivostok": "(GMT+11:00) Vladivostok",
  "Asia/Magadan": "(GMT+12:00) Magadan",
  "Pacific/Auckland": "(GMT+12:00) Auckland",
  "Pacific/Fiji": "(GMT+12:00) Fiji"
} as Record<string, string>;

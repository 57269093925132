import { RayClusterIcon } from "@/components/Icons/RayClusterIcon";
import { LudwigClusterIcon } from "@/components/Icons/LudwigClusterIcon";
import { ClusterType } from "@/types";
import { KubernetesIcon } from "@/components/Icons/KubernetesIcon";
import { IoIcon } from "@/components/Icons/IoIcon";
import { PlyTorchIcon } from "@/components/Icons/PlyTorchIcon";
import { UnityIcon } from "@/components/Icons/UnityIcon";
import { UnrealIcon } from "@/components/Icons/Unrealcon";
import env from "@/env";

export const CLUSTER_ITEMS = [
  {
    value: ClusterType.RAY,
    title: "Ray",
    subtitle: "Deploy a Decentralized Ray Cluster",
    icon: RayClusterIcon,
    url: "/cloud/clusters/create-cluster",
    disabled: !env.FEATUREFLAG_CLUSTER_DEPLOYMENT_ENABLED
  },
  {
    value: ClusterType.MEGA,
    title: "Mega-Ray",
    subtitle: "Deploy a Decentralized Mega-Ray Cluster",
    icon: RayClusterIcon,
    url: "/cloud/mega-clusters/create-cluster",
    disabled: !env.FEATUREFLAG_CLUSTER_DEPLOYMENT_ENABLED
  },
  {
    value: ClusterType.KUBERNETES,
    title: "Kubernetes",
    subtitle: "Deploy a Decentralized Kubernetes Cluster",
    icon: KubernetesIcon,
    url: "/cloud/kubernetes-clusters/create-cluster",
    disabled: true
  },
  {
    value: ClusterType.LUDWIG,
    title: "Ludwig",
    subtitle: "Declarative deep learning framework built for scale and efficiency.",
    icon: LudwigClusterIcon,
    url: "/cloud/clusters/create-cluster",
    disabled: true
  },
  {
    value: ClusterType.RAY_APP,
    title: "Ray LLM",
    subtitle: "LLM serving at scale built on Ray Serve.",
    icon: RayClusterIcon,
    url: "/cloud/clusters/create-cluster",
    disabled: true
  },
  {
    value: "pytorch",
    title: "PyTorch FSDP",
    icon: PlyTorchIcon,
    url: "/cloud/clusters/create-cluster",
    disabled: true
  },
  {
    value: "ionative",
    title: "IO Native App",
    icon: IoIcon,
    url: "/cloud/clusters/create-cluster",
    disabled: true
  },
  {
    value: "unrealengine5",
    title: "Unreal Engine 5",
    icon: UnrealIcon,
    url: "/cloud/clusters/create-cluster",
    disabled: true
  },
  {
    value: "unitystreaming",
    title: "Unity Streaming",
    icon: UnityIcon,
    url: "/cloud/clusters/create-cluster",
    disabled: true
  }
];

export const RESTRICT_TO_SINGLE_LOCATION = false;

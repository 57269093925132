import { FC, useEffect, useState } from "react";
import { CreateClusterButton } from "@/components/HomePage/CreateClusterButton/CreateClusterButton";
import { UserButton } from "../UserButton/UserButton";
import { MainLogo } from "../MainLogo/MainLogo";
import { MainMenu } from "../MainMenu/MainMenu";
import { useMainStore } from "@/store/main";
import { UserNotificationsButton } from "../UserNotificationsButton/UserNotificationsButton";
import { CreateNewDeviceButton } from "../CreateNewDeviceButton/CreateNewDeviceButton";
import { MobileNav } from "../MobileNav/MobileNav";
import { AuthMachineContext } from "@/store/machine/authMachine/authMachine";
import { BalanceType } from "@/types/user";
import env from "@/env";
import { Button } from "@/components/ui/button";
import { UserBalance } from "../UserBalance/UserBalance";
import { useLocation } from "wouter";
import { AuthEventType } from "@/types/auth";
import { TermsAndConditionsModal } from "@/components/Common/TermsAndConditionsModal/TermsAndConditionsModal";
import { trackEvent } from "@/utils/analytics.ts";
import { AnalyticsEventType } from "@/constants/analytics";

export const MainHeader: FC = () => {
  const [, setLocation] = useLocation();
  const tool = useMainStore((state) => state.tool);
  const { value } = tool || {};
  const [isTermsAndConditionsOpen, setIsTermsAndConditionsOpen] = useState(false);
  const authActorRef = AuthMachineContext.useActorRef();
  const profile = AuthMachineContext.useSelector((state) => state.context.profile);
  const { user_metadata: meta, user_id, email } = profile ?? {};

  useEffect(() => {
    if (meta && meta?.approved_terms_version !== env.LATEST_APPROVED_TERMS_VERSION) {
      setIsTermsAndConditionsOpen(true);
    }
  }, [authActorRef, meta, meta?.approved_terms_version]);

  const handleCloseTermsAndConditions = (isConfirmed: boolean) => {
    setIsTermsAndConditionsOpen(false);

    if (!isConfirmed) {
      authActorRef.send({ type: AuthEventType.LOGOUT });
      return;
    }

    authActorRef.send({
      type: AuthEventType.SET_METADATA,
      value: { approved_terms_version: env.LATEST_APPROVED_TERMS_VERSION }
    });

    trackEvent(AnalyticsEventType.AGREED_TERMS_UPDATE, {
      email,
      userId: user_id,
      time: new Date().toISOString()
    });
  };

  const isLoggedIn = AuthMachineContext.useSelector((state) => state.context.isLoggedIn);

  // if (!isLoggedIn && tool.value !== "explorer") {
  //   return (
  //     <div className="flex flex-row border-b border-gray-dark-900 bg-gray-dark-600 py-6 pl-6 lg:pl-20">
  //       <IoNetIcon className="h-[14px] text-white" />
  //     </div>
  //   );
  // }

  return (
    <>
      <TermsAndConditionsModal
        isOpen={isTermsAndConditionsOpen}
        onClose={handleCloseTermsAndConditions}
      />
      <div className="sticky top-0 z-[2] bg-gray-dark-600">
        <div
          className="flex flex-row items-center gap-3 px-6 py-3 pl-5 dark:border-b-gray-dark-500"
          data-testid={"MainHeader_wrapper"}
        >
          <MainLogo />
          <div className="flex-1"></div>
          {(value === "cloud" || value === "worker") && (
            <div className="hidden items-center gap-3 lg:flex">
              {value === "cloud" && (
                <>
                  <UserBalance type={BalanceType.IOCLOUD} />
                  <div className="h-[12px] w-[1px] bg-gray-dark-1200"></div>
                  <UserBalance type={BalanceType.CLOUD} />
                </>
              )}
              {value === "worker" && <UserBalance type={BalanceType.IOWORKER} />}
            </div>
          )}
          {env.FRESHDESK_ENABLED && (
            <Button
              className="max-md:hidden"
              size="xs"
              variant="outline"
              onClick={() => {
                window.open("https://support.io.net", "_blank");
              }}
            >
              Support Portal
            </Button>
          )}
          {isLoggedIn && (
            <Button
              className="max-md:hidden"
              size="xs"
              variant="outline"
              onClick={() => setLocation("/id/funds")}
            >
              Manage funds
            </Button>
          )}
          {value === "cloud" && (
            <div className="hidden lg:block">
              {env.FEATUREFLAG_CLUSTER_DEPLOYMENT_ENABLED && <CreateClusterButton />}
            </div>
          )}
          {value === "worker" && (
            <>
              <span className="hidden h-[16px] w-[1px] bg-gray-dark-1200 lg:flex"></span>
              <div className="hidden pr-3 lg:block">
                <CreateNewDeviceButton />
              </div>
            </>
          )}
          <a
            href="https://docs.io.net/"
            target="_blank"
            className="text-gray transition-colors hover:text-white max-lg:hidden"
          >
            Docs
          </a>
          {isLoggedIn && (
            <>
              <div className="px-3">
                <UserNotificationsButton />
              </div>
              <div className="hidden pr-3 lg:block">
                <UserButton />
              </div>
            </>
          )}
          <div className="lg:hidden">
            <MobileNav />
          </div>
        </div>
        <MainMenu />
      </div>
    </>
  );
};

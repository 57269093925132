export const ucfirst = (value: string) => {
  if (typeof value !== "string") {
    return "";
  }
  return value.slice(0, 1).toUpperCase() + value.slice(1);
};

export const pad = (value: string) => {
  if (value.length < 2) {
    return `0${value}`;
  }
  return value;
};

export const slugify = (str: string) => {
  return String(str)
    .normalize("NFKD") // split accented characters into their base characters and diacritical marks
    .replace(/[\u0300-\u036f]/g, "") // remove all the accents, which happen to be all in the \u03xx UNICODE block.
    .trim() // trim leading or trailing whitespace
    .toLowerCase() // convert to lowercase
    .replace(/[^a-z0-9 -]/g, "") // remove non-alphanumeric characters
    .replace(/\s+/g, "-") // replace spaces with hyphens
    .replace(/-+/g, "-"); // remove consecutive hyphens
};

export const pluralize = (word: string, count: number | undefined) => {
  return `${count} ${word}${count === 1 ? "" : "s"}`;
};

export const pluralizeWord = (word: string, count: number | string | undefined) => {
  const numericCount = typeof count === "string" ? parseInt(count, 10) : count;
  return `${word}${numericCount === 1 ? "" : "s"}`;
};

export const getQueryParams = (queryString: string) => {
  const urlParams = new URLSearchParams(queryString);

  const entries = urlParams.entries();
  const queryParams = {} as Record<string, string>;
  for (const entry of entries) {
    queryParams[entry[0]] = entry[1];
  }
  return queryParams;
};

import React, { ComponentType, lazy } from "react";
import { ContentLoader } from "../ContentLoader/ContentLoader";

const modules = import.meta.glob("../../../pages/**/*.tsx");

type LazyComponentProps = { path: string };

export const LazyComponent: React.FC<LazyComponentProps> = ({ path, ...rest }) => {
  const [Component, setComponent] = React.useState(() => ContentLoader);

  React.useEffect(() => {
    setComponent(
      lazy(
        modules[`../../../pages/${path}.tsx`] as () => Promise<{ default: ComponentType<unknown> }>
      )
    );
  }, [path]);

  return (
    <React.Suspense fallback={<ContentLoader />}>
      <Component {...rest} />
    </React.Suspense>
  );
};

import { IconProps } from ".";

export const IoIcon: React.FC<IconProps> = (props) => {
  return (
    <svg viewBox="0 0 27 12" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M19.1435 0.102661H2.94916L6.99358 2.88231H19.1435C19.6151 2.88231 20.0034 3.26817 20.0034 3.74217V5.81679L22.783 7.72705V3.74217C22.783 1.74139 21.1467 0.102661 19.1435 0.102661Z"
        fill="currentColor"
      />
      <path
        d="M10.636 11.8977H26.8303L22.7859 9.11806H10.636C10.1644 9.11806 9.77611 8.72981 9.77611 8.2582V6.18358L6.99646 4.27332V8.2582C6.99646 10.2637 8.63043 11.8977 10.636 11.8977Z"
        fill="currentColor"
      />
      <path d="M2.94933 4.27332H0.169678V11.8977H2.94933V4.27332Z" fill="currentColor" />
      <path
        d="M0.169678 0.102417V2.48429L2.85167 4.32787L2.94933 4.27309V0.102417H0.169678Z"
        fill="currentColor"
      />
    </svg>
  );
};

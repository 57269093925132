import env from "@/env";
import { TOKEN_PROGRAM_ID } from "@solana/spl-token";
import { PublicKey } from "@solana/web3.js";
import CryptoJS from "crypto-js";

export const PROGRAM_ID = env.STAKING_CONTRACT_ADDRESS;
export const SUPER_ADMIN_PUBKEY = env.STAKING_SUPER_ADMIN_PUBLIC_KEY;
export const LAMPORTS_PER_SOL_IO_TOKEN = env.STAKING_NETWORK === "devnet" ? 1000000000 : 100000000;

export const convertToLamport = (amount: string) => {
  const numberAmount = Number(amount);
  return (numberAmount * LAMPORTS_PER_SOL_IO_TOKEN).toString();
};

export const convertLamportBack = (amount: string | number) => {
  const numberAmount = Number(amount);
  return (numberAmount / LAMPORTS_PER_SOL_IO_TOKEN).toString();
};

export const findMerkleRootStateAccount = async (): Promise<PublicKey> => {
  const programId = new PublicKey(PROGRAM_ID);
  const seeds = [Buffer.from("merkle-root")];
  const [key] = PublicKey.findProgramAddressSync(seeds, programId);
  return key;
};

export const findGlobalVaultTokenAccount = (): PublicKey => {
  const programId = new PublicKey(PROGRAM_ID);
  const seeds = [Buffer.from("global-vault")];
  const [key] = PublicKey.findProgramAddressSync(seeds, programId);
  return key;
};

export const findGlobalAccount = (): PublicKey => {
  const programId = new PublicKey(PROGRAM_ID);
  const seeds = [Buffer.from("global-owner")];
  const [key] = PublicKey.findProgramAddressSync(seeds, programId);
  return key;
};

export const findPoolInfoAccount = (device_uuid: string): PublicKey => {
  const programId = new PublicKey(PROGRAM_ID);

  const hashedDeviceUUID = CryptoJS.SHA256(device_uuid);

  const hashedDeviceUUIDBytes = CryptoJS.enc.Hex.parse(
    hashedDeviceUUID.toString(CryptoJS.enc.Hex)
  ).words.slice(0, 8);

  const buffer = new ArrayBuffer(32);
  const view = new DataView(buffer);

  for (let i = 0; i < hashedDeviceUUIDBytes.length; i++) {
    view.setUint32(i * 4, hashedDeviceUUIDBytes[i], false);
  }

  const hashedDeviceUUIDBuffer = Buffer.from(buffer);

  const seeds = [Buffer.from("pool-info"), Buffer.from(hashedDeviceUUIDBuffer)];

  const [key] = PublicKey.findProgramAddressSync(seeds, programId);
  return key;
};

export const findStakeInfoAccount = (pool: PublicKey, user: PublicKey): PublicKey => {
  const programId = new PublicKey(PROGRAM_ID);
  const seeds = [Buffer.from("stake-info"), pool.toBuffer(), user.toBuffer()];
  const [key] = PublicKey.findProgramAddressSync(seeds, programId);
  return key;
};

export const findAccount = async (seeds: Buffer[]): Promise<PublicKey> => {
  const programId = new PublicKey(PROGRAM_ID);

  const [key] = PublicKey.findProgramAddressSync(seeds, programId);
  return key;
};

export const getUserAssociatedTokenAccount = async (wallet: PublicKey, mint: PublicKey) => {
  const SPL_ASSOCIATED_TOKEN_ACCOUNT_PROGRAM_ID = new PublicKey(
    "ATokenGPvbdGVxr1b2hvZbsiqW5xWH25efTNsLJA8knL"
  );

  const seeds = [wallet.toBuffer(), TOKEN_PROGRAM_ID.toBuffer(), mint.toBuffer()];

  return PublicKey.findProgramAddressSync(seeds, SPL_ASSOCIATED_TOKEN_ACCOUNT_PROGRAM_ID)[0];
};

import { getAuthActor, getAuthContext } from "@/store/machine/authMachine/authMachine";
import { AuthEventType } from "@/types/auth";
import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from "axios";
import axiosRetry from "axios-retry";
import { stringify } from "qs";
import { getRefreshToken } from "./auth0";
import { sleep } from ".";
import env from "@/env";

const API_REQUEST_RETRY_LIMIT = 2;

let token: string | undefined = undefined;

export const setToken = (newToken: string | undefined) => {
  token = newToken;
};

axiosRetry(axios, {
  retries: API_REQUEST_RETRY_LIMIT,
  retryCondition(error) {
    switch (error?.response?.status) {
      case 404:
      case 400:
      case 422:
        return false;
      default:
        return true;
    }
  },
  shouldResetTimeout: true
});

export const toFriendlyPublicError = (e: unknown) => {
  if (e instanceof AxiosError) {
    const errorMessage = e.response?.data?.error || e.response?.data?.detail;

    if (errorMessage && typeof errorMessage === "string") {
      return new Error(errorMessage);
    }
  }

  return e as Error;
};

export const toFriendlyError = (e: unknown) => {
  if (e instanceof AxiosError) {
    const errorMessage = e.response?.data?.error || e.response?.data?.detail;

    if (errorMessage && typeof errorMessage === "string") {
      return new Error(errorMessage);
    }

    if (Array.isArray(errorMessage)) {
      return new Error();
    }

    const errorMessageFromCode = getErrorMessageFromCode(e.code);
    if (errorMessageFromCode) {
      return new Error(errorMessageFromCode);
    }

    return new Error("We are currently experiencing high load. Please try again later.");
  }

  return new Error("Something went wrong. Please try again or come back later.");
};

export const executeAPIRequest = async <T>({
  method,
  url,
  options
}: {
  method: "get" | "post" | "delete" | "put";
  url: string;
  options?: {
    data?: unknown;
    errorPrefix?: string;
    abortController?: AbortController;
    timeout?: number;
    headers?: Record<string, unknown>;
    publicError?: boolean;
    axiosRetries?: number;
  };
}) => {
  const forceFail = !url.startsWith("/auth/user-uuid");
  const { abortController, timeout, publicError, axiosRetries } = options || {};
  const fullUrl = `${env.API_URL}${forceFail ? `${url}` : url}`;
  const headers = {
    ...options?.headers,
    Token: token
  };
  const config = {
    headers,
    signal: abortController?.signal,
    timeout: typeof timeout === "number" ? timeout : env.API_DEFAULT_TIMEOUT,
    ...(typeof axiosRetries === "number"
      ? {
          "axios-retry": {
            retries: axiosRetries
          }
        }
      : {})
  } as AxiosRequestConfig;

  try {
    let response: AxiosResponse<T>;

    switch (method) {
      case "delete":
        response = await axios.delete(fullUrl, config);
        break;

      case "put":
      case "post":
        response = await axios[method](fullUrl, options?.data, config);
        break;
      default:
        response = await axios.get(fullUrl, config);
        break;
    }

    return response.data as T;
  } catch (e) {
    if (publicError) {
      throw e;
    }
    throw toFriendlyError(e);
  }
};
const ERROR_CODE_MAP = {
  ERR_NAME_NOT_RESOLVED: "Unable to resolve domain",
  [AxiosError.ERR_NETWORK]: "Network problem",
  [AxiosError.ERR_FR_TOO_MANY_REDIRECTS]: "ERR_FR_TOO_MANY_REDIRECTS error",
  [AxiosError.ERR_BAD_OPTION_VALUE]: "ERR_BAD_OPTION_VALUE error",
  [AxiosError.ERR_BAD_OPTION]: "ERR_BAD_OPTION error",
  [AxiosError.ERR_DEPRECATED]: "ERR_DEPRECATED error",
  [AxiosError.ERR_BAD_RESPONSE]: "ERR_BAD_RESPONSE error",
  [AxiosError.ERR_BAD_REQUEST]: "ERR_BAD_REQUEST error",
  [AxiosError.ECONNABORTED]: "ECONNABORTED error",
  [AxiosError.ETIMEDOUT]: "Request timed out",
  [AxiosError.ERR_CANCELED]: "Request cancelled"
};

const getErrorMessageFromCode = (code: string | undefined): string | undefined => {
  return ERROR_CODE_MAP[code?.toUpperCase() as keyof typeof ERROR_CODE_MAP];
};

const errorMessageMap = {
  "Error getting user email from external provider":
    "Please use an account that has an email associated to it."
};

export const toFriendlyErrorMessage = (message: string) => {
  if (message in errorMessageMap) {
    return errorMessageMap[message as keyof typeof errorMessageMap];
  }
  return message;
};

let isLoggingOut = false;

axios.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    if (["ERR_CANCELED"].indexOf(error.code) > -1) {
      return Promise.reject(error);
    }

    const context = getAuthContext();
    const { status } = error.response || {};

    // Status code is 401 try to refresh token
    if (context.isLoggedIn && status === 401 && !isLoggingOut) {
      try {
        const refreshToken = await getRefreshToken();

        getAuthActor().send({
          type: AuthEventType.SET_TOKEN,
          value: refreshToken
        });

        await sleep(200);

        return Promise.reject(error);
      } catch (e) {
        console.log(e);
      }

      // If unable to refresh token log user out
      try {
        isLoggingOut = true;

        getAuthActor().send({
          type: AuthEventType.LOGOUT,
          value: `/login?${stringify({
            errorTitle: "io.net",
            errorMessage: "Your session has expired. Please sign back in."
          })}`
        });
      } catch (e) {
        console.log(e);
      }
    }

    return Promise.reject(error);
  }
);

import { useQueryParams } from "@/hooks/useQueryParams";
import { useEffect, useState } from "react";
import { AlertModal } from "../AlertModal/AlertModal";
import { useLocation } from "wouter";

export const HelpViewer = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [title, setTitle] = useState<React.ReactNode>();
  const [description, setDescription] = useState<React.ReactNode>();
  const { help } = useQueryParams<{
    help?: string;
  }>();
  const [location, setLocation] = useLocation();

  useEffect(() => {
    const content = HELP_TEXT_MAP[help as keyof typeof HELP_TEXT_MAP];
    setTitle(content?.[0]);
    setDescription(content?.[1]);
    setIsOpen(!!content);
  }, [help]);

  return (
    <AlertModal
      isOpen={isOpen}
      onClose={() => {
        setIsOpen(false);
        setLocation(location);
      }}
      title={title}
      description={description}
      submitLabel="Okay"
    />
  );
};

const HELP_TEXT_MAP = {
  "support-chat": [
    "Chat to our Team!",
    <>
      To access support please login and click the icon bottom right to open Intercom. If you are
      unable to login please send an email to{" "}
      <a href="mailto:support@io.net" className="text-orange">
        support@io.net
      </a>
    </>
  ]
};

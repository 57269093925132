import { IconProps } from ".";

export const InferenceIcon: React.FC<IconProps> = (props) => {
  return (
    <svg viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <rect x="0.333008" y="0.166504" width="5.92923" height="5.92923" rx="1" fill="currentColor" />
      <rect x="0.333008" y="6.9043" width="5.92923" height="5.92923" rx="1" fill="currentColor" />
      <rect x="7.0708" y="6.9043" width="5.92923" height="5.92923" rx="1" fill="currentColor" />
      <rect
        x="7.0708"
        y="0.166504"
        width="5.92923"
        height="5.92923"
        rx="2.96462"
        fill="currentColor"
      />
    </svg>
  );
};

import env from "@/env";
import { addExternalScript } from ".";

declare global {
  interface Window {
    fwSettings: {
      widget_id: number;
    };
    FreshworksWidget: (
      action: string,
      id?: string,
      options?: Record<string, unknown>,
      formOptions?: Record<string, unknown>
    ) => void;
  }
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const initialiseFreshdesk = async (_options: {
  userId: string;
  name?: string;
  email?: string;
  createdAt?: number;
}) => {
  if (!env.FRESHDESK_ENABLED) {
    return;
  }
  // initialiseFreshdeskWidget(options);
};

export const initialiseFreshdeskWidget = async (options: {
  userId: string;
  name?: string;
  email?: string;
  createdAt?: number;
}) => {
  const { name, email } = options;

  try {
    window.fwSettings = {
      widget_id: env.FRESHDESK_WIDGET_ID
    };

    if ("function" != typeof window.FreshworksWidget) {
      const n: {
        (): void;
        q?: unknown[];
      } = function (...rest) {
        (n.q as unknown[]).push(rest);
      };
      n.q = [];
      window.FreshworksWidget = n;

      // https://developers.freshdesk.com/widget-api/
      addExternalScript(`https://widget.freshworks.com/widgets/${env.FRESHDESK_WIDGET_ID}.js`);
    }

    window.FreshworksWidget(
      "identify",
      "ticketForm",
      {
        ...options,
        name,
        email
      },
      {}
    );
  } catch (e) {
    console.log(e);
  }
};

export const shutdownFreshdesk = async () => {
  if (!env.FRESHDESK_ENABLED) {
    return;
  }

  try {
    window?.FreshworksWidget("destroy");
  } catch (e) {
    console.log(e);
  }
};

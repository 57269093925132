import { create } from "zustand";
import { VisibleError } from "@/types/index";

type UseNotificationStoreProps = {
  currentError?: VisibleError;
  displayError: (error: VisibleError) => void;
};

export const useNotificationStore = create<UseNotificationStoreProps>((set) => ({
  displayError: (error: VisibleError) => {
    set({ currentError: error });
  }
}));

import { IconProps } from ".";

export const ExplorerIcon: React.FC<IconProps> = (props) => {
  return (
    <svg viewBox="0 0 97 8" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M0.857712 5.63399C0.857712 6.6739 1.41832 7.24291 2.46664 7.24291H9.35356C9.47689 7.24291 9.54696 7.31298 9.54696 7.43632V7.8007C9.54696 7.92683 9.47689 7.99411 9.35356 7.99411H2.29564C0.824078 7.99411 0 7.15881 0 5.68725V2.30685C0 0.835284 0.824078 0 2.29564 0H9.35356C9.47689 0 9.54696 0.0700753 9.54696 0.193407V0.557791C9.54696 0.683925 9.47689 0.751198 9.35356 0.751198H2.46664C1.41552 0.751198 0.857712 1.323 0.857712 2.36011V3.61585H8.4398C8.56593 3.61585 8.6332 3.68592 8.6332 3.80925V4.17364C8.6332 4.29977 8.56313 4.36705 8.4398 4.36705H0.857712V5.63399Z"
        fill="currentColor"
      />
      <path
        d="M17.3818 4.42373L13.4772 7.8826C13.3847 7.96389 13.3174 7.99753 13.1464 7.99753H12.541C12.4261 7.99753 12.3812 7.84897 12.4709 7.76769L16.7651 3.98926L12.5158 0.233265C12.4233 0.151978 12.4709 0.00341797 12.5858 0.00341797H13.2249C13.4071 0.00341797 13.466 0.0370548 13.5557 0.118341L17.4154 3.54359L21.2975 0.118341C21.3788 0.0370548 21.4461 0.00341797 21.6171 0.00341797H22.2225C22.3486 0.00341797 22.3823 0.151978 22.2926 0.233265L18.0461 3.97806L22.3374 7.76769C22.4187 7.84897 22.3935 7.99753 22.2926 7.99753H21.5946C21.4461 7.99753 21.39 7.96389 21.3003 7.8826L17.3818 4.42373Z"
        fill="currentColor"
      />
      <path
        d="M25.4591 0.00341797H32.276C33.8737 0.00341797 34.7314 0.631291 34.7314 2.20657V2.74474C34.7314 4.32001 33.8765 4.94789 32.276 4.94789H26.1318V7.80132C26.1318 7.92746 26.0646 7.99473 25.9384 7.99473H25.4591C25.333 7.99473 25.2657 7.92465 25.2657 7.80132V0.196825C25.2629 0.0734932 25.333 0.00341797 25.4591 0.00341797ZM32.161 0.757421H26.1318V4.19388H32.161C33.3944 4.19388 33.8737 3.737 33.8737 2.72232V2.24301C33.8737 1.21431 33.3944 0.757421 32.161 0.757421Z"
        fill="currentColor"
      />
      <path
        d="M40.0775 7.24353H46.6309C46.757 7.24353 46.8243 7.31361 46.8243 7.43694V7.80132C46.8243 7.92746 46.7542 7.99473 46.6309 7.99473H39.9177C38.4461 7.99473 37.6108 7.15944 37.6108 5.68787V0.196825C37.6108 0.0706903 37.6809 0.00341797 37.8183 0.00341797H38.2864C38.4097 0.00341797 38.4798 0.0734932 38.4798 0.196825V5.63181C38.477 6.67172 39.0376 7.24353 40.0775 7.24353Z"
        fill="currentColor"
      />
      <path
        d="M49.7021 5.69067V2.31028C49.7021 0.83871 50.5234 0.00341797 51.9978 0.00341797H57.3991C58.8735 0.00341797 59.706 0.83871 59.706 2.31028V5.69067C59.706 7.16224 58.8735 7.99753 57.3991 7.99753H51.9978C50.5234 7.99753 49.7021 7.16224 49.7021 5.69067ZM58.8371 5.63461V2.36914C58.8371 1.32923 58.2793 0.760227 57.2394 0.760227H52.1688C51.1177 0.760227 50.5571 1.33203 50.5571 2.36914V5.63461C50.5571 6.67452 51.1177 7.24353 52.1688 7.24353H57.2394C58.2765 7.24353 58.8371 6.67172 58.8371 5.63461Z"
        fill="currentColor"
      />
      <path
        d="M63.2582 7.99717H62.7789C62.6527 7.99717 62.5854 7.92709 62.5854 7.80376V0.199266C62.5854 0.0731317 62.6555 0.00585938 62.7789 0.00585938H69.6293C71.227 0.00585938 72.096 0.566455 72.096 2.14173V2.4837C72.096 3.60209 71.6503 4.21874 70.8066 4.47101C71.6531 4.73449 72.096 5.33713 72.096 6.45833V7.80657C72.096 7.9327 72.0259 7.99997 71.9026 7.99997H71.4345C71.3083 7.99997 71.2411 7.9299 71.2411 7.80657V6.35742C71.2411 5.31751 70.7505 4.84942 69.4808 4.84942H63.4516V7.80657C63.4516 7.9271 63.3843 7.99717 63.2582 7.99717ZM71.2411 2.19499C71.2411 1.20273 70.773 0.757057 69.5509 0.757057H63.4516V4.08981H69.5481C70.7702 4.08981 71.2383 3.65534 71.2383 2.65188V2.19499H71.2411Z"
        fill="currentColor"
      />
      <path
        d="M75.8323 5.63399C75.8323 6.6739 76.3929 7.24291 77.4412 7.24291H84.3254C84.4515 7.24291 84.5188 7.31298 84.5188 7.43632V7.8007C84.5188 7.92683 84.4487 7.99411 84.3254 7.99411H77.2703C75.7987 7.99411 74.9746 7.15881 74.9746 5.68725V2.30685C74.9746 0.835284 75.7987 0 77.2703 0H84.3282C84.4543 0 84.5216 0.0700753 84.5216 0.193407V0.557791C84.5216 0.683925 84.4515 0.751198 84.3282 0.751198H77.444C76.3929 0.751198 75.8351 1.323 75.8351 2.36011V3.61585H83.4172C83.5433 3.61585 83.6106 3.68592 83.6106 3.80925V4.17364C83.6106 4.29977 83.5433 4.36705 83.4172 4.36705H75.8351V5.63399H75.8323Z"
        fill="currentColor"
      />
      <path
        d="M88.072 7.99717H87.5927C87.4665 7.99717 87.3965 7.92709 87.3965 7.80376V0.199266C87.3965 0.0731317 87.4665 0.00585938 87.5927 0.00585938H94.4432C96.0437 0.00585938 96.9098 0.566455 96.9098 2.14173V2.4837C96.9098 3.60209 96.4641 4.21874 95.6204 4.47101C96.4669 4.73449 96.9098 5.33713 96.9098 6.45833V7.80657C96.9098 7.9327 96.8397 7.99997 96.7164 7.99997H96.2483C96.125 7.99997 96.0549 7.9299 96.0549 7.80657V6.35742C96.0549 5.31751 95.5644 4.84942 94.2946 4.84942H88.2654V7.80657C88.2654 7.9271 88.1981 7.99717 88.072 7.99717ZM96.0549 2.19499C96.0549 1.20273 95.5868 0.757057 94.3647 0.757057H88.2682V4.08981H94.3647C95.5868 4.08981 96.0549 3.65534 96.0549 2.65188V2.19499Z"
        fill="currentColor"
      />
    </svg>
  );
};

import ReactDOM from "react-dom/client";
import "/node_modules/flag-icons/css/flag-icons.min.css";
import "./index.css";
import { App } from "@/App";
import { initialiseSentry } from "./utils/sentry";

initialiseSentry();

ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(<App />);

window.addEventListener("vite:preloadError", (event) => {
  console.log("vite:preloadError", event);
  window.location.reload();
});

import { IconProps } from ".";
export const LambdaIcon: React.FC<IconProps> = (props) => {
  return (
    <svg viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 9V18H8.97954H17.9591L17.9722 17.779C18.0049 17.2301 18.011 0.189723 17.9785 0.100446C17.9497 0.0208929 16.0754 0 8.97104 0H0V9ZM1.5893 8.95982V16.3929H8.97875H16.3681L16.3889 8.99333C16.4002 4.92356 16.3946 1.5787 16.3764 1.56029C16.3582 1.54181 13.0237 1.52679 8.96627 1.52679H1.5893V8.95982ZM4.7679 3.00222C4.7679 3.02054 5.46703 4.27179 6.32152 5.78266L7.87514 8.52975L7.6044 9.02788C5.82955 12.2931 4.45004 14.8607 4.45004 14.899C4.45004 14.9251 4.94177 14.9459 5.54268 14.9451L6.63532 14.9438L7.78343 12.7683C8.68044 11.0687 8.9499 10.6186 9.01514 10.7106C9.06115 10.7753 9.61725 11.7538 10.251 12.8849L11.4032 14.9415L12.5384 14.9439L13.6735 14.9464L13.5814 14.7656C13.4772 14.5611 9.75274 7.87363 8.11027 4.94196L7.02987 3.01339L5.89892 2.99113C5.27687 2.97884 4.7679 2.98382 4.7679 3.00222Z"
        fill="currentColor"
      />
    </svg>
  );
};

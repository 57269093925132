import * as anchor from "@project-serum/anchor";
import { WalletNotConnectedError } from "@solana/wallet-adapter-base";
import { IO_STAKING_IDL, IO_STAKING_PROGRAM_ID } from "./const";
import { Connection } from "@solana/web3.js";
import { AnchorWallet } from "@solana/wallet-adapter-react";

export function getProgramInstance(connection: Connection, wallet: AnchorWallet) {
  if (!wallet.publicKey) throw new WalletNotConnectedError();

  const provider = new anchor.AnchorProvider(
    connection,
    wallet,
    anchor.AnchorProvider.defaultOptions()
  );

  const idl = IO_STAKING_IDL;

  const programId = IO_STAKING_PROGRAM_ID;

  const program = new anchor.Program(idl as anchor.Idl, programId, provider);

  return program;
}

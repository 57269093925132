import { IconProps } from ".";

export const LightningIcon: React.FC<IconProps> = (props) => {
  return (
    <svg viewBox="0 0 13 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M0.954741 8.76317L9.1034 1.01929C9.66274 0.487739 10.5446 1.11965 10.2211 1.82019L8.00265 6.62417C7.78844 7.08802 8.12724 7.61765 8.63815 7.61765H11.7261C12.4154 7.61765 12.6897 8.50883 12.1198 8.89646L2.03341 15.7567C1.3645 16.2116 0.585201 15.3578 1.09917 14.7331L4.07571 11.1153C4.45147 10.6586 4.12658 9.97059 3.53515 9.97059H1.43695C0.804688 9.97059 0.496424 9.19872 0.954741 8.76317Z"
        fill="currentColor"
      />
    </svg>
  );
};

import { IconProps } from ".";

export const CloudIcon: React.FC<IconProps> = (props) => {
  return (
    <svg viewBox="0 0 60 8" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M0 5.69106V2.30895C0 0.836238 0.82229 0 2.29732 0H9.187C9.31243 0 9.38211 0.0696856 9.38211 0.195121V0.559816C9.38211 0.685251 9.31243 0.754937 9.187 0.754937H2.46922C1.41696 0.754937 0.857144 1.32636 0.857144 2.36469V5.63299C0.857144 6.67132 1.41696 7.24275 2.46922 7.24275H9.187C9.31243 7.24275 9.38211 7.31243 9.38211 7.43787V7.80256C9.38211 7.928 9.31243 7.99769 9.187 7.99769H2.29732C0.82229 8.00001 0 7.16377 0 5.69106Z"
        fill="currentColor"
      />
      <path
        d="M14.7272 7.24506H21.2847C21.4101 7.24506 21.4774 7.31476 21.4774 7.44019V7.80489C21.4774 7.93032 21.4101 8 21.2847 8H14.5669C13.0942 8 12.2603 7.16609 12.2603 5.69106V0.195121C12.2603 0.0696856 12.3299 0 12.467 0H12.9339C13.0593 0 13.129 0.0696856 13.129 0.195121V5.63299C13.1267 6.67364 13.6888 7.24506 14.7272 7.24506Z"
        fill="currentColor"
      />
      <path
        d="M24.3975 5.69106V2.30895C24.3975 0.836238 25.2198 0 26.6948 0H32.0978C33.5705 0 34.4068 0.833915 34.4068 2.30895V5.69106C34.4068 7.16377 33.5729 8 32.0978 8H26.6948C25.2198 8 24.3975 7.16377 24.3975 5.69106ZM33.538 5.63299V2.36469C33.538 1.32636 32.9782 0.754937 31.9375 0.754937H26.8667C25.8144 0.754937 25.2546 1.32636 25.2546 2.36469V5.63299C25.2546 6.67132 25.8144 7.24275 26.8667 7.24275H31.9375C32.9782 7.24508 33.538 6.67364 33.538 5.63299Z"
        fill="currentColor"
      />
      <path
        d="M44.736 8H39.5931C38.1181 8 37.2842 7.16609 37.2842 5.69106V0.195121C37.2842 0.0696856 37.3539 0 37.4909 0H37.9578C38.0832 0 38.1529 0.0696856 38.1529 0.195121V5.63299C38.1529 6.67132 38.7128 7.24275 39.7511 7.24275H44.5618C45.6001 7.24275 46.1715 6.67132 46.1715 5.63299V0.195121C46.1715 0.0696856 46.2389 0 46.3666 0H46.8359C46.9613 0 47.0287 0.0696856 47.0287 0.195121V5.69106C47.031 7.16377 46.2087 8 44.736 8Z"
        fill="currentColor"
      />
      <path
        d="M50.1067 0H57.0289C58.7641 0 59.691 0.959349 59.691 2.66202V5.33566C59.691 7.03833 58.7641 7.99769 57.0289 7.99769H50.1067C49.9813 7.99769 49.9116 7.928 49.9116 7.80256V0.192796C49.9116 0.0696834 49.9813 0 50.1067 0ZM58.8361 2.70848C58.8361 1.44019 58.1509 0.754937 56.8594 0.754937H50.7804V7.24275H56.8594C58.1509 7.24275 58.8361 6.5575 58.8361 5.2892V2.70848Z"
        fill="currentColor"
      />
    </svg>
  );
};

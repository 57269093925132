import { IconProps } from ".";

export const SpannerIcon: React.FC<IconProps> = (props) => {
  return (
    <svg viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.5655 0.0539147C9.92471 0.170213 9.16905 0.484663 8.61439 0.865856C7.78359 1.43679 7.06734 2.45042 6.86838 3.33688C6.7825 3.71957 6.79335 4.5187 6.89162 5.04648C7.06788 5.99309 7.07284 6.2544 6.91892 6.48507C6.87523 6.55052 5.6352 7.61428 4.16329 8.84903C2.69138 10.0838 1.41137 11.1759 1.31885 11.276C0.540739 12.1175 0.721141 13.6401 1.69571 14.4566C2.07379 14.7733 2.70869 15.0015 3.20785 15C3.66563 14.9986 4.18796 14.8156 4.50207 14.5464C4.58977 14.4713 5.70791 13.1841 6.98689 11.686C8.74806 9.62305 9.34485 8.94667 9.44658 8.89826C9.66156 8.79593 9.93531 8.79009 10.4286 8.87735C11.5283 9.07185 11.8811 9.09462 12.3961 9.00432C12.9191 8.91265 13.3974 8.70942 13.8546 8.38473C14.1746 8.15746 14.7643 7.5552 14.9863 7.22912C15.4983 6.47679 15.826 5.51225 15.8333 4.73618C15.8366 4.38267 15.8015 4.29358 15.5991 4.14198C15.4948 4.06385 15.2607 4.02403 15.1157 4.05977C15.0489 4.07624 14.6564 4.4435 13.9397 5.16042L12.8639 6.23636L11.6462 5.82978L10.4285 5.4232L10.0276 4.21506L9.62673 3.00696L10.6835 1.94101C11.7958 0.818985 11.8544 0.743112 11.8063 0.487814C11.7752 0.322962 11.6604 0.151057 11.5265 0.0692397C11.3855 -0.0170174 10.9909 -0.0233192 10.5655 0.0539147ZM2.88753 11.5644C2.55214 11.6485 2.26044 11.8839 2.10899 12.1926C2.03667 12.34 2.02261 12.4216 2.02318 12.6911C2.02378 12.9702 2.03663 13.0378 2.11937 13.1963C2.24498 13.4368 2.5294 13.6904 2.7597 13.7671C3.03459 13.8587 3.43258 13.8266 3.6772 13.693C4.0561 13.4861 4.2718 13.1149 4.27034 12.6721C4.26923 12.33 4.15165 12.0687 3.89447 11.8367C3.63019 11.5984 3.20771 11.4841 2.88753 11.5644Z"
        fill="currentColor"
      />
    </svg>
  );
};

import { IconProps } from ".";

export const PayIcon: React.FC<IconProps> = (props) => {
  return (
    <svg viewBox="0 0 43 8" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M9.78533 5.69143C9.78533 7.16571 8.95937 8 7.47952 8H2.31728C0.837431 8 0 7.16571 0 5.69143V0.194286C0 0.0685719 0.06883 0 0.20649 0H0.676828C0.803016 0 0.871846 0.0685719 0.871846 0.194286V5.63429C0.871846 6.67429 1.43396 7.24571 2.47788 7.24571H7.30745C8.35137 7.24571 8.92495 6.67429 8.92495 5.63429V0.194286C8.92495 0.0685719 8.99378 0 9.11997 0H9.59031C9.7165 0 9.78533 0.0685719 9.78533 0.194286V5.69143Z"
        fill="url(#paint0_linear_5666_20722)"
      />
      <path
        d="M13.6108 0.754286C12.4865 0.754286 12.0047 1.13143 12.0047 2.05714V2.33143C12.0047 3.24571 12.4865 3.62286 13.6108 3.62286H18.5092C20.0005 3.62286 20.9182 4.13714 20.9182 5.58857V6.02286C20.9182 7.48571 20.0005 8 18.5092 8H11.477C11.3394 8 11.2705 7.93143 11.2705 7.80571V7.44C11.2705 7.31429 11.3394 7.24571 11.477 7.24571H18.4403C19.5646 7.24571 20.0464 6.86857 20.0464 5.94286V5.66857C20.0464 4.75429 19.5646 4.37714 18.4403 4.37714H13.5419C12.0506 4.37714 11.1329 3.86286 11.1329 2.41143V1.97714C11.1329 0.514286 12.0506 0 13.5419 0H20.4823C20.6085 0 20.6773 0.0685719 20.6773 0.194286V0.56C20.6773 0.685714 20.6085 0.754286 20.4823 0.754286H13.6108Z"
        fill="url(#paint1_linear_5666_20722)"
      />
      <path
        d="M22.3003 0.194286C22.3003 0.0685719 22.3691 0 22.4953 0H29.4471C31.1908 0 32.12 0.96 32.12 2.66286V5.33714C32.12 7.04 31.1908 8 29.4471 8H22.4953C22.3691 8 22.3003 7.93143 22.3003 7.80571V0.194286ZM31.2597 5.29143V2.70857C31.2597 1.44 30.5714 0.754286 29.2751 0.754286H23.1721V7.24571H29.2751C30.5714 7.24571 31.2597 6.56 31.2597 5.29143Z"
        fill="url(#paint2_linear_5666_20722)"
      />
      <path
        d="M35.8876 8C34.4077 8 33.5818 7.16571 33.5818 5.69143V2.30857C33.5818 0.834286 34.4077 0 35.8876 0H42.805C42.9312 0 43 0.0685719 43 0.194286V0.56C43 0.685714 42.9312 0.754286 42.805 0.754286H36.0596C35.0042 0.754286 34.4421 1.32571 34.4421 2.36571V5.63429C34.4421 6.67429 35.0042 7.24571 36.0596 7.24571H42.805C42.9312 7.24571 43 7.31429 43 7.44V7.80571C43 7.93143 42.9312 8 42.805 8H35.8876Z"
        fill="url(#paint3_linear_5666_20722)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_5666_20722"
          x1="-3.11809"
          y1="1.43258"
          x2="30.2394"
          y2="1.43258"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#A7A9AC" />
          <stop offset="0.0091653" stopColor="#AAACAF" />
          <stop offset="0.1095" stopColor="#C9CACC" />
          <stop offset="0.203" stopColor="#DFE0E1" />
          <stop offset="0.2859" stopColor="#ECEDEE" />
          <stop offset="0.35" stopColor="#F1F2F2" />
          <stop offset="0.4681" stopColor="#EAEBEC" />
          <stop offset="0.6561" stopColor="#D7D8DA" />
          <stop offset="0.8892" stopColor="#B8BABC" />
          <stop offset="1" stopColor="#A7A9AC" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_5666_20722"
          x1="-3.11809"
          y1="1.43258"
          x2="30.2394"
          y2="1.43258"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#A7A9AC" />
          <stop offset="0.0091653" stopColor="#AAACAF" />
          <stop offset="0.1095" stopColor="#C9CACC" />
          <stop offset="0.203" stopColor="#DFE0E1" />
          <stop offset="0.2859" stopColor="#ECEDEE" />
          <stop offset="0.35" stopColor="#F1F2F2" />
          <stop offset="0.4681" stopColor="#EAEBEC" />
          <stop offset="0.6561" stopColor="#D7D8DA" />
          <stop offset="0.8892" stopColor="#B8BABC" />
          <stop offset="1" stopColor="#A7A9AC" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_5666_20722"
          x1="-3.11809"
          y1="1.43258"
          x2="30.2394"
          y2="1.43258"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#A7A9AC" />
          <stop offset="0.0091653" stopColor="#AAACAF" />
          <stop offset="0.1095" stopColor="#C9CACC" />
          <stop offset="0.203" stopColor="#DFE0E1" />
          <stop offset="0.2859" stopColor="#ECEDEE" />
          <stop offset="0.35" stopColor="#F1F2F2" />
          <stop offset="0.4681" stopColor="#EAEBEC" />
          <stop offset="0.6561" stopColor="#D7D8DA" />
          <stop offset="0.8892" stopColor="#B8BABC" />
          <stop offset="1" stopColor="#A7A9AC" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_5666_20722"
          x1="-3.11809"
          y1="1.43258"
          x2="30.2394"
          y2="1.43258"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#A7A9AC" />
          <stop offset="0.0091653" stopColor="#AAACAF" />
          <stop offset="0.1095" stopColor="#C9CACC" />
          <stop offset="0.203" stopColor="#DFE0E1" />
          <stop offset="0.2859" stopColor="#ECEDEE" />
          <stop offset="0.35" stopColor="#F1F2F2" />
          <stop offset="0.4681" stopColor="#EAEBEC" />
          <stop offset="0.6561" stopColor="#D7D8DA" />
          <stop offset="0.8892" stopColor="#B8BABC" />
          <stop offset="1" stopColor="#A7A9AC" />
        </linearGradient>
      </defs>
    </svg>
  );
};

import { ToggleRowField } from "@/components/forms/fields";
import { Button } from "@/components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle
} from "@/components/ui/dialog";
import { useCookieStore } from "@/store/cookie";
import { cn } from "@/utils/styles";
import { Formik } from "formik";
import { useMemo, useState } from "react";
import { COOKIE_DIALOG_POSITION } from "../CookieAcceptAllModal/CookieAcceptAllModal";
import { CookiePreferenceType } from "@/types/user";

type CookieSelectionModalProps = {
  isOpen: boolean;
  onClose: () => void;
};

export const CookieSelectionModal: React.FC<CookieSelectionModalProps> = ({ isOpen, onClose }) => {
  const getCookieOptions = useCookieStore((state) => state.getCookieOptions);
  const options = useMemo(() => {
    return getCookieOptions();
  }, [getCookieOptions]);
  const preferences = useCookieStore((state) => state.cookiePreferences);
  const setCookiePreferences = useCookieStore((state) => state.setCookiePreferences);
  const initialValues = useMemo(() => {
    return Object.fromEntries(
      preferences.map((value) => {
        return [value, true];
      })
    ) as CookieSelectionForm;
  }, [preferences]);
  const [hasChanged, setHasChanged] = useState(false);

  return (
    <Dialog open={isOpen} modal={false} onOpenChange={onClose}>
      <DialogContent
        className={cn(
          COOKIE_DIALOG_POSITION,
          "custom-scrollbar max-h-[100vh] gap-2 overflow-y-auto p-0 !pr-[0px]"
        )}
      >
        <DialogHeader className="flex flex-row items-start p-5 pb-0">
          <DialogTitle className="flex-1 text-lg font-medium">Edit Cookies</DialogTitle>
        </DialogHeader>
        <Formik<CookieSelectionForm>
          initialValues={initialValues}
          onSubmit={(values) => {
            setCookiePreferences(toCookiePreferencesArray(values));

            onClose();
          }}
          validateOnMount={true}
          validate={(values) => {
            const newHasChanged =
              JSON.stringify(toCookiePreferencesArray(initialValues)) !==
              JSON.stringify(toCookiePreferencesArray(values));
            setHasChanged(newHasChanged);
          }}
        >
          {({ isValid, isSubmitting, handleSubmit }) => {
            return (
              <form onSubmit={handleSubmit}>
                <div className="flex flex-col gap-5 px-5">
                  <div className="px-text-sm flex flex-col text-gray-dark-1000">
                    Below you can choose which types of cookies you want to allow us to set on your
                    device.
                  </div>
                  <div className="flex flex-col gap-0">
                    {options.map(({ value, title, description, icon, disabled }) => {
                      return (
                        <ToggleRowField
                          key={value}
                          name={value}
                          title={title}
                          content={description}
                          icon={icon}
                          disabled={disabled}
                        />
                      );
                    })}
                  </div>
                </div>
                <DialogFooter className="items-center rounded-b-lg border-t border-gray-dark-1100 bg-black px-5 py-3">
                  <Button type="submit" disabled={!isValid || isSubmitting || !hasChanged}>
                    Set cookie preferences
                  </Button>
                </DialogFooter>
              </form>
            );
          }}
        </Formik>
      </DialogContent>
    </Dialog>
  );
};

const toCookiePreferencesArray = (values: CookieSelectionForm) => {
  const result = Object.entries(values)
    .filter(({ 1: value }) => {
      return value === true;
    })
    .map(({ 0: key }) => {
      return key as CookiePreferenceType;
    });

  result.sort();

  return result;
};

type CookieSelectionForm = Record<string, boolean | undefined>;

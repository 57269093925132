import { Device, DeviceForm } from "@/types";
import { ucfirst } from "./string";
import { v4 as uuidv4 } from "uuid";
import { createContext } from "react";

export const getDockerCommand = (options: {
  name: string;
  uid: string;
  userId: string;
  operatingSystem: string;
  deviceType: string;
}) => {
  const { name, uid, userId, operatingSystem, deviceType } = options;

  return `docker run -d -v /var/run/docker.sock:/var/run/docker.sock -e DEVICE_NAME="${name.replace(" ", "-")}" -e DEVICE_ID=${uid} -e USER_ID=${userId} -e OPERATING_SYSTEM="${ucfirst(
    operatingSystem
  )}" -e USEGPUS=${deviceType === "gpu"} --pull always ionetcontainers/io-launch:v0.1`;
};

export const getNewDeviceId = () => {
  return uuidv4();
};

export const getDeviceStatusDuration = (
  device: {
    status: DeviceStatusType;
    statusColor: string;
    statusValue: string;
    statusLabel: string;
  },
  statusDuration: string
) => {
  const isErrorStatus = isDeviceStatusErrorStatus(device.status);
  const statusToShow = isErrorStatus ? "down" : device.statusLabel;
  const statusDescription = ((statusDuration as string) || "")
    .split(statusToShow)
    .join("")
    .split("for ")
    .join("");

  return (
    <>
      <span>{ucfirst(statusToShow)} For</span>
      {": "}
      <span className={`${device.statusColor} !bg-transparent`}>{statusDescription || "-"}</span>
    </>
  );
};

export const getSupportedDeviceTypesForOperatingSystem = (
  values: Pick<DeviceForm, "operatingSystem" | "supplier">
) => {
  const { operatingSystem, supplier } = values;

  return ["gpu", "cpu"].map((value) => {
    const hidden =
      (operatingSystem === "linux" && value === "cpu") ||
      (operatingSystem === "windows" && value === "cpu");

    const disabled =
      (operatingSystem === "mac" && value === "gpu") ||
      (operatingSystem === "linux" && supplier === "2" && value === "cpu");

    return {
      hidden,
      value,
      label: value.toUpperCase(),
      disabled
    };
  });
};

export enum DeviceStatusType {
  UP = "up",
  PAUSED = "paused",
  DOWN = "down",
  TERMINATED = "terminated",
  UNSUPPORTED = "unsupported",
  BLOCKED = "blocked",
  ARCHIVED = "archived",
  RESTART_REQUIRED = "restart required",
  INACTIVE = "inactive",
  DESTROYED = "destroyed",
  MAINTENANCE = "maintenance",
  PENDING = "pending",
  IDLE = "idle"
}

const ERROR_DEVICE_STATUSES = [
  DeviceStatusType.TERMINATED,
  DeviceStatusType.UNSUPPORTED,
  DeviceStatusType.BLOCKED,
  DeviceStatusType.INACTIVE
];

export const isDeviceStatusErrorStatus = (status: DeviceStatusType) => {
  return ERROR_DEVICE_STATUSES.indexOf(status) > -1;
};

export const DeviceResultsDataTableContext = createContext<{
  setSelectedDevice: React.Dispatch<React.SetStateAction<Device | undefined>>;
  setSelectedAction: React.Dispatch<React.SetStateAction<string | undefined>>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
}>(undefined as any);

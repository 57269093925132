import { Loader } from "../Loader/Loader";
import { useUserStore } from "@/store/user";

type ContentLoaderProps = {
  color?: string;
  className?: string;
};

export const ContentLoader: React.FC<ContentLoaderProps> = ({ color, className }) => {
  const theme = useUserStore((state) => state.theme);
  return (
    <div
      className="flex min-h-[100px] flex-1 flex-col items-center justify-center"
      data-testid="ContentLoader"
    >
      <Loader
        className={className}
        color={color ? color : theme === "dark" ? "#ffffff" : "#000000"}
      />
    </div>
  );
};

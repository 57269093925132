import { IconProps } from ".";

export const ArrowDownIcon: React.FC<IconProps> = (props) => {
  return (
    <svg viewBox="0 0 12 8" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M1 1.5L5.92929 6.42929C5.96834 6.46834 6.03166 6.46834 6.07071 6.42929L11 1.5"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
    </svg>
  );
};

import { DEFAULT_ROUTE_AFTER_LOGIN } from "@/constants";
import env from "@/env";
import { setToken } from "@/utils/api";
import { useCookieStore } from "@/store/cookie";
import { AuthMachineContext, restoreMachine } from "@/store/machine/authMachine/authMachine";
import { useNotificationStore } from "@/store/notification";
import { useUserStore } from "@/store/user";
import { ClusterType } from "@/types";
import { AuthEventType, AuthStateType } from "@/types/auth";
import { CookiePreferenceType } from "@/types/user";
import { urlWithCurrentQuery } from "@/utils";
import {
  initialiseAmplitude,
  resetAmplitudeUserOptions,
  updateAmplitudeUserOptions
} from "@/utils/amplitude";
import { getClusterTypeFromUrl } from "@/utils/cluster";
import { redirectUsingQuery } from "@/utils/location";
import { getSubdomain, isOnLoginSubdomain, redirectToLogin } from "@/utils/tool";
import React, { useEffect, useRef } from "react";
import { useLocation } from "wouter";

type AuthProcessProps = {
  onInitialised?: () => void;
};

export const AuthProcess: React.FC<AuthProcessProps> = ({ onInitialised }) => {
  const authActorRef = AuthMachineContext.useActorRef();
  const stateValue = AuthMachineContext.useSelector((state) => state.value);
  const context = AuthMachineContext.useSelector((state) => state.context);
  const { isLoggedIn } = context;
  const displayError = useNotificationStore((state) => state.displayError);
  const [location, setLocation] = useLocation();
  const locationRef = useRef<string>("");
  const cookiePreferences = useCookieStore((state) => state.cookiePreferences);
  const fetchUserInfo = useUserStore((state) => state.fetchUserInfo);

  useEffect(() => {
    restoreMachine(authActorRef);

    const subscription = authActorRef.subscribe(({ value, context }) => {
      const { isInitialised } = context;

      if (isInitialised) {
        onInitialised?.();
      }

      switch (value) {
        case AuthStateType.REDIRECT: {
          if (locationRef.current.startsWith("/worker/devices/create/")) {
            const parts = locationRef.current.split("/");

            authActorRef.send({
              type: AuthEventType.CONNECT_DEVICE,
              value: parts[4]
            });
          } else if (locationRef.current.indexOf("/create-cluster") > -1) {
            const clusterType = getClusterTypeFromUrl(locationRef.current);

            authActorRef.send({
              type: AuthEventType.DEPLOY_CLUSTER,
              value: clusterType
            });
          } else {
            authActorRef.send({
              type: AuthEventType.AUTHORISED
            });
          }
          break;
        }

        case AuthStateType.AUTHORISED: {
          if ((locationRef.current || "").indexOf("/login") > -1) {
            setLocation(redirectUsingQuery(DEFAULT_ROUTE_AFTER_LOGIN));
          }
          break;
        }

        case AuthStateType.ENTER_PHONE_NUMBER:
        case AuthStateType.ENTER_PHONE_CODE:
        case AuthStateType.ENTER_ROLE:
        case AuthStateType.ENTER_WALLET_TYPE:
        case AuthStateType.ENTER_WALLET_ADDRESS: {
          setLocation(`/login/${value}`);
          break;
        }

        case AuthStateType.DEPLOY_CLUSTER: {
          switch (context.clusterType) {
            case ClusterType.RAY:
              setLocation("/cloud/clusters/create-cluster");
              break;
            case ClusterType.MEGA:
              setLocation("/cloud/mega-clusters/create-cluster");
              break;
            case ClusterType.KUBERNETES:
              setLocation("/cloud/kubernetes-clusters/create-cluster");
              break;
          }
          break;
        }

        case AuthStateType.CONNECT_DEVICE: {
          setLocation(`/worker/devices/create/${context.newDeviceId}`);
          break;
        }
      }
    });

    return () => {
      subscription.unsubscribe();
    };
  }, [authActorRef, displayError, setLocation, onInitialised]);

  useEffect(() => {
    locationRef.current = location;

    switch (stateValue) {
      case AuthStateType.CONNECT_DEVICE: {
        if (!location.startsWith("/worker/devices/create/")) {
          authActorRef.send({
            type: AuthEventType.EXIT_PROCESS
          });
        }
        break;
      }

      case AuthStateType.DEPLOY_CLUSTER: {
        if (location.indexOf("/create-cluster") === -1) {
          authActorRef.send({
            type: AuthEventType.EXIT_PROCESS
          });
        }
        break;
      }
    }

    if ([AuthStateType.AUTHORISED, AuthStateType.UNAUTHORISED].indexOf(stateValue) === -1) {
      return;
    }

    const hash = window.location.hash;

    if (!isLoggedIn) {
      if (env.SECTION_SUBDOMAINS_ENABLED && !isOnLoginSubdomain()) {
        redirectToLogin(location);
        return;
      }

      if (
        PUBLIC_ROUTES.filter((route) => {
          return location.startsWith(route);
        }).length === 0
      ) {
        const subdomain = getSubdomain();
        const redirectLocation = subdomain === "explorer" ? "/explorer/home" : "/login";

        setLocation(
          urlWithCurrentQuery(redirectLocation, {
            ...(redirectLocation === "/login"
              ? {
                  redirect: location
                }
              : {})
          })
        );
      }
    } else if (hash.length > 1 && hash.indexOf("error=") === -1) {
      setLocation(location);
    }
  }, [isLoggedIn, location, setLocation, stateValue, authActorRef]);

  useEffect(() => {
    const { token, internalUserId } = context;

    setToken(token);
    useUserStore.getState().setUserId(internalUserId);
  }, [context]);

  useEffect(() => {
    (async () => {
      const { isLoggedIn, isInitialised, internalUserId } = context;

      if (!isInitialised) {
        return;
      }

      if (
        !env.FEATUREFLAG_COOKIE_POLICY ||
        cookiePreferences.includes(CookiePreferenceType.ANALYTICS)
      ) {
        initialiseAmplitude({
          userId: isLoggedIn ? internalUserId : undefined
        });
      }

      if (isLoggedIn) {
        const userInfo = await fetchUserInfo();
        updateAmplitudeUserOptions(userInfo);
      } else {
        resetAmplitudeUserOptions();
      }
    })();
  }, [cookiePreferences, context, fetchUserInfo]);

  return null;
};

const PUBLIC_ROUTES = ["/login", "/explorer"];

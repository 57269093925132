import { IconProps } from ".";

export const AlertOctagonOutlineIcon: React.FC<IconProps> = (props) => {
  return (
    <svg viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g id="alert-octagon" clipPath="url(#clip0_1012_7674)">
        <path
          id="Vector"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4.88643 0.97979C4.98019 0.886022 5.10737 0.833344 5.23998 0.833344H10.76C10.8926 0.833344 11.0198 0.886022 11.1135 0.97979L15.0202 4.88646C15.114 4.98023 15.1666 5.1074 15.1666 5.24001V10.76C15.1666 10.8926 15.114 11.0198 15.0202 11.1136L11.1135 15.0202C11.0198 15.114 10.8926 15.1667 10.76 15.1667H5.23998C5.10737 15.1667 4.98019 15.114 4.88643 15.0202L0.97976 11.1136C0.885991 11.0198 0.833313 10.8926 0.833313 10.76V5.24001C0.833313 5.1074 0.885991 4.98023 0.97976 4.88646L4.88643 0.97979ZM5.44709 1.83334L1.83331 5.44712V10.5529L5.44709 14.1667H10.5529L14.1666 10.5529V5.44712L10.5529 1.83334H5.44709ZM7.99998 4.83334C8.27612 4.83334 8.49998 5.0572 8.49998 5.33334V8.00001C8.49998 8.27615 8.27612 8.50001 7.99998 8.50001C7.72384 8.50001 7.49998 8.27615 7.49998 8.00001V5.33334C7.49998 5.0572 7.72384 4.83334 7.99998 4.83334ZM7.49998 10.6667C7.49998 10.3905 7.72384 10.1667 7.99998 10.1667H8.00665C8.28279 10.1667 8.50665 10.3905 8.50665 10.6667C8.50665 10.9428 8.28279 11.1667 8.00665 11.1667H7.99998C7.72384 11.1667 7.49998 10.9428 7.49998 10.6667Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_1012_7674">
          <rect width="16" height="16" fill="currentColor" />
        </clipPath>
      </defs>
    </svg>
  );
};

import { CrossIcon } from "@/components/Icons/CrossIcon";
import { NotificationItem } from "../UserNotificationsButton/NotificationItem";
import React, { useEffect, useState } from "react";
import { usePaginatedUserNotificationStore } from "@/store/paginated";
import { DataPagination } from "../DataPagination/DataPagination";
import { useUserStore } from "@/store/user";
import { UserNotification } from "@/types";
import { AsyncSuspense } from "../AsyncSuspense/AsyncSuspense";
import { NotificationType } from "@/types/user";
import { useToast } from "@/components/ui/use-toast";

type UserNotificationResultsProps = {
  type: NotificationType;
};

export const UserNotificationResults: React.FC<UserNotificationResultsProps> = ({ type }) => {
  const notifications = usePaginatedUserNotificationStore(
    (state) => state.results as UserNotification[] | undefined
  );
  const setResultsRequestOptions = usePaginatedUserNotificationStore(
    (state) => state.setResultsRequestOptions
  );
  const page = usePaginatedUserNotificationStore((state) => state.page);
  const setPage = usePaginatedUserNotificationStore((state) => state.setPage);
  const pageCount = usePaginatedUserNotificationStore((state) => state.pageCount);
  const isLoading = usePaginatedUserNotificationStore((state) => state.isLoading);
  const fetchResults = usePaginatedUserNotificationStore((state) => state.fetchResults);
  const fetchResultsError = usePaginatedUserNotificationStore((state) => state.fetchResultsError);
  const removeNotification = useUserStore((store) => store.removeNotification);
  const [isDeleting, setIsDeleting] = useState(false);
  const { toast } = useToast();

  useEffect(() => {
    (async () => {
      setResultsRequestOptions({
        type
      });

      await fetchResults();
    })();
  }, [type, fetchResults, setResultsRequestOptions]);

  return (
    <div className="custom-scrollbar flex max-h-[425px] flex-col overflow-y-auto overflow-x-hidden">
      <AsyncSuspense isLoading={isLoading || isDeleting} error={fetchResultsError}>
        {Array.isArray(notifications) && (
          <>
            {notifications.length > 0 ? (
              <>
                {notifications?.map((notification) => {
                  return (
                    <NotificationItem notification={notification}>
                      <CrossIcon
                        onClick={async () => {
                          try {
                            setIsDeleting(true);

                            await removeNotification(notification.id);
                            await fetchResults();

                            setIsDeleting(false);

                            toast({ title: "Notification deleted", variant: "error" });
                          } catch (e) {
                            console.log(e);
                            setIsDeleting(false);
                          }
                        }}
                        className="ml-auto h-[16px] cursor-pointer text-gray-dark-1500 hover:text-gray-dark-300"
                      />
                    </NotificationItem>
                  );
                })}
                <DataPagination
                  page={page}
                  pageCount={pageCount}
                  maxItems={4}
                  onChange={async (newPage) => {
                    setPage(newPage);
                    await fetchResults();
                  }}
                />
              </>
            ) : (
              <div className="p-4 text-xs text-gray-dark-300">
                You currently have no notifications.
              </div>
            )}
          </>
        )}
      </AsyncSuspense>
    </div>
  );
};

import { IconProps } from ".";

export const TickCircleOutlineIcon: React.FC<IconProps> = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" fill="none" {...props}>
      <g clipPath="url(#clip0_1012_7680)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10.5098 2.36338C9.29515 1.82215 7.93806 1.68807 6.64096 1.98113C5.34386 2.2742 4.17625 2.9787 3.31225 3.98957C2.44826 5.00045 1.93418 6.26353 1.84668 7.59044C1.75918 8.91735 2.10296 10.237 2.82674 11.3526C3.55052 12.4681 4.61552 13.3199 5.8629 13.7807C7.11029 14.2415 8.47323 14.2868 9.74845 13.9098C11.0237 13.5327 12.1429 12.7536 12.9391 11.6885C13.7353 10.6234 14.1659 9.3295 14.1667 7.99971V7.38666C14.1667 7.11052 14.3905 6.88666 14.6667 6.88666C14.9428 6.88666 15.1667 7.11052 15.1667 7.38666V8C15.1658 9.54543 14.6653 11.0495 13.74 12.2873C12.8147 13.525 11.514 14.4306 10.032 14.8687C8.54997 15.3069 6.96602 15.2543 5.51635 14.7187C4.06668 14.1832 2.82898 13.1933 1.98783 11.8968C1.14669 10.6004 0.747163 9.06673 0.848848 7.52464C0.950533 5.98255 1.54798 4.51465 2.55208 3.33985C3.55618 2.16505 4.91314 1.34631 6.42058 1.00572C7.92802 0.665134 9.50517 0.820957 10.9168 1.44995C11.1691 1.56234 11.2824 1.85793 11.17 2.11016C11.0576 2.3624 10.7621 2.47577 10.5098 2.36338ZM15.02 2.31293C15.2154 2.5081 15.2155 2.82468 15.0204 3.02004L8.35371 9.69337C8.25996 9.78722 8.13276 9.83996 8.00011 9.84C7.86746 9.84003 7.74023 9.78735 7.64643 9.69355L5.64643 7.69355C5.45117 7.49829 5.45117 7.18171 5.64643 6.98644C5.84169 6.79118 6.15828 6.79118 6.35354 6.98644L7.99981 8.63271L14.3129 2.31329C14.5081 2.11793 14.8247 2.11777 15.02 2.31293Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_1012_7680">
          <rect width="16" height="16" fill="currentColor" />
        </clipPath>
      </defs>
    </svg>
  );
};

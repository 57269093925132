import { useToast } from "@/components/ui/use-toast";
import { useNotificationStore } from "@/store/notification";
import { useEffect, useState } from "react";
import { useLocation } from "wouter";
import { AlertModal } from "../AlertModal/AlertModal";

export const ErrorViewer = () => {
  const [isOpen, setIsOpen] = useState(false);
  const currentError = useNotificationStore((state) => state.currentError);
  const [, setLocation] = useLocation();
  const { toast } = useToast();

  useEffect(() => {
    const { redirectTo, isPublic, message } = currentError || {};
    const showAlert = currentError && redirectTo;

    setIsOpen(showAlert ? true : false);

    if (showAlert || !currentError) {
      return;
    }
    toast({ title: isPublic ? message : STATIC_ERROR_MESSAGE, variant: "error" });
  }, [currentError, toast]);

  return (
    <AlertModal
      isOpen={isOpen}
      onClose={() => {
        const { redirectTo } = currentError || {};
        if (redirectTo) {
          setLocation(redirectTo);
        }

        setIsOpen(false);
      }}
      title={currentError?.title || "ERROR"}
      description={(currentError?.isPublic ? currentError?.message : STATIC_ERROR_MESSAGE) || ""}
      submitLabel="Okay"
    />
  );
};

const STATIC_ERROR_MESSAGE = "Oops Something Went Wrong";

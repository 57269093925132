import { PublicKey } from "@solana/web3.js";
import { IDL } from "./programIdl";
import env from "@/env";
import { getStakingNetworkApiEndpoint } from "@/utils/staking";

export const CLUSTER = env.STAKING_NETWORK;

// export const SOLANA_HOST = clusterApiUrl(CLUSTER);
export const SOLANA_HOST = getStakingNetworkApiEndpoint();

export const IO_STAKING_PROGRAM_ID = new PublicKey(env.STAKING_CONTRACT_ADDRESS);

export const IO_STAKING_IDL = IDL;

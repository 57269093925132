import { parse } from "qs";

export const getParams = <T>() => {
  const { hash, search } = window.location;
  const hashParams = parse(hash.slice(1));
  const queryStringParams = parse(search.slice(1));

  return {
    ...hashParams,
    ...queryStringParams
  } as T;
};

import { IconProps } from "@/components/Icons";
import { clsx, ClassValue } from "clsx";
import { twMerge } from "tailwind-merge";

export const classNames = (...classes: string[]) => {
  return classes.filter(Boolean).join(" ");
};

export const getIconFill = ({ color }: IconProps) => {
  return color ? color : "currentColor";
};

export const cn = (...inputs: ClassValue[]) => {
  return twMerge(clsx(inputs));
};

import { create } from "zustand";
import {
  getUserTokenBalance,
  IO_TOKEN_MINT,
  getStakeInfoByUserAccount,
  getAllStakeInfo
} from "@/store/solution3/solanaAndApi";
import { toFriendlyPublicError } from "@/utils/api";
import { ComputeBudgetProgram, PublicKey, SystemProgram, Transaction } from "@solana/web3.js";
import {
  SUPER_ADMIN_PUBKEY,
  convertLamportBack,
  convertToLamport,
  findGlobalAccount,
  findGlobalVaultTokenAccount,
  findPoolInfoAccount,
  findStakeInfoAccount
} from "./solution3/utils";
import {
  ProxyToMiddlewareEndpoint,
  MiddlewareStakeEligibleDevice,
  MiddlewareStakeEligibleDeviceResponse,
  StakeEventType,
  UserStakedDevice,
  MiddlewareUserStakedDeviceResponse,
  UserStakedDeviceResponse,
  UserStakedDeviceResult,
  UserStakedDevicesRequestOptions,
  UserStakedDeviceStatusType
} from "@/types/staking";
import { AnchorWallet, WalletContextState } from "@solana/wallet-adapter-react";
import { TOKEN_PROGRAM_ID, getAssociatedTokenAddress } from "@solana/spl-token";
import { getProgramInstance } from "./solution3/solanaProgram";
import * as anchor from "@project-serum/anchor";
import { SOLANA_HOST } from "./solution3/const";
import { formatDateTime, toDuration } from "@/utils/date";
import eventDispatcher from "@/utils/eventDispatcher";
import { useUserStore } from "./user";
import { executeAPIRequest } from "@/utils/api";
import { stringify } from "qs";
import { PaginatedResponse } from "@/types";
import { DEFAULT_PAGINATION_LIMIT } from "@/constants";
import env from "@/env";
import { getHardware } from "@/utils/mapping";
import { STATUS_MAP } from "./device";
import { DeviceStatusType } from "@/utils/device";
import * as analytics from "@/utils/analytics";
import { AnalyticsEventType } from "@/constants/analytics";
import { AxiosError } from "axios";
import { errorIfAborted } from "@/utils";
import { logEvent } from "@/utils/betterstack";

export type UseStakingStoreProps = {
  isReady: boolean;
  isConnected: boolean;
  isConnecting?: boolean;
  isStaking?: boolean;
  unstakingDevice?: UserStakedDevice;
  withdrawingDevice?: UserStakedDevice;
  publicKey?: PublicKey;
  balance?: number;
  adapterName?: string;
  stakingInfo?: {
    totalStakeAmount: number;
    totalRewardAmount: number;
    totalFreezeAmount: number;
    totalWithdrawable: number;
  };
  walletOptions?: WalletContextState;
  setWalletOptions: (options: WalletContextState) => void;
  setPublicKey: (publicKey: PublicKey | null | undefined) => void;
  refreshInfo: () => Promise<void>;
  fetchIoBalance: () => Promise<number>;
  fetchStakingInfo: () => Promise<UseStakingStoreProps["stakingInfo"]>;
  fetchMiddlewareUserStakedDevicesRequest: (options: { page?: number; limit?: number }) => Promise<{
    resultCount: number;
    results: UserStakedDevice[];
  }>;
  fetchMiddlewareStakeEligibleDevicesRequest: (options: {
    page?: number;
    limit?: number;
    query?: string;
  }) => Promise<{
    resultCount: number;
    results: MiddlewareStakeEligibleDevice[];
  }>;
  unstakeDevice: (options: UserStakedDevice, abortController?: AbortController) => Promise<void>;
  withdrawDevice: (options: UserStakedDevice, abortController?: AbortController) => Promise<void>;
  stakeDevice: (
    options: UserStakedDevice,
    amount: number,
    abortController?: AbortController
  ) => Promise<void>;
  fetchUserStakedDevicesRequest: (
    option: UserStakedDevicesRequestOptions
  ) => Promise<UserStakedDeviceResult>;
  fetchStakingSearch: (
    option: UserStakedDevicesRequestOptions
  ) => Promise<UserStakedDeviceResult["results"]>;
  trackEvent: (eventName: AnalyticsEventType, options?: Record<string, unknown>) => void;
  trackProcessError: (
    e: unknown,
    events: {
      aborted: AnalyticsEventType;
      cancelled: AnalyticsEventType;
      transaction: AnalyticsEventType;
      middleware: AnalyticsEventType;
      capacity: AnalyticsEventType;
    },
    options?: Record<string, unknown>
  ) => void;
  hardwareList?: string[];
};

let timeout: number;

const connection = new anchor.web3.Connection(SOLANA_HOST);

export const useStakingStore = create<UseStakingStoreProps>((set, get) => ({
  isReady: false,
  isConnected: false,
  isConnecting: false,
  trackEvent: (eventName: AnalyticsEventType, defaultOptions?: Record<string, unknown>) => {
    const { publicKey, adapterName } = get();
    const device = defaultOptions?.device as UserStakedDevice | undefined;

    const options = {
      ...(defaultOptions ? defaultOptions : {}),
      ...(publicKey
        ? {
            walletAddress: publicKey.toBase58()
          }
        : {}),
      ...(device
        ? {
            deviceId: device.id
          }
        : {}),
      adapterName
    };

    const { userId } = useUserStore.getState();

    logEvent(eventName, {
      userId,
      ...options
    });

    analytics.trackEvent(eventName, options);
  },
  trackProcessError: (e, errorTypes, defaultOptions) => {
    const { trackEvent } = get();

    const message = (e as { message: string })?.message;
    const options = {
      ...defaultOptions,
      message
    };

    if (isCapacityReachedError(e)) {
      trackEvent(errorTypes.capacity, options);
      return;
    }

    if (["Transaction cancelled", "Transaction rejected"].includes(message)) {
      trackEvent(errorTypes.cancelled, options);
      return;
    }

    if (message === "aborted") {
      trackEvent(errorTypes.aborted, options);
      return;
    }

    if (e instanceof AxiosError) {
      trackEvent(errorTypes.middleware, options);
      return;
    }

    trackEvent(errorTypes.transaction, options);
  },
  setWalletOptions: (options) => {
    const { setPublicKey, trackEvent } = get();
    const { connected, connecting, disconnecting, publicKey } = options;

    if (connected && !connecting && !disconnecting && publicKey) {
      trackEvent(AnalyticsEventType.STAKING_CONNECT_WALLET, {
        walletAddress: publicKey.toBase58()
      });
    }

    set({
      walletOptions: options,
      isConnected: connected,
      isConnecting: connecting,
      ...(disconnecting
        ? {
            isReady: false,
            publicKey: undefined,
            balance: undefined,
            stakingInfo: undefined,
            unstakingDevice: undefined,
            withdrawingDevice: undefined
          }
        : {}),
      publicKey: undefined,
      adapterName: options.wallet?.adapter.name
    });

    if (timeout) {
      clearTimeout(timeout);
    }

    timeout = window.setTimeout(() => {
      if (connecting || disconnecting) {
        return;
      }

      set({ isReady: true });
      setPublicKey(publicKey);
    }, 200);
  },
  setPublicKey: (publicKey) => {
    const { refreshInfo } = get();

    if (!publicKey) {
      set({ balance: undefined, isConnected: false });
      return;
    }

    set({ isConnected: true, publicKey, balance: undefined, stakingInfo: undefined });
    refreshInfo();
  },
  refreshInfo: async () => {
    const { fetchIoBalance, fetchStakingInfo } = get();

    fetchIoBalance();
    fetchStakingInfo();
  },
  fetchIoBalance: async () => {
    try {
      const { publicKey } = get();
      if (!publicKey) {
        throw new Error("no wallet connected");
      }

      const balance = await getUserTokenBalance(publicKey, IO_TOKEN_MINT);
      const parsedBalance = parseFloat(`${balance}`);

      set({ balance: parsedBalance });

      return parsedBalance;
    } catch (e) {
      console.log("fetchIoBalance error", e);
      set({ balance: undefined });

      throw toFriendlyPublicError(e);
    }
  },
  fetchStakingInfo: async () => {
    try {
      const { publicKey } = get();
      if (!publicKey) {
        throw new Error("no wallet connected");
      }

      // const data = (await getUserStakeAggregateData(publicKey.toBase58())) as NonNullable<
      //   UseStakingStoreProps["stakingInfo"]
      // >;
      const data = (await proxyToMiddleware(
        ProxyToMiddlewareEndpoint.INFO,
        {
          user_account: publicKey.toBase58()
        },
        "get"
      )) as NonNullable<UseStakingStoreProps["stakingInfo"]>;

      const { totalFreezeAmount, totalRewardAmount, totalStakeAmount, totalWithdrawable } = data;
      const stakingInfo = {
        totalFreezeAmount: parseFloat(convertLamportBack(totalFreezeAmount)),
        totalRewardAmount: parseFloat(convertLamportBack(totalRewardAmount)),
        totalStakeAmount: parseFloat(convertLamportBack(totalStakeAmount)),
        totalWithdrawable: parseFloat(convertLamportBack(totalWithdrawable))
      };

      set({ stakingInfo });

      return stakingInfo;
    } catch (e) {
      console.log("fetchStakeAggregateData error", e);
      set({ stakingInfo: undefined });

      throw toFriendlyPublicError(e);
    }
  },
  fetchMiddlewareUserStakedDevicesRequest: async ({ page = 1 }) => {
    const { publicKey } = get();

    if (!publicKey) {
      throw new Error("no wallet connected");
    }

    const response = (await getStakeInfoByUserAccount(publicKey.toBase58(), page)) as {
      items: MiddlewareUserStakedDeviceResponse[];
      total: number;
      page: string;
      limit: number;
    };

    return {
      results: response.items.map((item) => {
        return normaliseMiddlewareUserStakedDevice(item);
      }),
      resultCount: response.total
    };
  },
  fetchMiddlewareStakeEligibleDevicesRequest: async ({ page = 1, query = "" }) => {
    const response = (await getAllStakeInfo(query, page)) as {
      items: MiddlewareStakeEligibleDeviceResponse[];
      total: number;
      page: string;
      limit: number;
    };

    return {
      results: response.items.map((item) => {
        return normaliseStakeEligibleDevice(item);
      }),
      resultCount: response.total
    };
  },
  stakeDevice: async (device, amount, abortController) => {
    const { publicKey, walletOptions, refreshInfo, trackEvent, trackProcessError } = get();

    try {
      set({ isStaking: true });

      if (!walletOptions || !publicKey) {
        throw new Error("no wallet connected");
      }

      trackEvent(AnalyticsEventType.STAKING_STAKE_PROCESS_STARTED);

      const { signTransaction } = walletOptions;

      const deviceId = device.deviceId;
      const poolInfo = findPoolInfoAccount(deviceId);
      const stakeInfo = findStakeInfoAccount(poolInfo, publicKey);
      const ioTokenMint = new PublicKey(IO_TOKEN_MINT);
      const userTokenAccount = await getAssociatedTokenAddress(ioTokenMint, publicKey);
      const globalAccount = findGlobalAccount();
      const globalVaultTokenAccount = findGlobalVaultTokenAccount();

      const program = getProgramInstance(connection, walletOptions as AnchorWallet);
      const instruction = await program.methods
        .stake(deviceId, new anchor.BN(convertToLamport(amount.toString())))
        .accounts({
          user: publicKey,
          superAdmin: new PublicKey(SUPER_ADMIN_PUBKEY),
          poolInfo: poolInfo,
          stakeInfo: stakeInfo,
          ioTokenMint: ioTokenMint,
          userTokenAccount: userTokenAccount,
          globalAccount: globalAccount,
          globalVaultTokenAccount: globalVaultTokenAccount,
          tokenProgram: TOKEN_PROGRAM_ID,
          systemProgram: SystemProgram.programId
        })
        .instruction();
      errorIfAborted(abortController);

      const transaction = toTransactionWithPriority(new Transaction()).add(instruction);

      const { blockhash } = await connection.getLatestBlockhash("confirmed");
      transaction.recentBlockhash = blockhash;
      transaction.feePayer = publicKey;

      const signedTransaction = await signTransaction!(transaction);
      const partiallySignedTransaction = signedTransaction
        .serialize({
          requireAllSignatures: false,
          verifySignatures: false
        })
        .toString("base64");

      const response = await proxyToMiddleware(
        ProxyToMiddlewareEndpoint.STAKE,
        {
          device_id: deviceId,
          user_account: publicKey.toBase58(),
          stake_amount: convertToLamport(amount.toString()),
          serialize_transaction: partiallySignedTransaction
        },
        "post"
      );

      console.log("stakeDevice response", response);

      refreshInfo();

      eventDispatcher.dispatch({
        name: StakeEventType.STAKED_DEVICE_UPDATED,
        data: {
          device
        }
      });

      trackEvent(AnalyticsEventType.STAKING_STAKE_PROCESS_COMPLETED);
    } catch (e) {
      console.log(e);
      trackProcessError(e, {
        transaction: AnalyticsEventType.STAKING_STAKE_PROCESS_TRANSACTION_FAILED,
        middleware: AnalyticsEventType.STAKING_STAKE_PROCESS_MIDDLEWARE_FAILED,
        cancelled: AnalyticsEventType.STAKING_STAKE_PROCESS_CANCELLED,
        aborted: AnalyticsEventType.STAKING_STAKE_PROCESS_TIMED_OUT,
        capacity: AnalyticsEventType.STAKING_STAKE_PROCESS_CAPACITY_REACHED
      });
      throw toFriendlyPublicError(e);
    } finally {
      set({ isStaking: false });
    }
  },
  unstakeDevice: async (device, abortController) => {
    const { publicKey, walletOptions, refreshInfo, trackEvent, trackProcessError } = get();

    try {
      if (!walletOptions || !publicKey) {
        throw new Error("no wallet connected");
      }
      set({ unstakingDevice: device });

      trackEvent(AnalyticsEventType.STAKING_UNSTAKE_PROCESS_STARTED, {
        device
      });

      const { signTransaction } = walletOptions;

      const poolInfo = findPoolInfoAccount(device.deviceId);
      const stakeInfo = findStakeInfoAccount(poolInfo, publicKey);
      const ioTokenMint = new PublicKey(IO_TOKEN_MINT);
      const userTokenAccount = await getAssociatedTokenAddress(ioTokenMint, publicKey);
      const globalAccount = findGlobalAccount();
      const globalVaultTokenAccount = findGlobalVaultTokenAccount();

      const program = getProgramInstance(connection, walletOptions as AnchorWallet);

      const instruction = await program.methods
        // device.totalRewardAmount as number
        .unstake(new anchor.BN(0))
        .accounts({
          user: publicKey,
          superAdmin: new PublicKey(SUPER_ADMIN_PUBKEY),
          stakeInfo: stakeInfo,
          userTokenAccount: userTokenAccount,
          poolInfo: poolInfo,
          globalAccount: globalAccount,
          globalVaultTokenAccount: globalVaultTokenAccount,
          ioTokenMint: ioTokenMint,
          systemProgram: SystemProgram.programId
        })
        .instruction();
      errorIfAborted(abortController);
      const transaction = toTransactionWithPriority(new Transaction()).add(instruction);

      const { blockhash } = await connection.getLatestBlockhash("confirmed");
      transaction.recentBlockhash = blockhash;
      transaction.feePayer = publicKey!;

      const signedTransaction = await signTransaction!(transaction);

      const partiallySignedTransaction = signedTransaction
        .serialize({
          requireAllSignatures: false,
          verifySignatures: false
        })
        .toString("base64");

      const response = await proxyToMiddleware(
        ProxyToMiddlewareEndpoint.UNSTAKE,
        {
          stake_detail_id: device.id,
          device_id: device.deviceId,
          user_account: publicKey.toBase58(),
          serialize_transaction: partiallySignedTransaction.toString()
        },
        "post"
      );

      console.log("unstakeDevice response", response);

      refreshInfo();

      eventDispatcher.dispatch({
        name: StakeEventType.STAKED_DEVICE_UPDATED,
        data: {
          device
        }
      });

      trackEvent(AnalyticsEventType.STAKING_UNSTAKE_PROCESS_COMPLETED, {
        device
      });
    } catch (e) {
      console.log(e);
      trackProcessError(
        e,
        {
          transaction: AnalyticsEventType.STAKING_UNSTAKE_PROCESS_TRANSACTION_FAILED,
          middleware: AnalyticsEventType.STAKING_UNSTAKE_PROCESS_MIDDLEWARE_FAILED,
          cancelled: AnalyticsEventType.STAKING_UNSTAKE_PROCESS_CANCELLED,
          aborted: AnalyticsEventType.STAKING_UNSTAKE_PROCESS_TIMED_OUT,
          capacity: AnalyticsEventType.STAKING_UNSTAKE_PROCESS_CAPACITY_REACHED
        },
        {
          device
        }
      );
      throw toFriendlyPublicError(e);
    } finally {
      set({ unstakingDevice: undefined });
    }
  },
  withdrawDevice: async (device, abortController) => {
    const { trackProcessError } = get();

    try {
      const { publicKey, walletOptions, refreshInfo, trackEvent } = get();

      if (!walletOptions || !publicKey) {
        throw new Error("no wallet connected");
      }

      trackEvent(AnalyticsEventType.STAKING_WITHDRAW_PROCESS_STARTED, {
        device
      });

      set({ withdrawingDevice: device });

      const { signTransaction } = walletOptions;

      const poolInfo = findPoolInfoAccount(device.deviceId);
      const stakeInfo = findStakeInfoAccount(poolInfo, publicKey);
      const ioTokenMint = new PublicKey(IO_TOKEN_MINT);
      const userTokenAccount = await getAssociatedTokenAddress(ioTokenMint, publicKey);
      const globalAccount = findGlobalAccount();
      const globalVaultTokenAccount = findGlobalVaultTokenAccount();

      const program = getProgramInstance(connection, walletOptions as AnchorWallet);

      const instruction = await program.methods
        .withdraw()
        .accounts({
          user: publicKey,
          stakeInfo: stakeInfo,
          userTokenAccount: userTokenAccount,
          poolInfo: poolInfo,
          globalAccount: globalAccount,
          globalVaultTokenAccount: globalVaultTokenAccount,
          ioTokenMint: ioTokenMint,
          systemProgram: SystemProgram.programId
        })
        .instruction();

      errorIfAborted(abortController);

      const transaction = toTransactionWithPriority(new Transaction()).add(instruction);
      const { blockhash } = await connection.getLatestBlockhash("confirmed");
      transaction.recentBlockhash = blockhash;
      transaction.feePayer = publicKey!;

      const signedTransaction = await signTransaction!(transaction);
      const fullySignedTransaction = signedTransaction
        .serialize({
          requireAllSignatures: false,
          verifySignatures: false
        })
        .toString("base64");

      const response = await proxyToMiddleware(
        ProxyToMiddlewareEndpoint.WITHDRAW,
        {
          stake_detail_id: "",
          device_id: device.deviceId,
          user_account: publicKey.toBase58(),
          serialize_transaction: fullySignedTransaction.toString()
        },
        "post"
      );

      console.log("withdrawDevice response", response);

      refreshInfo();

      eventDispatcher.dispatch({
        name: StakeEventType.STAKED_DEVICE_UPDATED,
        data: {
          device
        }
      });

      trackEvent(AnalyticsEventType.STAKING_WITHDRAW_PROCESS_COMPLETED, {
        device
      });
    } catch (e) {
      console.error(e);
      trackProcessError(
        e,
        {
          transaction: AnalyticsEventType.STAKING_WITHDRAW_PROCESS_TRANSACTION_FAILED,
          middleware: AnalyticsEventType.STAKING_WITHDRAW_PROCESS_MIDDLEWARE_FAILED,
          cancelled: AnalyticsEventType.STAKING_WITHDRAW_PROCESS_CANCELLED,
          aborted: AnalyticsEventType.STAKING_WITHDRAW_PROCESS_TIMED_OUT,
          capacity: AnalyticsEventType.STAKING_WITHDRAW_PROCESS_CAPACITY_REACHED
        },
        {
          device
        }
      );
      throw toFriendlyPublicError(e);
    } finally {
      set({ withdrawingDevice: undefined });
    }
  },
  fetchUserStakedDevicesRequest: async (
    options: UserStakedDevicesRequestOptions
  ): Promise<UserStakedDeviceResult> => {
    const {
      status = "all",
      page = 1,
      limit = DEFAULT_PAGINATION_LIMIT,
      deviceId = undefined,
      hardwareName
    } = options;

    const userId = useUserStore.getState().userId;
    const response = await executeAPIRequest<{
      data: PaginatedResponse<{
        statuses: string[];
        devices: UserStakedDeviceResponse[];
        hardware: string[];
        page: number;
        page_size: number;
      }>;
      status: string;
    }>({
      method: "get",
      url: `/io-worker/users/${userId}/devices_staking?${stringify({
        // ...(status != "all" ? { status } : {}),
        page,
        device_id: deviceId,
        page_size: limit,
        ...(hardwareName !== "All Devices"
          ? {
              hardware_name: hardwareName
            }
          : {})
      })}`
    });

    const { data } = response;

    if (!data || !data.devices) {
      return {
        resultCount: 0,
        results: [],
        statuses: []
      };
    }

    const hasAllResults = data.devices.length < limit;

    let results = data.devices.map((item) => {
      const { status } = item;
      return normaliseUserStakedDevice({
        ...item,
        status: status ? status : "all"
      });
    });

    if (status && status !== "all") {
      const statues = status.split(",");

      results = results.filter((result) => {
        return statues.includes(result.status);
      });
    }

    if (env.STAKING_SHOW_ONLY_ONLINE_DEVICES) {
      results = results.filter((result) => {
        if (result.amountFrozen > 0 || result.amountStaked > 0 || result.amountWithdrawable > 0) {
          return true;
        }
        return !result.deviceStatus || [DeviceStatusType.UP].includes(result.deviceStatus);
      });
    }

    if (page === 1 && !deviceId) {
      set({ hardwareList: data.hardware });
    }

    return {
      results,
      statuses: data.statuses,
      ...(hasAllResults
        ? {
            resultCount: results.length
          }
        : {})
    };
  },
  fetchStakingSearch: async (...args) => {
    const { fetchUserStakedDevicesRequest } = get();

    const { results } = await fetchUserStakedDevicesRequest(...args);
    return results;
  }
}));

const proxyToMiddleware = async (
  endPoint: ProxyToMiddlewareEndpoint,
  data: Record<string, string>,
  method: "get" | "post"
) => {
  const userId = useUserStore.getState().userId;
  const response = await executeAPIRequest<{
    data: unknown;
  }>({
    method,
    url: `/io-worker/users${MAPPING[endPoint]({
      user_id: userId,
      ...data
    })}${method === "get" ? `?${stringify(data)}` : ``}`,
    options: {
      data,
      publicError: true,
      axiosRetries: 0,
      timeout: 90000
    }
  });

  return response?.data;
};

// POST /{user_id}/devices_staking/{device_id}/stake
// POST /{user_id}/devices_staking/{device_id}/unstake
// POST /{user_id}/devices_staking/withdraw
// GET /{user_id}/devices_staking/aggregate
const MAPPING = {
  [ProxyToMiddlewareEndpoint.INFO]: (data) => {
    return `/${data.user_id}/devices_staking/aggregate`;
  },
  [ProxyToMiddlewareEndpoint.STAKE]: (data) => {
    return `/${data.user_id}/devices_staking/${data.device_id}/stake`;
  },
  [ProxyToMiddlewareEndpoint.UNSTAKE]: (data) => {
    return `/${data.user_id}/devices_staking/${data.device_id}/unstake`;
  },
  [ProxyToMiddlewareEndpoint.WITHDRAW]: (data) => {
    return `/${data.user_id}/devices_staking/${data.device_id}/withdraw`;
  }
} as Record<ProxyToMiddlewareEndpoint, (data: Record<string, string>) => string>;

const normaliseMiddlewareUserStakedDevice = (response: MiddlewareUserStakedDeviceResponse) => {
  const freezeEndTime =
    typeof response.freeze_end_time === "string"
      ? new Date(Date.parse(response.freeze_end_time))
      : null;

  return {
    id: `${response.id}`,
    stakeInfoId: response.stake_info_id,
    deviceId: response.device_id,
    userId: response.user_id,
    userAccount: response.user_account,
    amountStaked: parseFloat(convertLamportBack(response.stake_amount)),
    totalRewardAmount: parseFloat(convertLamportBack(response.total_reward_amount)),
    createBlockTime: response.create_block_time,
    createDate: response.create_date,
    updateDate: response.update_date,
    amountFrozen: parseFloat(convertLamportBack(response.freeze_amount)),
    freezeEndTime,
    amountWithdrawable: parseFloat(convertLamportBack(response.withdrawable)),
    status: response.status,
    hardwareQuantity: 1,
    hardwareManufacturer: "Unknown",
    hardwareName: "Unknown",
    hardwareManufacturerColor: "text-green-dark-100"
  } as UserStakedDevice;
};

const normaliseStakeEligibleDevice = (response: MiddlewareStakeEligibleDeviceResponse) => {
  return {
    createDate: formatDateTime(response.create_date),
    deviceId: response.device_id,
    id: `${response.id}`,
    lastRewardsTime: formatDateTime(response.last_rewards_time),
    minimumStakeAmount: parseFloat(convertLamportBack(response.minimum_stake_amount)),
    totalRewards: parseFloat(convertLamportBack(response.total_rewards)),
    totalStakeAccountCount: parseInt(response.total_stake_account, 10),
    totalStakeAmount: parseFloat(convertLamportBack(response.total_stake_amount)),
    updateDate: formatDateTime(response.update_date)
  } as MiddlewareStakeEligibleDevice;
};

export const toStakeEligibleDevice = (device: UserStakedDevice) => {
  return {
    createDate: "",
    deviceId: `${device.deviceId}`,
    deviceName: device.deviceName,
    id: `${device.id}`,
    lastRewardsTime: "",
    minimumStakeAmount: 0,
    totalRewards: 0,
    totalStakeAccountCount: 1,
    totalStakeAmount: device.amountStaked,
    updateDate: ""
  } as MiddlewareStakeEligibleDevice;
};

const normaliseUserStakedDevice = (result: UserStakedDeviceResponse) => {
  const statusType = result.status.toLowerCase();
  const deviceStatus = STATUS_MAP[`${statusType}` as keyof typeof STATUS_MAP];
  const id = `${result.device_id}`;
  const hardware = getHardware(result.brand_name);
  const freezeEndTime =
    typeof result.freeze_end_time === "string"
      ? new Date(Date.parse(`${result.freeze_end_time}Z`))
      : null;
  const hasStakedMinimum = (result?.amount_staked || 0) >= (result?.minimum_staked || -1);
  const remainingInterval =
    freezeEndTime instanceof Date
      ? Math.max(0, freezeEndTime.getTime() - new Date().getTime())
      : undefined;
  const frozenTimeRemaining =
    typeof remainingInterval === "number" ? toDuration(remainingInterval) : undefined;
  const frozenTimeShortRemaining =
    typeof frozenTimeRemaining === "string"
      ? frozenTimeRemaining.split(" ").slice(0, 2).join(" ")
      : undefined;
  const frozenTimeRemainingAsPercentage =
    typeof remainingInterval === "number"
      ? Math.min(
          100,
          Math.max(0, ((STAKE_FROZEN_DURATION - remainingInterval) / STAKE_FROZEN_DURATION) * 100)
        )
      : undefined;
  const stakedWalletAddress =
    result.amount_staked === 0 && result.amount_frozen === 0 && result.amount_withdrawable === 0
      ? undefined
      : result.user_account;

  return {
    id,
    status: getUserStakedDeviceStatus(result),
    deviceId: result.device_id,
    deviceName: result.device_name,
    minimumStaked: parseFloat(convertLamportBack(result.minimum_staked)),
    hardwareQuantity: result.hardware_quantity,
    hardwareName: result.hardware_name ?? "Unknown",
    blockRewards: result.block_rewards,
    amountStaked: parseFloat(convertLamportBack(result.amount_staked)),
    amountFrozen: parseFloat(convertLamportBack(result.amount_frozen)),
    amountWithdrawable: parseFloat(convertLamportBack(result.amount_withdrawable)),
    aprEstimate: result.apr_estimate,
    deviceStatus: deviceStatus.status,
    ...(hardware
      ? hardware
      : {
          hardwareManufacturerColor: "text-green-dark-100",
          hardwareManufacturer: "Unknown"
        }),
    freezeEndTime,
    hasStakedMinimum,
    frozenTimeRemaining,
    frozenTimeShortRemaining,
    frozenTimeRemainingAsPercentage,
    stakedWalletAddress
  } as UserStakedDevice;
};

// 2 mins or 14 days
const STAKE_FROZEN_DURATION =
  env.STAKING_NETWORK === "devnet" ? 1000 * 60 * 2 : 1000 * 60 * 60 * 24 * 14;

const getUserStakedDeviceStatus = (result: UserStakedDeviceResponse) => {
  if (result.amount_staked >= result.minimum_staked) {
    return UserStakedDeviceStatusType.SUFFICIENT;
  }
  return UserStakedDeviceStatusType.INSUFFICIENT;
};

export const MODIFY_COMPUTE_UNITS = ComputeBudgetProgram.setComputeUnitLimit({
  units: env.STAKING_COMPUTE_UNIT_LIMIT
});

export const ADD_PRIORITY_FEE = ComputeBudgetProgram.setComputeUnitPrice({
  microLamports: env.STAKING_COMPUTE_UNIT_PRICE
});

const toTransactionWithPriority = (transaction: Transaction) => {
  transaction.add(MODIFY_COMPUTE_UNITS).add(ADD_PRIORITY_FEE);

  return transaction;
};

export const isCapacityReachedError = (e: unknown) => {
  const friendlyError = toFriendlyPublicError(e);

  return friendlyError?.message === "Hardware staking pool capacity exceeded";
};

import { IconProps } from ".";

export const AlertTriangleIcon: React.FC<IconProps> = (props) => {
  return (
    <svg viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M15.9486 15.1461L8.51882 0.297452V0.291522C8.34165 0.000953547 7.97587 -0.0879959 7.69583 0.0958331C7.61582 0.143273 7.55295 0.208502 7.50723 0.285592L0.0774822 15.1283H0.071767C-0.0825431 15.4129 0.0203303 15.7747 0.300375 15.9288C0.380387 15.9704 0.47183 16 0.568989 16H15.4285C15.7428 15.9941 16 15.7332 16 15.4011C15.9943 15.3003 15.9714 15.1995 15.9257 15.1164L15.9486 15.1461ZM7.37006 4.75679H8.65598V10.6808H7.37006V4.75679ZM8.01017 14.2388C7.5358 14.2388 7.15289 13.8356 7.15289 13.3493C7.15289 12.8571 7.5358 12.4598 8.01017 12.4598C8.47881 12.4598 8.86744 12.8571 8.86744 13.3493C8.86744 13.8356 8.47881 14.2388 8.01017 14.2388Z"
        fill="currentColor"
      />
    </svg>
  );
};

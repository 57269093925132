export type TextInputProps = {
  value?: string;
  type?: string;
  disabled?: boolean;
  name: string;
  placeholder?: string;
  onChange: (value: string) => void;
};

export default function TextInput({
  onChange,
  value = "",
  name,
  placeholder,
  type = "text"
}: TextInputProps) {
  return (
    <input
      type={type}
      value={value}
      name={name}
      onChange={(e) => onChange(e.target.value)}
      placeholder={placeholder}
      className="flex-1 rounded-md border border-gray-dark-900 bg-white px-4 py-2 text-xs text-black focus:border-black dark:bg-black dark:text-white dark:focus:border-white"
    ></input>
  );
}

import { Connection, PublicKey } from "@solana/web3.js";
import { AccountLayout } from "@solana/spl-token";
import axios from "axios";
import env from "@/env";
import { getStakingNetworkApiEndpoint } from "@/utils/staking";
import { LAMPORTS_PER_SOL_IO_TOKEN } from "./utils";

let connection: Connection | null = null;

const getConnection = () => {
  if (!connection) {
    connection = new Connection(getStakingNetworkApiEndpoint(), "confirmed");
  }
  return connection;
};

// export const BASE_URL = "http://localhost:3002";
// export const BASE_URL = "http://3.101.142.119:3002";
export const BASE_URL = "https://staking-aws-dev.io.solutions";
export const PROGRAM_ID = env.STAKING_CONTRACT_ADDRESS;
export const IO_TOKEN_MINT = env.STAKING_MINT_TOKEN_ADDRESS;

export async function getSolanaBalance(publicKey: PublicKey): Promise<number> {
  try {
    const balance = await getConnection().getBalance(publicKey);
    return balance / LAMPORTS_PER_SOL_IO_TOKEN;
  } catch (error) {
    console.error("Error fetching SOL balance:", error);
    throw error;
  }
}

export const getTokenAccountBalance = async (tokenAccountPubkey: PublicKey) => {
  const tokenAccountInfo = await getConnection().getAccountInfo(tokenAccountPubkey);
  if (tokenAccountInfo === null) {
    throw new Error("Failed to find token account");
  }
  const accountData = AccountLayout.decode(tokenAccountInfo.data);
  const balance = accountData.amount;
  return balance;
};

export const getUserTokenBalance = async (pubKey: PublicKey, tokenMintAddress: string) => {
  const tokenAccounts = await getConnection().getParsedTokenAccountsByOwner(pubKey, {
    mint: new PublicKey(tokenMintAddress)
  });

  if (tokenAccounts.value.length === 0) {
    return 0;
  }

  let totalBalance = BigInt(0);
  for (const accountInfo of tokenAccounts.value) {
    const tokenAccountPubkey = new PublicKey(accountInfo.pubkey);
    const balance = await getTokenAccountBalance(tokenAccountPubkey);
    totalBalance += balance;
  }
  return (Number(totalBalance) / LAMPORTS_PER_SOL_IO_TOKEN).toFixed(2);
};

export const handleMiddlewareStaking = async (
  deviceId: string,
  userAccount: string,
  stakeAmount: string,
  serializeTransaction: string
) => {
  try {
    const response = await axios.post(`${BASE_URL}/staking/userStaking`, {
      device_id: deviceId,
      user_account: userAccount,
      stake_amount: stakeAmount,
      serialize_transaction: serializeTransaction
    });
    console.log("🚀 ~ handleStake ~ response:", response);
    return response;
  } catch (error) {
    console.log("🚀 ~ handleStake ~ error:", error);
    throw error;
  }
};

export const handleMiddlewareUnstaking = async (
  stake_detail_id: number | string,
  deviceId: string,
  userAccount: string,
  serializeTransaction: string
) => {
  try {
    const response = await axios.post(`${BASE_URL}/staking/userUnstaking`, {
      stake_detail_id: stake_detail_id,
      device_id: deviceId,
      user_account: userAccount,
      serialize_transaction: serializeTransaction
    });
    console.log("🚀 ~ handleStake ~ response:", response);
  } catch (error) {
    console.log("🚀 ~ handleStake ~ error:", error);
    throw error;
  }
};

export const handleMiddlewareWithdraw = async (
  stake_detail_id: number | string,
  deviceId: string,
  userAccount: string,
  flag?: boolean
) => {
  try {
    const response = await axios.post(`${BASE_URL}/staking/userWithdrawing`, {
      stake_detail_id: stake_detail_id,
      device_id: deviceId,
      user_account: userAccount,
      cooldown_finish_flag: flag
    });
    console.log("🚀 ~ handleStake ~ response:", response);
  } catch (error) {
    console.log("🚀 ~ handleStake ~ error:", error);
    throw error;
  }
};

export const getUserStakingData = async (userAccount: string) => {
  try {
    const response = await axios.get(`${BASE_URL}/staking/user/${userAccount}`);
    console.log("🚀 ~ fetchStakingData ~ response:", response);
    return response.data.data;
  } catch (error) {
    console.log("🚀 ~ fetchStakingData ~ error:", error);
  }
};

export const getAllStakeInfo = async (device_id: string, page: number) => {
  if (!device_id) device_id = "";
  try {
    const response = await axios.get(
      `${BASE_URL}/staking/getAllStakeInfo?device_id=${device_id}&page=${page}`
    );
    console.log("🚀 ~ getAllStakeInfo ~ response:", response);
    return response.data.data;
  } catch (error) {
    console.log("🚀 ~ getAllStakeInfo ~ error:", error);
  }
};

export const getStakeInfoByUserAccount = async (user_account: string, page: number) => {
  try {
    const response = await axios.get(
      `${BASE_URL}/staking/getStakeInfoByUserAccount?user_account=${user_account}&page=${page}`
    );
    console.log("🚀 ~ getStakeInfoByUserAccount ~ response:", response);
    return response.data.data;
  } catch (error) {
    console.log("🚀 ~ getStakeInfoByUserAccount ~ error:", error);
  }
};

export const getAllDeviceInfo = async (page: number) => {
  try {
    const response = await axios.get(`${BASE_URL}/device?page=${page + 4}`);
    console.log("🚀 ~ getAllDeviceInfo ~ response:", response);
    return response.data.data;
  } catch (error) {
    console.log("🚀 ~ getAllDeviceInfo ~ error:", error);
  }
};

export const getUserStakeAggregateData = async (user_account: string) => {
  try {
    const response = await axios.get(`${BASE_URL}/staking/aggregate?user_account=${user_account}`);
    console.log("🚀 ~ getUserStakeAggregateData ~ response:", response);
    return response.data.data;
  } catch (error) {
    console.log("🚀 ~ getUserStakeAggregateData ~ error:", error);
  }
};

export const distrubuteRewards = async (round?: string) => {
  let queryParams = "";
  if (round) {
    queryParams = `?reward_round=${round}`;
  }
  try {
    const response = await axios.get(`${BASE_URL}/device-rewards/distrubuteRewards${queryParams}`);
    console.log("🚀 ~ distrubuteRewards ~ response:", response);
    return response.data.data;
  } catch (error) {
    console.log("🚀 ~ distrubuteRewards ~ error:", error);
  }
};

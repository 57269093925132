import { FieldProps } from "formik";

interface BaseFieldLayoutProps<T> extends FieldProps {
  label?: string;
  inputComponent: React.ComponentType<T>;
  hideError?: boolean;
}

const BaseFieldLayout = (props: BaseFieldLayoutProps<{ onChange: (value: unknown) => void }>) => {
  const { label, inputComponent: InputComponent, hideError, form, field, ...rest } = props;

  const error =
    !hideError && form.errors[field.name] && form.touched[field.name]
      ? form.errors[field.name]
      : "";
  const errorText = Array.isArray(error) ? error.join(", ") : error;

  return (
    <div className="relative flex flex-1 flex-col pb-5">
      {label ? <label className="mb-2 text-sm">{label}</label> : null}
      <InputComponent
        {...rest}
        {...field}
        onChange={(value: unknown) => {
          form.setFieldTouched(field.name, true, true);
          form.setFieldValue(field.name, value, true);
        }}
      />
      {error && (
        <div className="absolute bottom-0 left-0 text-xs text-red">{errorText as string}</div>
      )}
    </div>
  );
};

export default BaseFieldLayout;

import { IconProps } from "@/components/Icons/index";

export const LinkedInIcon: React.FC<IconProps> = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="none" {...props}>
    <path
      d="M5.78333 4.15833C5.775 4.83333 5.36667 5.44167 4.74167 5.7C4.10833 5.95 3.39167 5.8 2.91667 5.30833C2.44167 4.81667 2.30833 4.09167 2.58333 3.475C2.85 2.85 3.475 2.45833 4.15 2.475C5.05 2.5 5.76667 3.23333 5.76667 4.14167L5.78333 4.15833ZM5.83333 7.05833H2.5V17.4833H5.83333V7.05V7.05833ZM11.1 7.05833H7.775V17.4833H11.0583V12.0083C11.0583 8.95833 15.0333 8.675 15.0333 12.0083V17.4833H18.325V10.875C18.325 5.73333 12.4417 5.925 11.0583 8.45L11.0917 7.05L11.1 7.05833Z"
      fill="currentColor"
    />
  </svg>
);

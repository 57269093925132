import { NvidiaIcon } from "@/components/Icons/NvidiaIcon";
import { DeviceStatusType } from "@/utils/device";
import React from "react";

export type DeviceIntelligence = {
  device_intelligence: {
    device_score: number;
    is_anti_fingerprinting: boolean;
    is_bot: boolean;
    is_emulated: boolean;
    is_incognito: boolean;
    is_proxy: boolean;
    is_tor: boolean;
    is_browser_spoofed: boolean;
    shield_id: string;
  };
  platform: string;
  session_id: string;
  timestamp: number;
  version: string;
};

export type LivePricing = {
  type: string;
  typeColor: string;
  typeIcon: typeof NvidiaIcon;
  name: string;
  availabilityCount: number;
  cost: string;
  costChange: number;
  busyPercentage: number;
  id: string;
};

export type OptionType = {
  value: string;
  label: string;
  disabled?: boolean;
  icon?: React.ReactNode;
  count?: number;
};

export type ClusterResponse = {
  cluster_id?: string;
  resource_name: string;
  resource_id: string;
  status: string;
  percent_completed: number;
  hardware_quantity: number;
  hardware_name: string;
  icon: string;
  brand_name: string;
  brand_info:
    | {
        brand_name: string;
        brand_icon: string;
      }
    | {
        brand_name: string;
        brand_icon: string;
      }[];
  jupyter_url?: string;
  vscode_url?: string;
  ray_dashboard_url?: string;
  compute_hours_served: string;
  compute_hours_remaining: string;
  started_at: string;
  is_mega_cluster: boolean;
};

export type Hardware = {
  hardwareManufacturerColor: string;
  hardwareManufacturerIcon: typeof NvidiaIcon;
  hardwareManufacturer: string;
};

export type Cluster = {
  id: string;
  type?: string;
  name: string;
  hasIncident: boolean;
  hardwareName: string;
  hardwareQuantity: number;
  computeHoursRemaining: "1 Hrs 40 Mins";
  computeHoursServed: "1 Hrs 40 Mins";
  percentCompleted: number;
  status: string;
  statusColor: string;
  statusMessage: string;
  statusLabel: string;
  statusValue: string;
  jupyterUrl?: string;
  vscodeUrl?: string;
  rayDashboardUrl?: string;
  startedAt: string;
  isMega?: boolean;
  hardwares?: Hardware[];
  href?: string;
} & Hardware;

export type ClusterDetailResponse = {
  now?: number;
  cluster_id: string;
  cluster_name: string;
  status: string;
  percent_completed: number;
  started_at: string;
  finished_at: string;
  public_ip: string;
  ray_dashboard_url: string;
  vscode_url: string;
  python_version: string;
  ray_version: string;
  compute_hours_served: string;
  compute_hours_remaining: string;
  locations: {
    location_id: number;
    location_name: string;
    iso2: string;
  }[];
  security_compliance: string;
  service_name: string;
  service_icon: string;
  base_tier_name: string;
  download_speed_mbps: number;
  upload_speed_mbps: number;
  hardware_quantity: number;
  amount_paid: number;
  amount_refunded: number;
  jupyter_url?: string;
  dashboard_auth_key?: string;
  total_age?: string;
  running_for?: string;
  brand_name: string;
  hardware_name?: string;
  hired_hours_remaining: number;
  average_per_card: number;
  k8s_file: string;
  is_mega_cluster?: boolean;
  hardware_info?: {
    hardware_id: number;
    hardware_name: string;
    quantity: number;
  };
  brand_info: {
    brand_name: string;
    brand_icon: string;
  }[];
  currency: "iocoin" | "usdc";
};

export type ClusterDetail = {
  amountPaid: number;
  amountRefunded: number;
  baseTierName: string;
  clusterId: string;
  clusterName: string;
  computeHoursRemaining: string;
  computeHoursServed: string;
  downloadSpeedMbps: number;
  finishedAt: string;
  hardwareQuantity: number;
  percentCompleted: number;
  publicIp: string;
  pythonVersion: string;
  rayDashboardUrl: string;
  rayVersion: string;
  securityCompliance: false;
  serviceIcon: typeof NvidiaIcon;
  serviceName: string;
  startedAt: string;
  status: string;
  statusColor: string;
  statusMessage: string;
  statusLabel: string;
  uploadSpeedMbps: number;
  vscodeUrl: string;
  runTime: string;
  locations: {
    value: string;
    label: string;
    code: string;
  }[];
  jupyterUrl: string;
  dashboardAuthKey: string;
  totalAge: string;
  runningFor: string;
  hardwareName: string;
  hiredHoursRemaining: number;
  averagePerCard: number;
  k8sFile: string;
  isMega: boolean;
  hardwares?: Hardware[];
  currency: "iocoin" | "usdc";
} & Hardware;

export type ClusterWorkerInfoResponse = {
  total_gpu: number;
  total_cpu: number;
  workers_info: ClusterWorkerResponse[];
};

export type ClusterWorkerInfo = {
  totalGpu: number;
  totalCpu: number;
  items: ClusterWorker[];
};

export type ClusterWorkerResponse = {
  device_id?: string;
  uptime_percent?: number;
  compute_minutes_served: number;
  total_hire_rate: number;
  earned: number;
  slashed: number;
  hardware_name: string;
  brand_name: string;
  brand_icon: string;
  hardware_quantity?: number;
  status: string;
};

export type ClusterWorker = {
  id: string;
  computeMinutesServed: number;
  deviceId: string;
  earned: number;
  slashed: number;
  totalHireRate: number;
  uptimePercent: number;
  hardwareName: string;
  hardwareManufacturerIcon: typeof NvidiaIcon;
  hardwareManufacturer: string;
  hardwareManufacturerColor: string;
  hardwareQuantity: number;
  status: string;
};

export type SupplyHardware = {
  quantity: number;
  hardware_id: number;
  quantity_available: number;
};

export type CreateClusterDataFilteredResponse = {
  supplier_id: number;
  hardware_id: number;
  connectivity_tier: number;
  security_soc2: boolean;
  service_id: number;
  hardware_qty: number;
  location_id: string;
}[];

export type CreateClusterDataResponse = {
  securitySoc2Enabled?: boolean;
  suppliers: {
    id: number;
    name: string;
    icon: string;
    description: string;
    busy_percent: number;
    device_count: number;
  }[];
  hardwares: {
    hardware_id: number;
    type: string;
    name: string;
    hardware_quantity: number;
    price: number;
    brand_name: string;
    brand_icon: string;
    busy_percent: number;
  }[];
  locations: {
    id: number;
    name: string;
    icon: string;
    description: string;
    busy_percent: number;
    iso2: string;
  }[];
  connectivity_tiers: {
    connectivity_tier: number;
    name: string;
    download_speed_mbps: number;
    upload_speed_mbps: number;
  }[];
  services: {
    id: number;
    name: string;
    icon: string;
    description: string;
    disabled?: boolean;
  }[];
  durations: {
    duration: string;
    percent: number;
  }[];
};

export type CreateClusterData = {
  clusterTypes: {
    description: string;
    icon: typeof NvidiaIcon;
    id: string;
    name: string;
    disabled?: boolean;
    validate?: (values: Partial<ClusterForm>) => boolean;
  }[];
  connectivityTiers: {
    id: string;
    downloadSpeedMbps: number;
    name: string;
    uploadSpeedMbps: number;
    disabled?: boolean;
  }[];
  durations: {
    duration: string;
    percentage: number;
  }[];
  locations: ClusterLocation[];
  services: {
    description: string;
    icon: typeof NvidiaIcon;
    id: string;
    name: string;
    disabled: boolean;
    unavailable?: boolean;
  }[];
  suppliers: {
    busyPercent: number;
    description: string;
    icon: React.ReactNode;
    id: string;
    name: string;
    disabled?: boolean;
    unavailable?: boolean;
    validate?: (values: Partial<ClusterForm>) => boolean;
  }[];
  hardwareTypes: {
    value: string;
    label: string;
  }[];
  hardwareManufacturers: {
    value: string;
    label: string;
    icon: typeof NvidiaIcon;
  }[];
  hardwares: ClusterHardwareMap;
  hardwareList: ClusterHardware[];
  sustainable: {
    validate: (values: Partial<ClusterForm>) => boolean;
  };
  securityCompliances: {
    icon: typeof NvidiaIcon;
    id: string;
    name: string;
    disabled: boolean;
    unavailable?: boolean;
  }[];
  currencyConversionFeePrecent?: number;
  ionetFeePrecent?: number;
};

export type ClusterHardwareMap = {
  [hardwareType: string]:
    | {
        [hardwareManufacturer: string]: ClusterHardware[] | undefined;
      }
    | undefined;
};

export type ClusterHardware = {
  id: string;
  available: number;
  hardwareManufacturer?: string;
  hardwareManufacturerIcon?: typeof NvidiaIcon;
  hardwareManufacturerColor?: string;
  hardwareName: string;
  busyPercent: number;
  price: number;
  total: number;
  disabled?: boolean;
  type: string;
};

export type CompletedCluster = {
  id: number;
  status: string;
  statusColor: string;
  statusMessage: string;
  name: string;
  runTime: string;
  processorIcon: typeof NvidiaIcon;
  processorColor: string;
  processorBitType: string;
  processorName: string;
};

export type ClusterLocation = {
  id: string;
  name: string;
  icon?: typeof NvidiaIcon;
  busyPercentage?: number;
  description?: string;
  code?: string;
  disabled?: boolean;
};

export type Tool = {
  value: string;
  label: string;
  icon: typeof NvidiaIcon;
  href: string;
  prefix: string;
  logo?: typeof NvidiaIcon;
  logoImage?: string;
  bg?: string;
  menuItems?: {
    value: string;
    label: string;
    icon: typeof NvidiaIcon;
    href: string;
    disabled?: boolean;
  }[];
};

export type InferenceType = {
  id: string;
  name: string;
  hasIncident: boolean;
  hardwareIcon?: typeof NvidiaIcon;
  hardwareManufacturerColor: string;
  hardwareName: string;
  hardwareQuantity: number;
  hardwareManufacturerIcon: typeof NvidiaIcon;
  hardwareManufacturer: string;
  computeHoursRemaining: string;
  computeHoursServed: string;
  percentCompleted: number;
  status: string;
  statusColor: string;
  statusMessage: string;
  statusLabel: string;
  statusValue: string;
  jupyterUrl?: string;
  vscodeUrl?: string;
  rayDashboardUrl?: string;
  startedAt: string;
  isBasedIO: boolean;
  computedDate: string;
};
export type InferenceSummary = {
  deviceId: string;
  isBasedAI: boolean;
  status: string;
  computedHours: string;
  createdAt: string;
  statusColor: string;
  statusMessage: string;
  link: string;
  bc8Link: string;
  inferencePrice: string;
  royaltyFee: string;
  networkFee: string;
  computedFee: string;
  hardwareQuantity: number;
  hardwareManufacturerColor: string;
  hardwareManufacturerIcon: typeof NvidiaIcon;
  hardwareName: string;
  clusterId: string;
  clusterOverload: number;
};

export type InferenceDetailResponse = {
  app_inference_price: number;
  app_inference_txid_url: string;
  app_name: string;
  cluster_id: string;
  compute_duration: string;
  created_at: string;
  inference_id: string;
  inference_verification_url: string;
  io_network_fee: number;
  io_network_fee_txid_url: string;
  model_owner_royalty_fee: number;
  model_owner_royalty_fee_txid_url: string;
  requested_by: string;
  compute_cost: number;
  status: string;
  compute_cost_txid_url: string;
  supplier: InferenceDetailSupplierResponse;
  href?: string;
};

export type InferenceDetailSupplierResponse = {
  icon: string;
  name: string;
  busy_percent: number;
  is_active: boolean;
};

export type InferenceDetail = {
  appInferencePrice: number;
  appInferenceTxidUrl: string;
  appName: string;
  clusterId: string;
  computeDuration: string;
  createdAt: string;
  inferenceId: string;
  inferenceVerificationUrl: string;
  ioNetworkFee: number;
  ioNetworkFeeTxidUrl: string;
  modelOwnerRoyaltyFee: number;
  modelOwnerRoyaltyFeeTxidUrl: string;
  requestedBy: string;
  computeCost: number;
  status: string;
  computeCostTxidUrl: string;
  supplierName?: string;
  supplierIcon?: typeof NvidiaIcon;
  supplierIconColor?: string;
};

export type InferenceStats = {
  total_inference_count: number;
  total_inference_cost: number;
  total_inferences_today: number;
  total_inferences_today_cost: number;
};

export type DeviceResponse = {
  brand_icon: string;
  device_id: number;
  device_name: string;
  hardware: string;
  hardware_name?: string;
  hardware_quantity: number;
  operating_system: string;
  status: string;
  status_duration: string;
  brand_name: string;
  is_working: boolean;
  last_audit_successful: boolean | null;
  last_challenge_successful: boolean | null;
  readiness_info: {
    readiness: string;
    info: string;
  };
};

export type Device = {
  id: string;
  name: string;
  operatingSystem: string;
  status: DeviceStatusType;
  statusLabel: string;
  statusColor: string;
  statusDuration: React.ReactNode;
  statusValue: string;
  networkMapLabel: string;
  hardwareName: string;
  hardwareQuantity: number;
  hardwareIcon: typeof NvidiaIcon;
  hardwareManufacturerIcon: typeof NvidiaIcon;
  hardwareManufacturer: string;
  hardwareManufacturerColor: string;
  isWorking: boolean;
  upFor?: string;
  href?: string;
  isVerified: boolean | null;
  isChecked: boolean | null;
  readinessInfo: {
    readiness: string;
    info: string;
  };
};

export type DeviceDetailResponse = {
  device_id: string;
  hardware_quantity: number;
  security_soc2: boolean;
  location_name: string;
  location_icon: string;
  iso2: string;
  brand_icon: string;
  hardware_name: string;
  download_speed_mbps: number;
  upload_speed_mbps: number;
  base_tier_name: string;
  jobs: unknown[];
  down_percentage: number;
  downtime_by_date: {
    [k: string]: {
      downtime: number;
      note: string;
    };
  };
  brand_name: string;
  ray_version?: string;
  python_version?: string;
  supplier_name: string;
  supplier?: {
    name: string;
    icon: string;
    is_active: boolean;
    busy_percent: number;
  };
  start_date: string;
  device_type: string;
  operating_system: string;
  status: string;
  readiness_info: {
    readiness: string;
    info: string;
  };
};

export type DeviceDetail = {
  status: string;
  id: string;
  brandIcon: string;
  downPercentage: number | null;
  upPercentage: number | null;
  downtimeByDate: {
    [k: string]: {
      downtime: number;
      note: string;
    };
  } | null;
  hardwareName: string;
  hardwareQuantity: number;
  hardwareOSIcon?: typeof NvidiaIcon;
  hardwareManufacturerIcon: typeof NvidiaIcon;
  hardwareManufacturer: string;
  hardwareManufacturerColor: string;
  locationIcon: string;
  locationName: string;
  locationCode: string;
  securitySoc2: boolean;
  connectivityTierName: string;
  supplierName?: string;
  supplierIcon?: typeof NvidiaIcon;
  supplierIconColor?: string;
  createdAt: string;
  deviceType: string;
  operatingSystem: string;
  operatingSystemIcon?: typeof NvidiaIcon;
  readinessInfo: {
    readiness: string;
    info: string;
  };
};

export type DeviceNotificationResponse = {
  type: string;
  created_at: string;
  device_id: string;
  id: number;
  text: string;
};

export type DeviceNotification = {
  id: string;
  type: "success" | "error" | "info" | "alert";
  createdAt: string;
  deviceId: string;
  text: string;
  icon: typeof NvidiaIcon;
  color: string;
};

export type DeviceServiceResponse = {
  id: number;
  description: string;
  icon: string;
  is_running: boolean;
  name: string;
  status: string;
};

export type DeviceService = {
  id: string;
  description: string;
  icon: typeof NvidiaIcon;
  isRunning: boolean;
  name: string;
  status: string;
};

export type DeviceHistoricalJobResponse = {
  for_id: string;
  device_id: string;
  hardware_name: string;
  brand_name: string;
  status: string;
  start_time: string;
  end_time: string;
  total_hire_rate: number;
  earned: number;
  slashed: number;
  compute_hours_served: number;
  compute_hours_hired: number;
  icon: string;
  currency: "usdc" | "iocoin";
  verification_job?: boolean;
};

export type DeviceJobResponse = {
  id?: string;
  for: string;
  for_id: string;
  resource_id: string;
  device_id: string;
  start_time: string;
  end_time: string;
  uptime_percent: number;
  total_hire_rate: number;
  slashed: number;
  earned: number;
  status: string;
  earning_claim_txid_url: string;
  created_at: string;
  updated_at: string;
  compute_hours_served: string;
  compute_hours_hired: string;
  hardware_name?: string;
  brand_name?: string;
  currency: "usdc" | "iocoin";
  verification_job?: boolean;
};

export type DeviceJob = {
  computeHoursHired: string;
  computeHoursServed: string;
  createdAt: string;
  deviceId: string;
  forId: string;
  resourceId: string;
  earned: number;
  earningClaimTxidUrl: string;
  endTime: string;
  id: string;
  slashed: number;
  startTime: string;
  status: string;
  totalHireRate: number;
  updatedAt: string;
  uptimePercent: number;
  currency: "usdc" | "iocoin";
  verificationJob: boolean;
};

export type DeviceHistoricalJob = {
  id: string;
  forId: string;
  deviceId: string;
  status: string;
  startTime: string;
  endTime: string;
  totalHireRate: number;
  earned: number;
  slashed: number;
  computeHoursServed: number;
  computeHoursHired: number;
  uptimePercent: number;
  hardwareName: string;
  hardwareManufacturerIcon: typeof NvidiaIcon;
  hardwareManufacturer: string;
  hardwareManufacturerColor: string;
  currency: "usdc" | "iocoin";
  verificationJob: boolean;
};

export type DeviceSummaryResponse = {
  device_id: string;
  device_name: string;
  status: string;
  status_duration: string;
  is_working: boolean;
  total_compute_hours_served: number;
  total_jobs: number;
  total_earnings: number;
  total_slashed_earning: number;
  download_speed_mbps: number;
  upload_speed_mbps: number;
  connectivity_tier: number;
  down_percentage: number;
  last_challenge_successful: boolean | null;
  last_audit_successful: boolean | null;
  total_earnings_io_coin?: number;
  total_slahed_io_coin?: number;
  downtime_by_date: {
    [k: string]: {
      downtime: number;
      note: string;
    };
  };
};

export type DeviceSummary = {
  name: string;
  connectivityTier: number;
  deviceId: string;
  downPercentage: number;
  downloadSpeedMbps: number;
  downtimeByDate: {
    [k: string]: {
      downtime: number;
      note: string;
    };
  };
  totalComputeHoursServed: number;
  totalEarnings: number;
  totalJobs: number;
  totalSlashedEarning: number;
  uploadSpeedMbps: number;
  status: DeviceStatusType;
  statusColor: string;
  statusValue: string;
  statusDuration: React.ReactNode;
  statusLabel: string;
  isWorking: boolean;
  isVerified: boolean | null;
  isChecked: boolean | null;
  totalEarnedIoCoin?: number;
  totalSlashedIoCoin?: number;
};

export type ClusterForm = {
  clusterType: string;
  name: string;
  supplier: string;
  securityCompliance: string;
  service: string;
  connectivityTier: string;
  durationQuantity?: number;
  durationType: string;
  hardware: string;
  hardwareManufacturer: string;
  hardwareType: string;
  hardwareQuantity?: number;
  locations: string[];
  sustainable?: boolean;
  nvLink?: boolean;
  k8sOperators?: string;
  sshKeyName?: string;
  sshKey?: string;
  createClusterData?: CreateClusterData;
  hardwareInfo?: ClusterHardwareInfo;
  clusterId?: string;
  masterNode?: string;
  currency: "iocoin" | "usdc";
  priceUpdateTrigger?: number;
};

export type ModelsResponse = {
  category: string;
  creator_organization: string;
  description: string;
  display_name: string;
  hardware_label: string;
};

export type Model = {
  category: string;
  creator_organization: string;
  description: string;
  display_name: string;
  hardware_label: string;
};

export type LiveHardwarePricingResponse = {
  available: number;
  brand_name: string;
  busy_percent: number;
  hardware_name: string;
  icon: string;
  id: number;
  price: number;
  total: number;
};

export type LiveHardwarePricing = {
  id: string;
  hardwareIcon: typeof NvidiaIcon;
  hardwareManufacturerColor: string;
  hardwareName: string;
  hardwareQuantity: number;
  hardwareManufacturerIcon: typeof NvidiaIcon;
  hardwareManufacturer: string;
  busyPercent: number;
  price: number;
  priceChange: number;
  total: number;
};

export type NetworkInfoSupplierResponse = {
  active_cpu: number;
  active_gpu: number;
  busy_percent: number;
  icon: string;
  id: number;
  name: string;
  total_cpu: number;
  total_gpu: number;
  verified_cpu: number;
  verified_gpu: number;
  cluster_ready_gpu: number;
};

export type NetworkInfoSupplier = {
  activeCpu: number;
  activeGpu: number;
  busyPercent: number;
  id: string;
  name: string;
  totalCpu: number;
  totalGpu: number;
  icon: typeof NvidiaIcon;
  iconColor: string;
  verifiedCpu: number;
  verifiedGpu: number;
  clusterReadyGpu: number;
};

export type Filters = {
  [k: string]: string[] | undefined;
};

export type FilterGroup = {
  value: string;
  label: string;
  items: {
    value: string;
    label: string;
    icon: typeof NvidiaIcon;
    iconHeight?: number;
  }[];
  icon: typeof NvidiaIcon;
};

export type ClusterHardwareInfoResponse = {
  avg: 1.03138461538462;
  avg_iocoin: 1.03138461538462;
  qty: 13;
  currency_conversion_fee_percent: number;
  conversion_rate: number;
  ionet_fee_percent: number;
  currency_conversion_fee_precent: number;
  ionet_fee_precent: number;
  transaction: string;
  available_quantity?: {
    [key: string]: number[];
  };
  cost?: number;
  currency_conversion_fee?: number;
  ionet_fee?: number;
  total_cost?: number;
};

export type ClusterHardwareInfo = {
  averagePerCard: number;
  ioAveragePerCard: number;
  quantity: number;
  availableQuantity?: ClusterHardwareInfoMap[];
  currencyConversionFeePrecent: number;
  conversionRate: number;
  ionetFeePrecent: number;
  transaction: string;
  cost?: number;
  currencyConversionFee?: number;
  ionetFee?: number;
  totalCost?: number;
};

export type ClusterHardwareInfoMap = {
  label: string;
  value: string;
};

export type ClusterStats = {
  today_clusters: number;
  today_compute_hours_served: number;
  today_earnings: number;
  total_clusters_created: number;
  total_compute_hours_served: number;
  total_earnings: number;
  previous_day_earnings: number;
  running_clusters: number;
};

export type DeviceStats = {
  total_earnings: number;
  today_earnings: number;
  total_compute_hours_served: number;
  total_devices: number;
  today_devices: number;
};

export type BrandResponse = {
  icon: string;
  name: string;
};

export type Brand = {
  value: string;
  label: string;
  icon: typeof NvidiaIcon;
};

export type UserNotificationResponse = {
  created_at: string;
  id: string;
  text: string;
  type: string;
  seen: boolean;
  is_admin_notification: boolean;
};

export type UserNotification = {
  createdAt: string;
  id: string;
  text: string;
  icon: typeof NvidiaIcon;
  color: string;
  type: string;
  isRead?: boolean;
  isAdminNotification?: boolean;
  label?: string;
};

export type UserPayoutResponse = {
  solscan_url: string;
};

export type UserPayout = {
  solscanUrl: string;
};

export type Notification = {
  id: string;
  type?: string;
  message: string;
};

export type DeviceForm = {
  name: string;
  supplier: string;
  operatingSystem: string;
  deviceType: string;
  isConnected: string;
};

export type RunDeviceForm = {
  operatingSystem: string;
  deviceType: string;
};

export type VisibleError = {
  title?: string;
  message: string;
  redirectTo?: string;
  isPublic?: boolean;
};

export type UserTransactionResponse = {
  amount: number;
  created_at: string;
  fee: number;
  id: string;
  method: string;
  network: string;
  payment_settled: boolean;
  source_type: TransactionSourceType;
  source_id: string;
  status: string;
  txid_url: string;
  original_amount: number;
  ionet_fee_precent: number;
  currency_conversion_fee_precent: number;
  currency: "usdc" | "iocoin";
  device_name?: string;
  device_id?: string;
  compute_minutes_served?: number;
  job_id?: string;
};

export type UserTransaction = {
  amount: number;
  originalAmount: number;
  createdAt: string;
  fee: number;
  id: string;
  method: string;
  network: string;
  payment_settled: boolean;
  sourceType: TransactionSourceType;
  sourceId: string;
  status: string;
  txidUrl: string;
  ionetFeePrecent: number;
  currencyConversionFeePrecent: number;
  currency: "usdc" | "iocoin";
  workerName?: string;
  timeAmount?: number;
  jobId?: string;
  deviceId?: string;
};

export type PaginatedResponse<T> = {
  page: number;
  page_size: number;
  total_pages: number;
} & T;

export type ZustandDefinition<T> = (
  set: (partial: T | Partial<T> | ((state: T) => T | Partial<T>)) => void,
  get: () => T
) => T;

export type LocationCityResponse = {
  location: string;
  geo_coordinates: string;
  brand_name: string;
  hardware_name: string;
  hardware_quantity: number;
};

export type LocationCity = {
  name: string;
  lat: number;
  lon: number;
  country: string;
};

export type LocationCityGeoJSON = {
  type: "Feature";
  properties: {
    cluster: false;
    country: string;
    name: string;
  };
  geometry: {
    type: "Point";
    coordinates: [number, number];
  };
  info?: {
    hardwareName?: string;
    hardwareQuantity?: number;
  };
};

export type LocationDeviceResponse = {
  city: string;
  connected: boolean;
  device_id: string;
  device_type: "gpu" | "cpu";
  geo_coordinates: string;
  is_working: boolean;
  operating_system: string;
  status: string;
  user_id: string;
  brand_name: string;
  status_duration: string;
  hardware_name: string;
  hardware_quantity: number;
};

export type LocationDevice = Device & {
  country: string;
  city: string;
  isConnected: boolean;
  deviceType: "gpu" | "cpu";
  lat: number;
  lon: number;
  operatingSystem: string;
  userId: string;
};

export type ExtendClusterRentalForm = {
  durationType: "hourly" | "daily" | "weekly";
  durationQuantity: number;
};

export type GenerateBalanceLinkForm = {
  amount: number;
  amountType: string;
  paymentProvider: "iocoin" | "usdc" | "stripe";
};

export type ClaimRewardsForm = {
  amount: number;
  amountType: string;
};

export enum LoginType {
  SUPABASE = "supabase",
  AUTH0 = "auth0"
}

export type ZoomControlType = {
  latitude: number;
  longitude: number;
  zoom: number;
};

export enum ClusterType {
  RAY = "ray",
  KUBERNETES = "kubernetes",
  MEGA = "mega",
  LUDWIG = "ludwig",
  RAY_APP = "ray-app"
}

export type DeviceEarningsResponse = {
  device_id: string;
  total_compute_hours: number;
  total_earnings: number;
  total_jobs: number;
  total_slashed: number;
  datewise_earnings_summary: DeviceDailyEarningsResponse[];
};

export type DeviceEarningsType = {
  deviceId: string;
  totalComputeHours: number;
  totalEarnings: number;
  totalJobs: number;
  totalSlashed: number;
  datewiseEarningsSummary: DeviceDailyEarningsType[];
};

export type DeviceMonthlyEarningsType = {
  month: number;
  earning: number;
  year: number;
};

export type DeviceDailyEarningsResponse = {
  earning: number;
  date_with_year: string;
};

export type DeviceDailyEarningsType = {
  earning: number;
  dateWithYear: string;
};

export type ClustersEarningsSummaryResponse = {
  date_month: string;
  total_earnings: number;
  daily_earnings: number;
};

export type ClustersEarningsSummary = {
  dateMonth: string;
  totalEarnings: number;
  dailyEarnings: number;
};

export type GraphDataType = { name: string; value: number };

export type clusterVariant = "cluster" | "explorer" | "mega" | "kubernetes";

export type UserDevicesEarningsResponse = {
  user_id: string;
  total_compute_hours: number;
  total_earnings: number;
  total_jobs: number;
  total_slashed: number;
  year: number;
  monthly_earnings_summary?: DeviceMonthlyEarningsType[];
  daily_earnings_summary: DeviceDailyEarningsResponse[];
  total_earned_io_coin?: number;
  total_slashed_io_coin?: number;
};

export type UserDevicesEarningsType = {
  userId: string;
  totalComputeHours: number;
  totalEarnings: number;
  totalJobs: number;
  totalSlashed: number;
  year: number;
  dailyEarningsSummary: DeviceDailyEarningsType[];
  totalEarnedIoCoin?: number;
  totalSlashedIoCoin?: number;
};

export type DevicePotlType = {
  blockId: string;
  deviceId: string;
  status: string;
  timeAndDate: string;
  uptimePercentage: number;
  uptimeInMinutes: number;
  txidUrl: string;
};

export type DevicePotlResponseType = {
  block_id: string;
  device_id: string;
  status: string;
  time_and_date: string;
  uptime_percentage: number;
  uptime_in_minutes: number;
  txid_url: string;
};

export type DevicePowResponseType = {
  proof_id: string;
  block_id: string;
  status: string;
  time_and_date: string;
  complexity: number;
  hash_rate: number;
  compute_time: number;
  device_id: string;
  txid_url: string;
};

export type DevicePowType = {
  proofId: string;
  blockId: string;
  deviceId: string;
  status: string;
  timeAndDate: string;
  complexity: number;
  hashRate: number;
  computeTime: number;
  txidUrl: string;
};

export type DeviceBlockEarningsType = {
  deviceId: string;
  totalBlockRewards: number;
  totalBlocksEarned: number;
  totalBlocksFailed: number;
  totalBlocksMissed: number;
  datewiseEarningsSummary: BlockDailyEarnings[];
};

export type BlockDailyEarnings = {
  rewards: number;
  dateWithYear: string;
};

export type DeviceBlockEarningsResponseType = {
  device_id: string;
  total_block_rewards: number;
  total_blocks_earned: number;
  total_blocks_failed: number;
  total_blocks_missed: number;
  datewise_earnings_summary: BlockDailyEarningsResponseType[];
};

export type BlockDailyEarningsResponseType = {
  rewards: number;
  date_with_year: string;
};

export type DevicePowSummaryResponseType = {
  device_id: string;
  last_challenge_status: string;
  last_audit_successful: string;
  pow_jobs_passed: number;
  pow_jobs_failed: number;
};

export type DevicePowSummaryType = {
  deviceId: string;
  lastChallengeStatus: string;
  powJobsPassed: number;
  powJobsFailed: number;
};

export type DeviceStatusCountsType = {
  block_workers: StatusCountsType;
  pow: StatusCountsType;
  potl: StatusCountsType;
};

export type StatusCountsType = {
  all: number | null;
  success: number | null;
  failed: number | null;
};

export type BlockRewardsStatusCountsType = {
  all: number | null;
  completed: number | null;
  failed: number | null;
};

export type UsereBlockEarningsResponse = {
  total_block_rewards: number;
  total_blocks_earned: number;
  total_blocks_failed: number;
  total_blocks_missed: number;
  datewise_earnings_summary: BlockDailyEarningsResponseType[];
};

export type UsereBlockEarningsType = {
  totalBlockRewards: number;
  totalBlocksEarned: number;
  totalBlocksFailed: number;
  totalBlocksMissed: number;
  datewiseEarningsSummary: BlockDailyEarnings[];
};

export type UserLatestBlockRewardsResponse = {
  sum_rewarded_for_user: number;
};

export type UserLatestBlockRewards = {
  sumRewardedForUser: number;
};

export type TransactionSourceType =
  | "cluster"
  | "k8s_cluster"
  | "mega"
  | "payments"
  | "payouts"
  | "Settlement"
  | "promotion_credit"
  | "job";

export type DeviceBlockRewardsChecklistRulesType = {
  check: boolean;
  reason: string;
};

export type DeviceBlockRewardsChecklistType = {
  device_id: string;
  rules: DeviceBlockRewardsChecklistRulesType[];
  block_rewards_ready: boolean;
};

export type FormattedDateTimeFunctionType = (dateTime: unknown) => string | undefined;

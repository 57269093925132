import { IconProps } from ".";

export const AuthIcon: React.FC<IconProps> = (props) => {
  return (
    <svg viewBox="0 0 22 14" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M2.18522 0.657306V13.343C2.18522 13.5525 2.06536 13.6668 1.84561 13.6668H1.00659C0.786852 13.6668 0.666992 13.5525 0.666992 13.343V0.657306C0.666992 0.447783 0.786852 0.333496 1.00659 0.333496H1.84561C2.06536 0.333496 2.18522 0.447783 2.18522 0.657306Z"
        fill="currentColor"
      />
      <path
        d="M4.90032 0.657306C4.90032 0.447783 5.02018 0.333496 5.23993 0.333496H17.3458C20.3822 0.333496 22.0003 1.9335 22.0003 4.77159V9.22873C22.0003 12.0668 20.3822 13.6668 17.3458 13.6668H5.23993C5.02018 13.6668 4.90032 13.5525 4.90032 13.343V0.657306ZM20.5021 9.15254V4.84778C20.5021 2.7335 19.3035 1.59064 17.0461 1.59064H6.41855V12.4097H17.0461C19.3035 12.4097 20.5021 11.2668 20.5021 9.15254Z"
        fill="currentColor"
      />
    </svg>
  );
};

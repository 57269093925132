import { initialiseIntercom, shutdownIntercom } from "@/utils/intercom";
import { initialiseFreshdesk, shutdownFreshdesk } from "./freshdesk";

export const initialiseSupport = (options: {
  userId: string;
  name?: string;
  email?: string;
  createdAt?: number;
}) => {
  initialiseIntercom(options);
  initialiseFreshdesk(options);
};

export const shutdownSupport = () => {
  shutdownIntercom();
  shutdownFreshdesk();
};

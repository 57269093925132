import { IconProps } from ".";

export const LudwigClusterIcon: React.FC<IconProps> = (props) => {
  return (
    <svg viewBox="0 0 10 11" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 5.5V10.5H8.88889H10V5.5V0.5H8.88889H0V5.5ZM1.10637 5.5V9.39328H8.88889H8.89363V5.5V1.60672H8.88889H1.10637V5.5ZM2.21274 5.5V8.28656H4.99842H7.7841V7.7332V7.17984H5.5516H3.31911V4.94664V2.71344H2.76592H2.21274V5.5ZM4.46499 4.39328V6.07312H6.12455H7.7841V4.39328V2.71344H7.23092H6.67773V3.83992V4.9664H6.12455H5.57136V3.83992V2.71344H5.01818H4.46499V4.39328Z"
        fill="currentColor"
      />
    </svg>
  );
};

import env from "@/env";
import { baseUrl, urlWithCurrentQuery } from ".";
import { redirectOnce } from "./location";

export const getToolUrl = (value: string, path: string) => {
  if (!env.SECTION_SUBDOMAINS_ENABLED) {
    return `${baseUrl()}${path}`;
  }

  return `${window.location.protocol}//${value}.${env.APP_DOMAIN}${
    window.location.port ? `:${window.location.port}` : ``
  }${path}`;
};

export const getSubdomain = () => {
  const subdomain = window.location.hostname.split(".")[0];
  return subdomain;
};

export const isOnLoginSubdomain = () => {
  const subdomain = getSubdomain();
  return ["id", "explorer", env.LOGIN_SUBDOMAIN].includes(subdomain);
};

export const redirectToLogin = (redirect: string) => {
  const redirectUrl = urlWithCurrentQuery(getToolUrl(env.LOGIN_SUBDOMAIN, "/login"), {
    redirect
  });
  console.info("redirect as unauthorised + wrong subdomain", redirectUrl);
  redirectOnce(redirectUrl);
};

import { IconProps } from ".";

export const BurgerIcon: React.FC<IconProps> = (props) => {
  return (
    <svg viewBox="0 0 14 7" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M-4.22691e-08 3.01426L0 3.98584L14 3.98584L14 3.01425L-4.22691e-08 3.01426Z"
        fill="currentColor"
      />
      <path
        d="M-4.22691e-08 9.51886e-05L0 0.97168L14 0.971679L14 9.45738e-05L-4.22691e-08 9.51886e-05Z"
        fill="currentColor"
      />
      <path
        d="M-4.22691e-08 6.02842L0 7L14 7L14 6.02841L-4.22691e-08 6.02842Z"
        fill="currentColor"
      />
    </svg>
  );
};

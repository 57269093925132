import { useMemo } from "react";
import {
  Pagination,
  PaginationContent,
  PaginationEllipsis,
  PaginationItem,
  PaginationLink,
  PaginationNext,
  PaginationPrevious
} from "@/components/ui/pagination";

type DataPaginationProps = {
  page: number;
  pageCount: number;
  maxPageCount?: number;
  maxItems?: number;
  onChange: (page: number) => void;
};

export const DataPagination: React.FC<DataPaginationProps> = ({
  page,
  pageCount,
  maxPageCount,
  maxItems = 10,
  onChange
}) => {
  const items = useMemo(() => {
    const min = Math.max(1, page - maxItems / 2);
    const max = Math.min(pageCount, min + (maxItems - 1));
    const result: number[] = [];

    for (let i = min; i <= max; i++) {
      result.push(i);
    }

    return result;
  }, [page, pageCount, maxItems]);

  if (pageCount === 0) {
    return (
      <div className="flex flex-col gap-4">
        <Pagination>
          <PaginationContent>
            {page > 1 && (
              <PaginationItem>
                <PaginationLink
                  size="default"
                  onClick={() => {
                    onChange(page - 1);
                  }}
                  className="border-0"
                >
                  Previous Page
                </PaginationLink>
              </PaginationItem>
            )}
            {(typeof maxPageCount !== "number" || page < maxPageCount) && (
              <PaginationItem>
                <PaginationLink
                  size="default"
                  onClick={() => {
                    onChange(page + 1);
                  }}
                  className="border-0"
                >
                  Next Page
                </PaginationLink>
              </PaginationItem>
            )}
          </PaginationContent>
        </Pagination>
      </div>
    );
  }

  if (items.length <= 1) {
    return null;
  }

  return (
    <div className="flex flex-col gap-4">
      <Pagination>
        <PaginationContent>
          {page > 1 && (
            <PaginationItem>
              <PaginationPrevious
                onClick={() => {
                  onChange(page - 1);
                }}
                className="border-0"
              />
            </PaginationItem>
          )}
          {items[0] > 2 && (
            <PaginationItem>
              <PaginationLink
                onClick={() => {
                  onChange(1); // First page
                }}
                className="w-fit border-gray-dark-900 px-3 text-gray-dark-1500"
              >
                1
              </PaginationLink>
            </PaginationItem>
          )}
          {items[0] > 1 && (
            <PaginationItem>
              <PaginationEllipsis className="border-gray-dark-900" />
            </PaginationItem>
          )}
          {items.map((index) => {
            return (
              <PaginationItem key={index}>
                <PaginationLink
                  key={index}
                  isActive={page === index}
                  onClick={() => {
                    onChange(index);
                  }}
                  className={
                    page === index
                      ? "w-fit border-gray-dark-1200 px-3 text-gray-light-1000"
                      : "w-fit border-gray-dark-900 px-3 text-gray-dark-1500"
                  }
                >
                  {index}
                </PaginationLink>
              </PaginationItem>
            );
          })}
          {items[items.length - 1] < pageCount && (
            <>
              <PaginationItem>
                <PaginationEllipsis className="border-gray-dark-900" />
              </PaginationItem>
              <PaginationItem>
                <PaginationLink
                  onClick={() => {
                    onChange(pageCount);
                  }}
                  className="w-fit border-gray-dark-900 px-3 text-gray-dark-1500"
                >
                  {pageCount}
                </PaginationLink>
              </PaginationItem>
            </>
          )}
          {page < pageCount && (
            <PaginationItem>
              <PaginationNext
                onClick={() => {
                  onChange(page + 1);
                }}
                className="border-0"
              />
            </PaginationItem>
          )}
        </PaginationContent>
      </Pagination>
      <span className="flex flex-row justify-center text-sm text-gray-dark-1000">{`Page ${page} of ${pageCount}`}</span>
    </div>
  );
};

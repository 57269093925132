import { IconProps } from ".";

export const TickCircleIcon: React.FC<IconProps> = (props) => {
  return (
    <svg viewBox="0 0 19 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <circle cx="9.5" cy="9.67969" r="9.5" fill="currentColor" />
      <path
        d="M5.18176 10.8082L7.37196 12.931C7.48831 13.0438 7.67319 13.0438 7.78954 12.931L13.8181 7.08789"
        stroke="white"
        className={"dark:stroke-black"}
        strokeWidth="2"
        strokeLinecap="round"
      />
    </svg>
  );
};

import { IconProps } from "@/components/Icons/index";

export const TwitterIcon: React.FC<IconProps> = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="none" {...props}>
      <path
        d="M11.6842 8.85148L17.3939 2.5H16.0409L11.0831 8.0149L7.12337 2.5H2.55627L8.54419 10.8395L2.55627 17.5H3.90937L9.14489 11.6761L13.3267 17.5H17.8938L11.6838 8.85148H11.6842ZM9.83091 10.913L9.22421 10.0825L4.39692 3.47476H6.4752L10.3709 8.80746L10.9776 9.63788L16.0415 16.5696H13.9632L9.83091 10.9133V10.913Z"
        fill="currentColor"
        style={{
          fill: "currentColor",
          fillOpacity: "1"
        }}
      />
    </svg>
  );
};

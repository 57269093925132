import { IconProps } from ".";

export const TickThickIcon: React.FC<IconProps> = (props) => {
  return (
    <svg viewBox="0 0 10 8" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M1 4.375L3.36147 6.92095C3.40104 6.96362 3.46853 6.96362 3.5081 6.92095L9 1"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
      />
    </svg>
  );
};

import { IconProps } from ".";

export const WindowsOSIcon: React.FC<IconProps> = (props) => {
  return (
    <svg viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M10.7579 10.7575H18.3337V18.3333H10.7579V10.7575Z" fill="#9B9B9B" />
      <path d="M1.66699 10.7575H9.24275V18.3333H1.66699V10.7575Z" fill="#0CA5F0" />
      <path d="M10.7579 1.66663H18.3337V9.24238H10.7579V1.66663Z" fill="#81BC0D" />
      <path d="M1.66699 1.66663H9.24275V9.24238H1.66699V1.66663Z" fill="#F35326" />
    </svg>
  );
};

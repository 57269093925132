import { IconProps } from ".";

export const PredibaseIcon: React.FC<IconProps> = (props) => {
  return (
    <svg viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M9.33333 0.5H4.66667L2.33333 2.83333L0 5.16667V9.83333H4.66667V14.5H9.33333L11.6667 12.1667L14 9.83333V5.16667H9.33333V0.5Z"
        fill="currentColor"
      />
    </svg>
  );
};

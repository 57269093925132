import BaseFieldLayout from "./BaseFieldLayout.tsx";
import { Field, FieldConfig } from "formik";

type FieldProps<T> = Omit<T, "onChange"> & {
  label?: string;
  hideError?: boolean;
  validate?: FieldConfig["validate"];
};

export default function BaseFieldHOC<T>(Component: React.ComponentType<T>) {
  return (props: FieldProps<T>) => {
    return <Field component={BaseFieldLayout} inputComponent={Component} {...props} />;
  };
}

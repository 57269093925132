import { useEffect, useState, FC } from "react";
import parse from "html-react-parser";
import env from "@/env.ts";
import { Loader } from "../Loader/Loader";

type TermsAndConditionsTextProps = {
  showHeader?: boolean;
};

export const TermsAndConditionsText: FC<TermsAndConditionsTextProps> = ({ showHeader }) => {
  const [copy, setCopy] = useState<React.ReactNode>();

  useEffect(() => {
    (async () => {
      const newCopy = await fetchLegalCopy();
      setCopy(parse(newCopy));
    })();
  }, []);

  if (!copy) {
    return (
      <div className="flex h-[700px] pr-[6rem]">
        <div
          className="flex min-h-[100px] flex-1 flex-col items-end justify-center"
          data-testid="ContentLoader"
        >
          <Loader className={"flex-1"} color={"#ffffff"} />
        </div>
      </div>
    );
  }

  return (
    <div>
      <>
        {showHeader && (
          <>
            <div className="mb-3 text-sm text-gray-light-1500">
              Last Updated: {env.LATEST_APPROVED_TERMS_VERSION}
            </div>
            <div className="mb-6 text-2xl font-medium text-white">TERMS OF USE</div>
          </>
        )}
        <div id="terms-root">{copy}</div>
      </>
    </div>
  );
};

const LEGAL_TEXT_COLOR = "#989898";
const LEGAL_FONT_SIZE = "14px";

const fetchLegalCopy = async () => {
  const response = await fetch("/assets/copy/terms.html");
  let copy = await response.text();

  copy = copy.split("color: black").join(`color: ${LEGAL_TEXT_COLOR}`);
  copy = copy.split("color: #0d0d0d").join(`color: ${LEGAL_TEXT_COLOR}`);
  copy = copy.split('font-family: "Times New Roman", serif;').join("");
  copy = copy.split("font-family: &quot;Times New Roman&quot;, serif;").join("");
  copy = copy.split("11pt").join(LEGAL_FONT_SIZE);
  copy = copy.split("12pt").join(LEGAL_FONT_SIZE);

  const tempContainer = document.createElement("div");
  tempContainer.innerHTML = copy;

  const toc = tempContainer.querySelector(".table-of-contents") as HTMLElement;
  toc.remove();

  return tempContainer.innerHTML;
};

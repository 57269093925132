import { CookiePreferenceType } from "@/types/user";
import localCookies from "@/utils/cookie";
import { CogIcon, SignalIcon } from "@heroicons/react/24/outline";
import { create } from "zustand";
import { createJSONStorage, persist } from "zustand/middleware";

type UseCookieStoreProps = {
  isAcceptAllModalOpen: boolean;
  setIsAcceptAllModalOpen: (isOpen: boolean) => void;
  isSelectionModalOpen: boolean;
  setIsSelectionModalOpen: (isOpen: boolean) => void;
  getCookieOptions: () => {
    value: CookiePreferenceType;
    title: string;
    description: string;
    icon: React.FC;
    disabled?: boolean;
  }[];
  cookiePreferences: CookiePreferenceType[];
  setCookiePreferences: (values: CookiePreferenceType[]) => void;
};

const COOKIE_OPTIONS = [
  {
    value: CookiePreferenceType.FUNCTIONAL,
    title: "Functional",
    description:
      "These cookies are required for the website to function properly. They cannot be disabled.",
    icon: CogIcon,
    disabled: true
  },
  {
    value: CookiePreferenceType.ANALYTICS,
    title: "Analytics",
    description:
      "These cookies collect information that can help us understand how our websites are being used. This information can also be used to measure effectiveness in our marketing campaigns or to curate a personalized site experience for you.",
    icon: SignalIcon
  }
  // {
  //   value: CookiePreferenceType.TRACKING,
  //   title: "Tracking",
  //   description:
  //     'We use various tracking cookies to help us measure the performance of our marketing campaigns and show you content and promotions we think you"d be interested in.',
  //   icon: IdentificationIcon
  // }
];

export const useCookieStore = create(
  persist<UseCookieStoreProps>(
    (set) => ({
      isAcceptAllModalOpen: true,
      setIsAcceptAllModalOpen: (isOpen) => {
        set({ isAcceptAllModalOpen: isOpen });
      },
      isSelectionModalOpen: false,
      setIsSelectionModalOpen: (isOpen) => {
        set({ isSelectionModalOpen: isOpen });
      },
      getCookieOptions: () => {
        return COOKIE_OPTIONS;
      },
      cookiePreferences: [CookiePreferenceType.FUNCTIONAL],
      setCookiePreferences: (values) => {
        console.log(values);

        set({ cookiePreferences: values });
      }
    }),
    {
      name: "ionet_cookie",
      storage: createJSONStorage(() => localCookies),
      partialize: (state) => {
        return {
          ...state,
          isSelectionModalOpen: false
        };
      },
      onRehydrateStorage: () => {
        return (state, error) => {
          if (error) {
            console.log("an error happened during hydration", error);
          } else if (state) {
            //
          }
        };
      }
    }
  )
);

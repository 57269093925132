import auth0, { Auth0DecodedHash, Auth0UserProfile } from "auth0-js";
import env from "@/env";
import { Nullable } from "@/types/utility";
import { Auth0Client, createAuth0Client } from "@auth0/auth0-spa-js";
import { getToolUrl } from "./tool";
import { AuthToken } from "@/types/auth";

// https://github.com/auth0/auth0.js
// https://auth0.com/docs/libraries/auth0js

export const getAudience = () => {
  return env.AUTH0_AUDIENCE;
};

export const getClientId = () => {
  return env.AUTH0_CLIENT_ID;
};

let webClient: auth0.WebAuth | null = null;

export const getAuth0Client = () => {
  if (!webClient) {
    webClient = new auth0.WebAuth({
      domain: env.AUTH0_DOMAIN,
      clientID: env.AUTH0_CLIENT_ID,
      responseType: env.AUTH0_RESPONSE_TYPE,
      scope: env.AUTH0_SCOPE
    });
  }

  return webClient;
};

let spaClient: Auth0Client | null = null;

export const getAuth0SpaClient = async () => {
  if (!spaClient) {
    spaClient = await createAuth0Client({
      domain: env.AUTH0_DOMAIN,
      clientId: env.AUTH0_CLIENT_ID,
      useRefreshTokens: true,
      authorizationParams: {
        redirect_uri: getRedirectUri(),
        scope: env.AUTH0_SCOPE
      },
      // https://auth0.com/docs/libraries/auth0-single-page-app-sdk#change-storage-options
      cacheLocation: "memory"
    });
  }

  return spaClient;
};

let managementClient: auth0.Management | null = null;
let managementClientToken: string | null = null;

export const getAuth0ManagementClient = (token: string) => {
  if (!managementClient || managementClientToken !== token) {
    managementClientToken = token;
    managementClient = new auth0.Management({
      domain: env.AUTH0_DOMAIN,
      token
    });
  }

  return managementClient;
};

export const parseHash = async (): Promise<undefined | Auth0DecodedHash> => {
  return new Promise((resolve) => {
    getAuth0Client().parseHash({ hash: window.location.hash }, (err, authResult) => {
      if (err || !authResult) {
        return resolve(undefined);
      }

      resolve(authResult);
    });
  });
};

export const userProfile = async <T>(token: string): Promise<Auth0CustomUserProfile<T>> => {
  return new Promise((resolve, reject) => {
    getAuth0Client().client.userInfo(token, (err, user) => {
      if (err) {
        return reject(err);
      }

      resolve(user as Auth0CustomUserProfile<T>);
    });
  });
};

export const patchUserAttributes = async <T>(
  token: string | undefined,
  userId: string | undefined,
  profile: Partial<Auth0CustomUserProfile<T>> | undefined
): Promise<void> => {
  if (!token) {
    throw new Error("token not found");
  }
  if (!userId) {
    throw new Error("userId not found");
  }
  if (!profile) {
    throw new Error("profile not found");
  }

  return new Promise((resolve, reject) => {
    getAuth0ManagementClient(token).patchUserAttributes(
      userId,
      profile as Auth0UserProfile,
      (e) => {
        if (e) {
          reject(e);
          return;
        }

        resolve(undefined);
      }
    );
  });
};

export const patchUserMetadata = async <T>(
  token: string | undefined,
  userId: string | undefined,
  meta: Nullable<Partial<T>>
): Promise<void> => {
  if (!token) {
    throw new Error("token not found");
  }
  if (!userId) {
    throw new Error("userId not found");
  }

  return new Promise((resolve, reject) => {
    getAuth0ManagementClient(token).patchUserMetadata(userId, meta, (e) => {
      if (e) {
        reject(e);
        return;
      }

      resolve(undefined);
    });
  });
};

export const getUser = async <T>(
  token: string,
  userId: string
): Promise<Auth0CustomUserProfile<T>> => {
  return new Promise((resolve, reject) => {
    getAuth0ManagementClient(token).getUser(userId, (e, profile) => {
      if (e) {
        reject(e);
        return;
      }

      resolve(sanitizeUserProfile(profile as Auth0CustomUserProfile<T>));
    });
  });
};

const sanitizeUserProfile = <T>(profile: Auth0CustomUserProfile<T>) => {
  const { name, email, family_name, given_name, user_metadata, user_id, picture } = profile;

  return {
    name,
    email,
    family_name,
    given_name,
    user_metadata,
    user_id,
    picture
  } as Auth0CustomUserProfile<T>;
};

export type Auth0CustomUserProfile<T> = Omit<Auth0UserProfile, "user_metadata"> & {
  user_metadata: T;
};

export const getUserAttribute = <T>(
  profile: Auth0CustomUserProfile<T> | undefined,
  attribute: string
) => {
  if (!profile) {
    return;
  }
  if (profile.user_metadata[attribute as keyof typeof profile.user_metadata]) {
    return profile.user_metadata[attribute as keyof typeof profile.user_metadata];
  }
  return profile[attribute as keyof typeof profile];
};

export const getUserName = (profile?: Auth0CustomUserProfile<unknown>) => {
  return getUserAttribute(profile, "name");
};

export const getRefreshToken = async () => {
  const client = await getAuth0SpaClient();
  const token = await client.getTokenSilently({
    detailedResponse: true,
    authorizationParams: {
      redirect_uri: getRedirectUri(),
      scope: env.AUTH0_SCOPE,
      audience: getAudience()
    }
  });

  let result: string | null = null;

  if (typeof token === "string") {
    result = token;
  } else {
    result = token.access_token;
  }

  try {
    const decoded = decodeToken(result);
    if (decoded) {
      console.log("Token expires", new Date(decoded.exp * 1000));
    }
  } catch (e) {
    console.log(e);
  }

  return result;
};

export const decodeToken = <T extends AuthToken>(result: string) => {
  try {
    const decoded = JSON.parse(atob(result.split(".")[1]));

    return decoded as T;
  } catch (e) {
    console.log(e);
  }
};

export const getRedirectUri = () => {
  return getToolUrl(env.LOGIN_SUBDOMAIN, "/login");
};

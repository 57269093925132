import { IconProps } from ".";

export const CpuIcon: React.FC<IconProps> = (props) => {
  return (
    <svg viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.40625 14.1519V9.33936C8.40625 8.95966 8.71405 8.65186 9.09375 8.65186H13.9062C14.2859 8.65186 14.5938 8.95966 14.5938 9.33936V14.1519C14.5938 14.5316 14.2859 14.8394 13.9062 14.8394H9.09375C8.71405 14.8394 8.40625 14.5316 8.40625 14.1519ZM13.2188 13.4644H9.78125V10.0269H13.2188V13.4644Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.9375 5.55811C3.9375 5.55811 3.9375 4.98856 4.34023 4.58583C4.34023 4.58583 4.74296 4.18311 5.3125 4.18311H17.6875C17.6875 4.18311 18.257 4.18311 18.6598 4.58583C18.6598 4.58583 19.0625 4.98856 19.0625 5.55811V17.9331C19.0625 17.9331 19.0625 18.5027 18.6598 18.9054C18.6598 18.9054 18.257 19.3081 17.6875 19.3081H5.3125C5.3125 19.3081 4.74295 19.3081 4.34023 18.9054C4.34023 18.9054 3.9375 18.5026 3.9375 17.9331V5.55811ZM5.3125 5.55811V17.9331H17.6875V5.55811H5.3125Z"
        fill="currentColor"
      />
      <path
        d="M18.375 10.3706H20.4375C20.8172 10.3706 21.125 10.0628 21.125 9.68311C21.125 9.30341 20.8172 8.99561 20.4375 8.99561H18.375C17.9953 8.99561 17.6875 9.30341 17.6875 9.68311C17.6875 10.0628 17.9953 10.3706 18.375 10.3706Z"
        fill="currentColor"
      />
      <path
        d="M18.375 14.4956H20.4375C20.8172 14.4956 21.125 14.1878 21.125 13.8081C21.125 13.4284 20.8172 13.1206 20.4375 13.1206H18.375C17.9953 13.1206 17.6875 13.4284 17.6875 13.8081C17.6875 14.1878 17.9953 14.4956 18.375 14.4956Z"
        fill="currentColor"
      />
      <path
        d="M2.5625 10.3706H4.625C5.0047 10.3706 5.3125 10.0628 5.3125 9.68311C5.3125 9.30341 5.0047 8.99561 4.625 8.99561H2.5625C2.1828 8.99561 1.875 9.30341 1.875 9.68311C1.875 10.0628 2.1828 10.3706 2.5625 10.3706Z"
        fill="currentColor"
      />
      <path
        d="M2.5625 14.4956H4.625C5.0047 14.4956 5.3125 14.1878 5.3125 13.8081C5.3125 13.4284 5.0047 13.1206 4.625 13.1206H2.5625C2.1828 13.1206 1.875 13.4284 1.875 13.8081C1.875 14.1878 2.1828 14.4956 2.5625 14.4956Z"
        fill="currentColor"
      />
      <path
        d="M12.875 18.6206V20.6831C12.875 21.0628 13.1828 21.3706 13.5625 21.3706C13.9422 21.3706 14.25 21.0628 14.25 20.6831V18.6206C14.25 18.2409 13.9422 17.9331 13.5625 17.9331C13.1828 17.9331 12.875 18.2409 12.875 18.6206Z"
        fill="currentColor"
      />
      <path
        d="M8.75 18.6206V20.6831C8.75 21.0628 9.0578 21.3706 9.4375 21.3706C9.8172 21.3706 10.125 21.0628 10.125 20.6831V18.6206C10.125 18.2409 9.8172 17.9331 9.4375 17.9331C9.0578 17.9331 8.75 18.2409 8.75 18.6206Z"
        fill="currentColor"
      />
      <path
        d="M12.875 2.80811V4.87061C12.875 5.2503 13.1828 5.55811 13.5625 5.55811C13.9422 5.55811 14.25 5.2503 14.25 4.87061V2.80811C14.25 2.42841 13.9422 2.12061 13.5625 2.12061C13.1828 2.12061 12.875 2.42841 12.875 2.80811Z"
        fill="currentColor"
      />
      <path
        d="M8.75 2.80811V4.87061C8.75 5.2503 9.0578 5.55811 9.4375 5.55811C9.8172 5.55811 10.125 5.2503 10.125 4.87061V2.80811C10.125 2.42841 9.8172 2.12061 9.4375 2.12061C9.0578 2.12061 8.75 2.42841 8.75 2.80811Z"
        fill="currentColor"
      />
    </svg>
  );
};

import { toBits } from "./number";
import { pluralize } from "./string";

export const formatMegaBitsPerSecond = (value: number) => {
  return `${toBits(value * 1024 * 1024, 0)}ps`;
};

export const busyPercentage = (value: number) => {
  return Math.min(100, Math.max(0, value));
};

export const formatMegaBits = (value: number) => {
  return `${toBits(value * 1024 * 1024, 1)}`;
};

export const formatSeconds = (seconds: number) => {
  if (seconds < 60) {
    return pluralize("second", seconds);
  }

  const minutes = Math.floor(seconds / 60);
  const remainingSeconds = seconds % 60;

  if (seconds < 3600) {
    let result = pluralize("minute", minutes);
    if (remainingSeconds > 0) {
      result += " ";
      result += pluralize("second", remainingSeconds);
    }
    return result;
  }

  const hours = Math.floor(seconds / 3600);
  const remainingMinutes = Math.floor((seconds % 3600) / 60);
  const remainingSecondsAfterHours = seconds % 60;

  let result = pluralize("hour", hours);
  if (remainingMinutes > 0) {
    result += " ";
    result += pluralize("minute", remainingMinutes);
  }
  if (remainingSecondsAfterHours > 0) {
    result += " ";
    result += pluralize("second", remainingSecondsAfterHours);
  }

  return result;
};

import { IconProps } from ".";

export const PlyTorchIcon: React.FC<IconProps> = (props) => {
  return (
    <svg viewBox="0 0 16 18" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M7.99977 0V2.6353L7.99887 2.6335L7.52672 3.10512L4.0214 6.58825C1.8265 8.77353 1.8265 12.3134 4.02321 14.4977C6.21993 16.6821 9.78052 16.6821 11.9772 14.4977C14.174 12.3134 14.174 8.77353 11.9772 6.59005L13.304 5.2715C16.232 8.18311 16.232 12.9038 13.304 15.8163C10.3741 18.7279 5.62544 18.7279 2.69739 15.8163C-0.232464 12.9038 -0.232464 8.18311 2.69739 5.2715L7.99977 0ZM9.95914 3.26973C10.3389 2.89082 10.956 2.89082 11.3375 3.26973C11.7181 3.64775 11.7181 4.26157 11.3375 4.64048C10.956 5.0185 10.3389 5.0185 9.95914 4.64048C9.57852 4.26157 9.57852 3.64775 9.95914 3.26973Z"
        fill="currentColor"
      />
    </svg>
  );
};

import { IconProps } from ".";

export const AethirIcon: React.FC<IconProps> = (props) => {
  return (
    <svg viewBox="0 0 29 23" fill="none" preserveAspectRatio="xMidYMid meet" {...props}>
      <path
        d="M17.7046 0.199127H11.8067C11.1273 0.199127 10.5083 0.586468 10.2135 1.19535L7.19823 7.42508C6.82095 8.20416 7.39129 9.10796 8.26081 9.10796H12.3299C12.8958 9.10796 13.4116 8.78517 13.6577 8.27752L14.7557 6.00923L20.381 17.6294L12.7808 12.1539C12.4787 11.9367 12.1162 11.8193 11.7433 11.8193H6.17986C5.50046 11.8193 4.88148 12.2067 4.58673 12.8156L0.90381 20.4289C0.526528 21.2079 1.09687 22.1117 1.96639 22.1117H6.03543C6.60136 22.1117 7.11717 21.789 7.36329 21.2813L10.3535 15.1044L18.5918 21.3444C19.2491 21.8418 20.0509 22.1117 20.8762 22.1117H27.5464C28.4159 22.1117 28.9863 21.2079 28.609 20.4289L19.2978 1.19535C19.003 0.586468 18.384 0.199127 17.7046 0.199127Z"
        fill="#D7FE51"
      ></path>
    </svg>
  );
};

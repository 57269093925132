import { useState } from "react";
import { useLocation } from "wouter";
import { Popover, PopoverContent, PopoverTrigger } from "@/components/ui/popover";
import { Button } from "@/components/ui/button";
import { SettingsIcon } from "@/components/Icons/SettingsIcon";
import { LogOutIcon } from "@/components/Icons/LogOutIcon";
import { ShareIcon } from "@/components/Icons/ShareIcon";
import { AuthMachineContext } from "@/store/machine/authMachine/authMachine";
import { AuthEventType } from "@/types/auth";
import { getUserAttribute, getUserName } from "@/utils/auth0";
import { getUserPicture } from "@/utils/user";

export const UserButton = () => {
  const authActorRef = AuthMachineContext.useActorRef();
  const profile = AuthMachineContext.useSelector((state) => state.context.profile);
  const [, setLocation] = useLocation();
  const [isOpen, setIsOpen] = useState(false);

  const handleLogout = async () => {
    authActorRef.send({
      type: AuthEventType.LOGOUT
    });
  };

  return (
    <Popover
      open={isOpen}
      onOpenChange={(open) => {
        setIsOpen(open);
      }}
    >
      <PopoverTrigger
        data-testid={"UserButton_trigger"}
        className="flex flex-row items-center gap-2 text-sm text-gray focus:outline-none"
      >
        <div className="flex h-[32px] w-[32px] flex-col items-center justify-center overflow-hidden rounded-full bg-gray-light-200 text-2xs uppercase text-black">
          <img src={getUserPicture(profile)} alt="" className={"h-full w-full object-cover"} />
        </div>
      </PopoverTrigger>
      <PopoverContent className="mt-2 flex w-[290px] rounded-[16px] border-0 p-0" align="end">
        <div className="flex w-[290px] flex-auto flex-col gap-3 overflow-hidden rounded-[16px] bg-white p-4 dark:border dark:border-gray-dark-900 dark:bg-black">
          <div className="flex flex-col gap-2">
            <div className="flex flex-row items-center">
              <img
                src={getUserPicture(profile)}
                alt=""
                className={"mr-3 h-[32px] w-[32px] rounded-full object-cover"}
              />
              <div className="flex-1 truncate">
                <div className="truncate text-sm text-black dark:text-white">
                  {getUserName(profile)}
                </div>
                <div className="text-xs text-gray-dark-1000">
                  {getUserAttribute(profile, "email")}
                </div>
              </div>
            </div>

            <div className="my-2 h-[1px] bg-gray-dark-900"></div>
            <div
              onClick={() => setLocation("/id/account/settings")}
              className={`flex cursor-pointer flex-row items-center rounded px-4 py-[10px] text-sm text-gray-dark-1000 transition-colors hover:bg-gray-dark-1100 hover:text-white`}
            >
              <SettingsIcon className="mr-2 h-[18px]" />
              Account Settings
            </div>
            <div
              onClick={() => setLocation("/id/account/io-elements")}
              className={`flex cursor-pointer flex-row items-center rounded px-4 py-[10px] text-sm text-gray-dark-1000 transition-colors hover:bg-gray-dark-1100 hover:text-white`}
            >
              <ShareIcon className="mr-2 h-[18px]" />
              IO Elements
            </div>
            <div className="my-2 h-[1px] bg-gray-dark-900"></div>
            <Button
              onClick={handleLogout}
              variant="ghost"
              className=" text-red-light-800 hover:text-red-light-800"
            >
              <LogOutIcon className="mr-1 h-[18px]" />
              <span>Sign Out</span>
              <div className="flex-1"></div>
            </Button>
          </div>
        </div>
      </PopoverContent>
    </Popover>
  );
};

import { CoinIcon } from "@/components/Icons/CoinIcon";
import { CoinLogo } from "@/components/Icons/CoinLogo";
import { IoIcon } from "@/components/Icons/IoIcon";

export const IoCoin = ({ className }: { className?: string }) => {
  return (
    <div className={`flex flex-row items-center gap-1 ${className}`}>
      <IoIcon className="h-[10px]" />
      <span className="h-[12px] w-[1px] bg-gray-dark-1000/30"></span>
      <CoinLogo className="h-[10px] px-[1px]" />
      <CoinIcon className="mr-1 h-[10px]" />
    </div>
  );
};

import { Redirect, Route, RouteComponentProps, useLocation } from "wouter";
import { ComponentType } from "react";
import { AuthMachineContext } from "@/store/machine/authMachine/authMachine";
import { AuthStateType } from "@/types/auth";
import { DEFAULT_ROUTE_AFTER_LOGIN } from "@/constants";
import { urlWithCurrentQuery } from "@/utils";
import { useQueryParams } from "@/hooks/useQueryParams";
import env from "@/env";
import { isOnLoginSubdomain, redirectToLogin } from "@/utils/tool";
import { redirectUsingQuery } from "@/utils/location";

type AuthenticatedRouteProps = {
  path: string;
  component: ComponentType<RouteComponentProps>;
  requiresState?: AuthStateType;
};

export const AuthenticatedRoute: React.FC<AuthenticatedRouteProps> = ({
  path,
  component,
  requiresState
}) => {
  const isLoggedIn = AuthMachineContext.useSelector((state) => state.context.isLoggedIn);
  const value = AuthMachineContext.useSelector((state) => state.value);
  const [location] = useLocation();
  const query = useQueryParams<{ redirect: string }>();

  if (!isLoggedIn) {
    const redirect = query.redirect ?? location;

    if (env.SECTION_SUBDOMAINS_ENABLED && !isOnLoginSubdomain()) {
      redirectToLogin(redirect);
      return null;
    }

    return (
      <Redirect
        to={urlWithCurrentQuery("/login", {
          ...(redirect.length > 1
            ? {
                redirect
              }
            : {})
        })}
      />
    );
  }

  if (requiresState && value !== requiresState) {
    if (STATES_TO_IGNORE.indexOf(value) > -1) {
      return null;
    }

    console.log(`route requires state - ${path} - ${requiresState} but current state is ${value}`);
    return <Redirect to={redirectUsingQuery(DEFAULT_ROUTE_AFTER_LOGIN)} />;
  }

  return <Route path={path} component={component} />;
};

const STATES_TO_IGNORE = [AuthStateType.REDIRECT, AuthStateType.AUTHORISED];

import { CookieAcceptAllModal } from "../CookieAcceptAllModal/CookieAcceptAllModal";
import { useCookieStore } from "@/store/cookie";
import { CookieSelectionModal } from "../CookieSelectionModal/CookieSelectionModal";

export const CookieWidget = () => {
  const isAcceptAllModalOpen = useCookieStore((state) => state.isAcceptAllModalOpen);
  const setIsAcceptAllModalOpen = useCookieStore((state) => state.setIsAcceptAllModalOpen);
  const isSelectionModalOpen = useCookieStore((state) => state.isSelectionModalOpen);
  const setIsSelectionModalOpen = useCookieStore((state) => state.setIsSelectionModalOpen);

  return (
    <>
      <CookieAcceptAllModal
        isOpen={isAcceptAllModalOpen}
        onClose={() => {
          setIsAcceptAllModalOpen(false);
        }}
      />
      <CookieSelectionModal
        isOpen={isSelectionModalOpen}
        onClose={() => {
          setIsSelectionModalOpen(false);
        }}
      />
    </>
  );
};

import env from "@/env";
import { UserStakedDevice } from "@/types/staking";
import { clusterApiUrl } from "@solana/web3.js";
import { createContext } from "react";

export const StakeDeviceResultsDataTableContext = createContext<{
  setSelectedDevice: React.Dispatch<React.SetStateAction<UserStakedDevice | undefined>>;
  setSelectedAction: React.Dispatch<React.SetStateAction<string | undefined>>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
}>(undefined as any);

export const getStakingNetworkApiEndpoint = () => {
  if (env.STAKING_NETWORK === "devnet") {
    return clusterApiUrl(env.STAKING_NETWORK);
  }
  return env.STAKING_NETWORK_API_ENDPOINT;
};

import { IconProps } from "@/components/Icons/index";

export const DiscordIcon: React.FC<IconProps> = (props) => (
  <svg {...props} viewBox="0 0 14 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M11.8458 1.55176C10.9591 1.13842 9.99905 0.838408 8.99902 0.66507C8.99025 0.664789 8.98153 0.666435 8.97347 0.66989C8.96541 0.673346 8.9582 0.678528 8.95235 0.68507C8.83235 0.905076 8.69235 1.19175 8.59901 1.41176C7.53832 1.25175 6.45962 1.25175 5.39892 1.41176C5.30559 1.18508 5.16558 0.905076 5.03891 0.68507C5.03225 0.671737 5.01225 0.66507 4.99225 0.66507C3.99222 0.838408 3.03886 1.13842 2.1455 1.55176C2.13883 1.55176 2.13217 1.55843 2.1255 1.56509C0.312118 4.2785 -0.187896 6.91857 0.0587777 9.53198C0.0587777 9.54531 0.0654445 9.55865 0.0787782 9.56531C1.27881 10.4453 2.43218 10.9787 3.57221 11.332C3.59221 11.3387 3.61221 11.332 3.61888 11.3187C3.88555 10.952 4.12556 10.5653 4.33223 10.1587C4.34556 10.132 4.33223 10.1053 4.30556 10.0987C3.92555 9.95199 3.56554 9.77865 3.2122 9.57865C3.18553 9.56531 3.18553 9.52531 3.20553 9.50531C3.27887 9.45198 3.3522 9.39198 3.42554 9.33864C3.43887 9.32531 3.45887 9.32531 3.4722 9.33197C5.7656 10.3787 8.239 10.3787 10.5057 9.33197C10.5191 9.32531 10.5391 9.32531 10.5524 9.33864C10.6257 9.39864 10.6991 9.45198 10.7724 9.51198C10.7991 9.53198 10.7991 9.57198 10.7657 9.58531C10.4191 9.79199 10.0524 9.95866 9.67237 10.1053C9.64571 10.112 9.63904 10.1453 9.64571 10.1653C9.85905 10.572 10.0991 10.9587 10.3591 11.3254C10.3791 11.332 10.3991 11.3387 10.4191 11.332C11.5658 10.9787 12.7191 10.4453 13.9192 9.56531C13.9325 9.55865 13.9392 9.54531 13.9392 9.53198C14.2325 6.5119 13.4525 3.89182 11.8724 1.56509C11.8658 1.55843 11.8591 1.55176 11.8458 1.55176ZM4.6789 7.9386C3.99222 7.9386 3.41887 7.30525 3.41887 6.52523C3.41887 5.74521 3.97889 5.11186 4.6789 5.11186C5.38559 5.11186 5.94561 5.75188 5.93894 6.52523C5.93894 7.30525 5.37892 7.9386 4.6789 7.9386ZM9.3257 7.9386C8.63901 7.9386 8.06566 7.30525 8.06566 6.52523C8.06566 5.74521 8.62568 5.11186 9.3257 5.11186C10.0324 5.11186 10.5924 5.75188 10.5857 6.52523C10.5857 7.30525 10.0324 7.9386 9.3257 7.9386Z"
      fill="currentColor"
    />
  </svg>
);

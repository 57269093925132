export type IoStaking = {
  version: "0.1.0";
  name: "io_staking";
  instructions: [
    {
      name: "initMerkleRoot";
      accounts: [
        {
          name: "authority";
          isMut: true;
          isSigner: true;
        },
        {
          name: "globalAccount";
          isMut: true;
          isSigner: false;
        },
        {
          name: "systemProgram";
          isMut: false;
          isSigner: false;
        }
      ];
      args: [];
    },
    {
      name: "initialize";
      accounts: [
        {
          name: "deviceOwner";
          isMut: true;
          isSigner: true;
        },
        {
          name: "poolInfo";
          isMut: true;
          isSigner: false;
        },
        {
          name: "ioTokenMint";
          isMut: true;
          isSigner: false;
        },
        {
          name: "systemProgram";
          isMut: false;
          isSigner: false;
        }
      ];
      args: [
        {
          name: "deviceUuid";
          type: "string";
        }
      ];
    },
    {
      name: "stake";
      accounts: [
        {
          name: "user";
          isMut: true;
          isSigner: true;
        },
        {
          name: "superAdmin";
          isMut: true;
          isSigner: true;
        },
        {
          name: "poolInfo";
          isMut: true;
          isSigner: false;
        },
        {
          name: "stakeInfo";
          isMut: true;
          isSigner: false;
        },
        {
          name: "ioTokenMint";
          isMut: true;
          isSigner: false;
        },
        {
          name: "userTokenAccount";
          isMut: true;
          isSigner: false;
        },
        {
          name: "globalAccount";
          isMut: true;
          isSigner: false;
        },
        {
          name: "globalVaultTokenAccount";
          isMut: true;
          isSigner: false;
        },
        {
          name: "tokenProgram";
          isMut: false;
          isSigner: false;
        },
        {
          name: "systemProgram";
          isMut: false;
          isSigner: false;
        }
      ];
      args: [
        {
          name: "deviceUuid";
          type: "string";
        },
        {
          name: "amount";
          type: "u64";
        }
      ];
    },
    {
      name: "unstake";
      accounts: [
        {
          name: "user";
          isMut: true;
          isSigner: true;
        },
        {
          name: "superAdmin";
          isMut: true;
          isSigner: true;
        },
        {
          name: "stakeInfo";
          isMut: true;
          isSigner: false;
        },
        // {
        //   name: "userTokenAccount";
        //   isMut: true;
        //   isSigner: false;
        // },
        {
          name: "poolInfo";
          isMut: true;
          isSigner: false;
        },
        {
          name: "globalAccount";
          isMut: true;
          isSigner: false;
        },
        {
          name: "globalVaultTokenAccount";
          isMut: true;
          isSigner: false;
        },
        {
          name: "ioTokenMint";
          isMut: true;
          isSigner: false;
        },
        {
          name: "tokenProgram";
          isMut: false;
          isSigner: false;
        }
      ];
      args: [
        {
          name: "reward";
          type: "u64";
        }
      ];
    },
    {
      name: "withdraw";
      accounts: [
        {
          name: "user";
          isMut: true;
          isSigner: true;
        },
        {
          name: "stakeInfo";
          isMut: true;
          isSigner: false;
        },
        {
          name: "userTokenAccount";
          isMut: true;
          isSigner: false;
        },
        {
          name: "poolInfo";
          isMut: true;
          isSigner: false;
        },
        {
          name: "globalAccount";
          isMut: true;
          isSigner: false;
        },
        {
          name: "globalVaultTokenAccount";
          isMut: true;
          isSigner: false;
        },
        {
          name: "ioTokenMint";
          isMut: true;
          isSigner: false;
        },
        {
          name: "tokenProgram";
          isMut: false;
          isSigner: false;
        }
      ];
      args: [];
    },
    {
      name: "reward";
      accounts: [
        {
          name: "globalAccount";
          isMut: true;
          isSigner: false;
        },
        {
          name: "authority";
          isMut: true;
          isSigner: true;
        },
        {
          name: "systemProgram";
          isMut: false;
          isSigner: false;
        }
      ];
      args: [
        {
          name: "root";
          type: {
            array: ["u8", 32];
          };
        }
      ];
    },
    {
      name: "deposit";
      accounts: [
        {
          name: "ioFondation";
          isMut: true;
          isSigner: true;
        },
        {
          name: "ioTokenMint";
          isMut: true;
          isSigner: false;
        },
        {
          name: "ioFondationTokenAccount";
          isMut: true;
          isSigner: false;
        },
        {
          name: "globalAccount";
          isMut: true;
          isSigner: false;
        },
        {
          name: "globalVaultTokenAccount";
          isMut: true;
          isSigner: false;
        },
        {
          name: "tokenProgram";
          isMut: false;
          isSigner: false;
        },
        {
          name: "systemProgram";
          isMut: false;
          isSigner: false;
        }
      ];
      args: [
        {
          name: "amount";
          type: "u64";
        }
      ];
    }
  ];
  accounts: [
    {
      name: "merkleStateAccount";
      type: {
        kind: "struct";
        fields: [
          {
            name: "authority";
            type: "publicKey";
          },
          {
            name: "merkleRoot";
            type: {
              array: ["u8", 32];
            };
          }
        ];
      };
    },
    {
      name: "merkleStateAccount";
      type: {
        kind: "struct";
        fields: [
          {
            name: "authority";
            type: "publicKey";
          },
          {
            name: "merkleRoot";
            type: {
              array: ["u8", 32];
            };
          }
        ];
      };
    },
    {
      name: "merkleStateAccount";
      type: {
        kind: "struct";
        fields: [
          {
            name: "authority";
            type: "publicKey";
          },
          {
            name: "merkleRoot";
            type: {
              array: ["u8", 32];
            };
          }
        ];
      };
    },
    {
      name: "poolInfo";
      type: {
        kind: "struct";
        fields: [
          {
            name: "token";
            type: "publicKey";
          },
          {
            name: "deviceUuid";
            type: "string";
          },
          {
            name: "deviceOwner";
            type: "publicKey";
          },
          {
            name: "totalStakedAmount";
            type: "u64";
          }
        ];
      };
    },
    {
      name: "globalAccountInfo";
      type: {
        kind: "struct";
        fields: [
          {
            name: "authority";
            type: "publicKey";
          },
          {
            name: "bump";
            type: "u8";
          },
          {
            name: "merkleRoot";
            type: {
              array: ["u8", 32];
            };
          }
        ];
      };
    },
    {
      name: "stakeInfo";
      type: {
        kind: "struct";
        fields: [
          {
            name: "stakeSlot";
            type: "u64";
          },
          {
            name: "stakedAmount";
            type: "u64";
          },
          {
            name: "unstakeSlot";
            type: "u64";
          },
          {
            name: "withdrawable";
            type: "u64";
          },
          {
            name: "freezeable";
            type: "u64";
          },
          {
            name: "status";
            type: "u64";
          }
        ];
      };
    }
  ];
  events: [
    {
      name: "InitializeEvent";
      fields: [
        {
          name: "data";
          type: "u64";
          index: false;
        }
      ];
    },
    {
      name: "StakeEvent";
      fields: [
        {
          name: "user";
          type: "publicKey";
          index: false;
        },
        {
          name: "devicePool";
          type: "publicKey";
          index: false;
        },
        {
          name: "stakeAmount";
          type: "u64";
          index: false;
        }
      ];
    },
    {
      name: "UnStakeEvent";
      fields: [
        {
          name: "user";
          type: "publicKey";
          index: false;
        },
        {
          name: "devicePool";
          type: "publicKey";
          index: false;
        },
        {
          name: "unstakeAmount";
          type: "u64";
          index: false;
        }
      ];
    },
    {
      name: "ClaimEvent";
      fields: [
        {
          name: "user";
          type: "publicKey";
          index: false;
        },
        {
          name: "devicePool";
          type: "publicKey";
          index: false;
        },
        {
          name: "totalReward";
          type: "u64";
          index: false;
        },
        {
          name: "rewardToClaim";
          type: "u64";
          index: false;
        }
      ];
    },
    {
      name: "WithdrawEvent";
      fields: [
        {
          name: "user";
          type: "publicKey";
          index: false;
        },
        {
          name: "devicePool";
          type: "publicKey";
          index: false;
        },
        {
          name: "withdrawAmount";
          type: "u64";
          index: false;
        },
        {
          name: "freezeableAmount";
          type: "u64";
          index: false;
        }
      ];
    },
    {
      name: "RewardEvent";
      fields: [
        {
          name: "data";
          type: "u64";
          index: false;
        }
      ];
    },
    {
      name: "DepositEvent";
      fields: [
        {
          name: "data";
          type: "u64";
          index: false;
        }
      ];
    }
  ];
  errors: [
    {
      code: 6000;
      name: "AlreadyInited";
      msg: "Account is Already Initialize.";
    },
    {
      code: 6001;
      name: "InvalidProof";
      msg: "Invalid Merkle proof.";
    },
    {
      code: 6002;
      name: "InvalidStakeStatus";
      msg: "Invalid Stake info Status ";
    },
    {
      code: 6003;
      name: "InvalidPoolinfoDeviceUUID";
      msg: "Invalid pool info device_uuid";
    },
    {
      code: 6004;
      name: "InvalidPoolinfoTokenMint";
      msg: "Invalid pool info token_mint";
    },
    {
      code: 6005;
      name: "InvalidAuthority";
      msg: "Invalid authority.";
    },
    {
      code: 6006;
      name: "InvalidAmount";
      msg: "Invalid amount.";
    },
    {
      code: 6007;
      name: "InvalidSuperAdminSig";
      msg: "Invalid super admin sig.";
    }
  ];
};

export const IDL: IoStaking = {
  version: "0.1.0",
  name: "io_staking",
  instructions: [
    {
      name: "initMerkleRoot",
      accounts: [
        {
          name: "authority",
          isMut: true,
          isSigner: true
        },
        {
          name: "globalAccount",
          isMut: true,
          isSigner: false
        },
        {
          name: "systemProgram",
          isMut: false,
          isSigner: false
        }
      ],
      args: []
    },
    {
      name: "initialize",
      accounts: [
        {
          name: "deviceOwner",
          isMut: true,
          isSigner: true
        },
        {
          name: "poolInfo",
          isMut: true,
          isSigner: false
        },
        {
          name: "ioTokenMint",
          isMut: true,
          isSigner: false
        },
        {
          name: "systemProgram",
          isMut: false,
          isSigner: false
        }
      ],
      args: [
        {
          name: "deviceUuid",
          type: "string"
        }
      ]
    },
    {
      name: "stake",
      accounts: [
        {
          name: "user",
          isMut: true,
          isSigner: true
        },
        {
          name: "superAdmin",
          isMut: true,
          isSigner: true
        },
        {
          name: "poolInfo",
          isMut: true,
          isSigner: false
        },
        {
          name: "stakeInfo",
          isMut: true,
          isSigner: false
        },
        {
          name: "ioTokenMint",
          isMut: true,
          isSigner: false
        },
        {
          name: "userTokenAccount",
          isMut: true,
          isSigner: false
        },
        {
          name: "globalAccount",
          isMut: true,
          isSigner: false
        },
        {
          name: "globalVaultTokenAccount",
          isMut: true,
          isSigner: false
        },
        {
          name: "tokenProgram",
          isMut: false,
          isSigner: false
        },
        {
          name: "systemProgram",
          isMut: false,
          isSigner: false
        }
      ],
      args: [
        {
          name: "deviceUuid",
          type: "string"
        },
        {
          name: "amount",
          type: "u64"
        }
      ]
    },
    {
      name: "unstake",
      accounts: [
        {
          name: "user",
          isMut: true,
          isSigner: true
        },
        {
          name: "superAdmin",
          isMut: true,
          isSigner: true
        },
        {
          name: "stakeInfo",
          isMut: true,
          isSigner: false
        },
        // {
        //   name: "userTokenAccount",
        //   isMut: true,
        //   isSigner: false
        // },
        {
          name: "poolInfo",
          isMut: true,
          isSigner: false
        },
        {
          name: "globalAccount",
          isMut: true,
          isSigner: false
        },
        {
          name: "globalVaultTokenAccount",
          isMut: true,
          isSigner: false
        },
        {
          name: "ioTokenMint",
          isMut: true,
          isSigner: false
        },
        {
          name: "tokenProgram",
          isMut: false,
          isSigner: false
        }
      ],
      args: [
        {
          name: "reward",
          type: "u64"
        }
      ]
    },
    {
      name: "withdraw",
      accounts: [
        {
          name: "user",
          isMut: true,
          isSigner: true
        },
        {
          name: "stakeInfo",
          isMut: true,
          isSigner: false
        },
        {
          name: "userTokenAccount",
          isMut: true,
          isSigner: false
        },
        {
          name: "poolInfo",
          isMut: true,
          isSigner: false
        },
        {
          name: "globalAccount",
          isMut: true,
          isSigner: false
        },
        {
          name: "globalVaultTokenAccount",
          isMut: true,
          isSigner: false
        },
        {
          name: "ioTokenMint",
          isMut: true,
          isSigner: false
        },
        {
          name: "tokenProgram",
          isMut: false,
          isSigner: false
        }
      ],
      args: []
    },
    {
      name: "reward",
      accounts: [
        {
          name: "globalAccount",
          isMut: true,
          isSigner: false
        },
        {
          name: "authority",
          isMut: true,
          isSigner: true
        },
        {
          name: "systemProgram",
          isMut: false,
          isSigner: false
        }
      ],
      args: [
        {
          name: "root",
          type: {
            array: ["u8", 32]
          }
        }
      ]
    },
    {
      name: "deposit",
      accounts: [
        {
          name: "ioFondation",
          isMut: true,
          isSigner: true
        },
        {
          name: "ioTokenMint",
          isMut: true,
          isSigner: false
        },
        {
          name: "ioFondationTokenAccount",
          isMut: true,
          isSigner: false
        },
        {
          name: "globalAccount",
          isMut: true,
          isSigner: false
        },
        {
          name: "globalVaultTokenAccount",
          isMut: true,
          isSigner: false
        },
        {
          name: "tokenProgram",
          isMut: false,
          isSigner: false
        },
        {
          name: "systemProgram",
          isMut: false,
          isSigner: false
        }
      ],
      args: [
        {
          name: "amount",
          type: "u64"
        }
      ]
    }
  ],
  accounts: [
    {
      name: "merkleStateAccount",
      type: {
        kind: "struct",
        fields: [
          {
            name: "authority",
            type: "publicKey"
          },
          {
            name: "merkleRoot",
            type: {
              array: ["u8", 32]
            }
          }
        ]
      }
    },
    {
      name: "merkleStateAccount",
      type: {
        kind: "struct",
        fields: [
          {
            name: "authority",
            type: "publicKey"
          },
          {
            name: "merkleRoot",
            type: {
              array: ["u8", 32]
            }
          }
        ]
      }
    },
    {
      name: "merkleStateAccount",
      type: {
        kind: "struct",
        fields: [
          {
            name: "authority",
            type: "publicKey"
          },
          {
            name: "merkleRoot",
            type: {
              array: ["u8", 32]
            }
          }
        ]
      }
    },
    {
      name: "poolInfo",
      type: {
        kind: "struct",
        fields: [
          {
            name: "token",
            type: "publicKey"
          },
          {
            name: "deviceUuid",
            type: "string"
          },
          {
            name: "deviceOwner",
            type: "publicKey"
          },
          {
            name: "totalStakedAmount",
            type: "u64"
          }
        ]
      }
    },
    {
      name: "globalAccountInfo",
      type: {
        kind: "struct",
        fields: [
          {
            name: "authority",
            type: "publicKey"
          },
          {
            name: "bump",
            type: "u8"
          },
          {
            name: "merkleRoot",
            type: {
              array: ["u8", 32]
            }
          }
        ]
      }
    },
    {
      name: "stakeInfo",
      type: {
        kind: "struct",
        fields: [
          {
            name: "stakeSlot",
            type: "u64"
          },
          {
            name: "stakedAmount",
            type: "u64"
          },
          {
            name: "unstakeSlot",
            type: "u64"
          },
          {
            name: "withdrawable",
            type: "u64"
          },
          {
            name: "freezeable",
            type: "u64"
          },
          {
            name: "status",
            type: "u64"
          }
        ]
      }
    }
  ],
  events: [
    {
      name: "InitializeEvent",
      fields: [
        {
          name: "data",
          type: "u64",
          index: false
        }
      ]
    },
    {
      name: "StakeEvent",
      fields: [
        {
          name: "user",
          type: "publicKey",
          index: false
        },
        {
          name: "devicePool",
          type: "publicKey",
          index: false
        },
        {
          name: "stakeAmount",
          type: "u64",
          index: false
        }
      ]
    },
    {
      name: "UnStakeEvent",
      fields: [
        {
          name: "user",
          type: "publicKey",
          index: false
        },
        {
          name: "devicePool",
          type: "publicKey",
          index: false
        },
        {
          name: "unstakeAmount",
          type: "u64",
          index: false
        }
      ]
    },
    {
      name: "ClaimEvent",
      fields: [
        {
          name: "user",
          type: "publicKey",
          index: false
        },
        {
          name: "devicePool",
          type: "publicKey",
          index: false
        },
        {
          name: "totalReward",
          type: "u64",
          index: false
        },
        {
          name: "rewardToClaim",
          type: "u64",
          index: false
        }
      ]
    },
    {
      name: "WithdrawEvent",
      fields: [
        {
          name: "user",
          type: "publicKey",
          index: false
        },
        {
          name: "devicePool",
          type: "publicKey",
          index: false
        },
        {
          name: "withdrawAmount",
          type: "u64",
          index: false
        },
        {
          name: "freezeableAmount",
          type: "u64",
          index: false
        }
      ]
    },
    {
      name: "RewardEvent",
      fields: [
        {
          name: "data",
          type: "u64",
          index: false
        }
      ]
    },
    {
      name: "DepositEvent",
      fields: [
        {
          name: "data",
          type: "u64",
          index: false
        }
      ]
    }
  ],
  errors: [
    {
      code: 6000,
      name: "AlreadyInited",
      msg: "Account is Already Initialize."
    },
    {
      code: 6001,
      name: "InvalidProof",
      msg: "Invalid Merkle proof."
    },
    {
      code: 6002,
      name: "InvalidStakeStatus",
      msg: "Invalid Stake info Status "
    },
    {
      code: 6003,
      name: "InvalidPoolinfoDeviceUUID",
      msg: "Invalid pool info device_uuid"
    },
    {
      code: 6004,
      name: "InvalidPoolinfoTokenMint",
      msg: "Invalid pool info token_mint"
    },
    {
      code: 6005,
      name: "InvalidAuthority",
      msg: "Invalid authority."
    },
    {
      code: 6006,
      name: "InvalidAmount",
      msg: "Invalid amount."
    },
    {
      code: 6007,
      name: "InvalidSuperAdminSig",
      msg: "Invalid super admin sig."
    }
  ]
};

import { IconProps } from ".";

export const InferenceIconRound: React.FC<IconProps> = (props) => (
  <svg
    {...props}
    width="13"
    height="12"
    viewBox="0 0 13 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.24788 1.64642L11.2479 9.64642L10.5408 10.3535L2.54077 2.35353L3.24788 1.64642Z"
      className={"fill-[#cccccc] dark:fill-[#4c4c4c]"}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.54077 9.64642L10.5408 1.64642L11.2479 2.35353L3.24788 10.3535L2.54077 9.64642Z"
      className={"fill-[#cccccc] dark:fill-[#4c4c4c]"}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.89429 5.5H11.8943V6.5H1.89429V5.5Z"
      className={"fill-[#cccccc] dark:fill-[#4c4c4c]"}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.39429 11V1H7.39429V11H6.39429Z"
      className={"fill-[#cccccc] dark:fill-[#4c4c4c]"}
    />
    <path
      d="M7.89429 6C7.89429 6.55228 7.44657 7 6.89429 7C6.342 7 5.89429 6.55228 5.89429 6C5.89429 5.44772 6.342 5 6.89429 5C7.44657 5 7.89429 5.44772 7.89429 6Z"
      className={"fill-current dark:fill-[#bbb]"}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.89429 7C7.44657 7 7.89429 6.55228 7.89429 6C7.89429 5.44772 7.44657 5 6.89429 5C6.342 5 5.89429 5.44772 5.89429 6C5.89429 6.55228 6.342 7 6.89429 7Z"
      className={"fill-[#4c4c4c] dark:fill-[#bbb]"}
    />
    <path
      d="M3.89429 2C3.89429 2.55228 3.44657 3 2.89429 3C2.342 3 1.89429 2.55228 1.89429 2C1.89429 1.44772 2.342 1 2.89429 1C3.44657 1 3.89429 1.44772 3.89429 2Z"
      className="fill-[#4C4C4C] dark:fill-[#bbb]"
    />
    <path
      d="M7.89429 1C7.89429 1.55228 7.44657 2 6.89429 2C6.342 2 5.89429 1.55228 5.89429 1C5.89429 0.447715 6.342 0 6.89429 0C7.44657 0 7.89429 0.447715 7.89429 1Z"
      fill="currentColor"
    />
    <path
      d="M11.8943 2C11.8943 2.55228 11.4466 3 10.8943 3C10.342 3 9.89429 2.55228 9.89429 2C9.89429 1.44772 10.342 1 10.8943 1C11.4466 1 11.8943 1.44772 11.8943 2Z"
      className="fill-[#4C4C4C] dark:fill-[#bbb]"
    />
    <path
      d="M2.89429 6C2.89429 6.55228 2.44657 7 1.89429 7C1.342 7 0.894287 6.55228 0.894287 6C0.894287 5.44772 1.342 5 1.89429 5C2.44657 5 2.89429 5.44772 2.89429 6Z"
      fill="currentColor"
    />
    <path
      d="M12.8943 6C12.8943 6.55228 12.4466 7 11.8943 7C11.342 7 10.8943 6.55228 10.8943 6C10.8943 5.44772 11.342 5 11.8943 5C12.4466 5 12.8943 5.44772 12.8943 6Z"
      fill="currentColor"
    />
    <path
      d="M3.89429 10C3.89429 10.5523 3.44657 11 2.89429 11C2.342 11 1.89429 10.5523 1.89429 10C1.89429 9.44772 2.342 9 2.89429 9C3.44657 9 3.89429 9.44772 3.89429 10Z"
      className="fill-[#4C4C4C] dark:fill-[#bbb]"
    />
    <path
      d="M7.89429 11C7.89429 11.5523 7.44657 12 6.89429 12C6.342 12 5.89429 11.5523 5.89429 11C5.89429 10.4477 6.342 10 6.89429 10C7.44657 10 7.89429 10.4477 7.89429 11Z"
      fill="currentColor"
    />
    <path
      d="M11.8943 10C11.8943 10.5523 11.4466 11 10.8943 11C10.342 11 9.89429 10.5523 9.89429 10C9.89429 9.44772 10.342 9 10.8943 9C11.4466 9 11.8943 9.44772 11.8943 10Z"
      className="fill-[#4C4C4C] dark:fill-[#bbb]"
    />
  </svg>
);

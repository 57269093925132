import { IconProps } from ".";

export const EarningsIcon: React.FC<IconProps> = (props) => {
  return (
    <svg viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <rect x="2.5" y="0.5" width="10" height="6" rx="1" fill="#CCCCCC" />
      <rect
        x="1.5"
        y="2.5"
        width="12"
        height="8"
        rx="1"
        className="fill-[#4C4C4C] dark:fill-gray"
      />
      <rect x="0.5" y="4.5" width="14" height="9" rx="1" className={"fill-black dark:fill-white"} />
      <path
        d="M7.37407 11.5V6.5H7.67778V11.5H7.37407ZM8.17963 8.15039C8.16481 7.99284 8.10123 7.87044 7.98889 7.7832C7.87654 7.69596 7.72407 7.65234 7.53148 7.65234C7.40062 7.65234 7.29012 7.67188 7.2 7.71094C7.10988 7.7487 7.04074 7.80143 6.99259 7.86914C6.94568 7.93685 6.92222 8.01367 6.92222 8.09961C6.91975 8.17122 6.93395 8.23372 6.96481 8.28711C6.99691 8.34049 7.04074 8.38672 7.0963 8.42578C7.15185 8.46354 7.21605 8.49674 7.28889 8.52539C7.36173 8.55273 7.43951 8.57617 7.52222 8.5957L7.86296 8.68164C8.02839 8.7207 8.18025 8.77279 8.31852 8.83789C8.45679 8.90299 8.57654 8.98307 8.67778 9.07812C8.77901 9.17318 8.85741 9.28516 8.91296 9.41406C8.96975 9.54297 8.99877 9.69076 9 9.85742C8.99877 10.1022 8.93951 10.3145 8.82222 10.4941C8.70617 10.6725 8.53827 10.8112 8.31852 10.9102C8.1 11.0078 7.83642 11.0566 7.52778 11.0566C7.22161 11.0566 6.95494 11.0072 6.72778 10.9082C6.50185 10.8092 6.32531 10.6628 6.19815 10.4687C6.07222 10.2734 6.00617 10.0319 6 9.74414H6.77593C6.78457 9.87826 6.82099 9.99023 6.88519 10.0801C6.95062 10.1686 7.03765 10.2357 7.1463 10.2812C7.25617 10.3255 7.38025 10.3477 7.51852 10.3477C7.65432 10.3477 7.77222 10.3268 7.87222 10.2852C7.97346 10.2435 8.05185 10.1855 8.10741 10.1113C8.16296 10.0371 8.19074 9.95182 8.19074 9.85547C8.19074 9.76562 8.16543 9.6901 8.11481 9.62891C8.06543 9.56771 7.99259 9.51562 7.8963 9.47266C7.80123 9.42969 7.68457 9.39062 7.5463 9.35547L7.13333 9.24609C6.81358 9.16406 6.56111 9.03581 6.37593 8.86133C6.19074 8.68685 6.09877 8.45182 6.1 8.15625C6.09877 7.91406 6.15988 7.70247 6.28333 7.52148C6.40802 7.34049 6.57901 7.19922 6.7963 7.09766C7.01358 6.99609 7.26049 6.94531 7.53704 6.94531C7.81852 6.94531 8.0642 6.99609 8.27407 7.09766C8.48519 7.19922 8.64938 7.34049 8.76667 7.52148C8.88395 7.70247 8.94444 7.91211 8.94815 8.15039H8.17963Z"
        className="fill-white dark:fill-black"
      />
    </svg>
  );
};

import { ToolLogo } from "@/components/Common/ToolLogo/ToolLogo";
// import { UserBalance } from "@/components/Common/UserBalance/UserBalance";
import { useMainStore } from "@/store/main";
import { SmartLink } from "../Common/SmartLink/SmartLink";
import { Tool } from "@/types";
// import { BalanceType } from "@/types/user";

type IoElementsListType = {
  height?: string;
  padding?: string;
  onClick?: (item: Tool) => void;
};

export const IoElementsList = ({
  height = "120px",
  padding = "4",
  onClick
}: IoElementsListType) => {
  const getToolOptions = useMainStore((state) => state.getToolOptions);

  return getToolOptions().map((item) => {
    const { value, href } = item;
    // const withBalance = value === "worker" || value === "cloud";
    return (
      <SmartLink
        data-testid={`MainLogo_${value}Link`}
        key={href}
        href={href}
        onClick={onClick ? () => onClick(item) : undefined}
      >
        <div
          style={{
            backgroundImage: `url(${item.bg})`,
            backgroundSize: "100% 100%"
          }}
          className={`item relative flex h-[${height}] cursor-pointer flex-col rounded border border-gray-dark-900 bg-gray-dark-600 bg-cover bg-bottom
             bg-no-repeat transition-colors p-${padding} items-stretch justify-between hover:border-gray-dark-1200
            `}
        >
          <ToolLogo tool={item} height={"14px"} />
        </div>
      </SmartLink>
    );
  });
};

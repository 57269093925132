import { IconProps } from ".";

export const ShareIcon: React.FC<IconProps> = (props) => {
  return (
    <svg viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.5 2.0625C12.568 2.0625 11.8125 2.81802 11.8125 3.75C11.8125 4.04604 11.8887 4.32428 12.0226 4.56616C12.0299 4.57678 12.0368 4.58773 12.0433 4.59898C12.0498 4.61016 12.0559 4.62146 12.0616 4.63288C12.3585 5.11562 12.8917 5.4375 13.5 5.4375C14.432 5.4375 15.1875 4.68198 15.1875 3.75C15.1875 2.81802 14.432 2.0625 13.5 2.0625ZM11.4028 5.62405C11.9178 6.19999 12.6666 6.5625 13.5 6.5625C15.0533 6.5625 16.3125 5.3033 16.3125 3.75C16.3125 2.1967 15.0533 0.9375 13.5 0.9375C11.9467 0.9375 10.6875 2.1967 10.6875 3.75C10.6875 4.06571 10.7395 4.36928 10.8355 4.65259L6.5972 7.12595C6.08218 6.55001 5.33342 6.1875 4.5 6.1875C2.9467 6.1875 1.6875 7.4467 1.6875 9C1.6875 10.5533 2.9467 11.8125 4.5 11.8125C5.33354 11.8125 6.08239 11.4499 6.59742 10.8738L10.8366 13.3441C10.7399 13.6283 10.6875 13.933 10.6875 14.25C10.6875 15.8033 11.9467 17.0625 13.5 17.0625C15.0533 17.0625 16.3125 15.8033 16.3125 14.25C16.3125 12.6967 15.0533 11.4375 13.5 11.4375C12.6678 11.4375 11.9201 11.7989 11.4052 12.3733L7.16465 9.90228C7.26052 9.61906 7.3125 9.3156 7.3125 9C7.3125 8.68429 7.26048 8.38072 7.16454 8.09741L11.4028 5.62405ZM5.9384 8.11712C5.94406 8.12854 5.95016 8.13984 5.95668 8.15102C5.96325 8.16228 5.97015 8.17322 5.97736 8.18385C6.11127 8.42572 6.1875 8.70396 6.1875 9C6.1875 9.29604 6.11127 9.57427 5.97737 9.81614C5.97009 9.82687 5.96312 9.83792 5.9565 9.84929C5.95004 9.86036 5.94401 9.87156 5.9384 9.88287C5.64147 10.3656 5.10832 10.6875 4.5 10.6875C3.56802 10.6875 2.8125 9.93198 2.8125 9C2.8125 8.06802 3.56802 7.3125 4.5 7.3125C5.10832 7.3125 5.64146 7.63438 5.9384 8.11712ZM12.0027 13.4709C12.0203 13.4491 12.0365 13.4257 12.051 13.4007C12.065 13.3766 12.0771 13.3519 12.0872 13.3268C12.3885 12.8666 12.9088 12.5625 13.5 12.5625C14.432 12.5625 15.1875 13.318 15.1875 14.25C15.1875 15.182 14.432 15.9375 13.5 15.9375C12.568 15.9375 11.8125 15.182 11.8125 14.25C11.8125 13.969 11.8812 13.704 12.0027 13.4709Z"
        fill="currentColor"
      />
    </svg>
  );
};

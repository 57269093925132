import { ContentLoader } from "../ContentLoader/ContentLoader";
import { ErrorFeedback } from "../ErrorFeedback/ErrorFeedback";

type AsyncSuspenseProps = {
  error?: Error;
  errorTitle?: string;
  isLoading?: boolean;
  children: React.ReactNode;
  alwaysDisplayChildren?: boolean;
};

export const AsyncSuspense: React.FC<AsyncSuspenseProps> = ({
  error,
  errorTitle = "ERROR",
  isLoading = false,
  children,
  alwaysDisplayChildren = false
}) => {
  if (error) {
    if (alwaysDisplayChildren) {
      return (
        <>
          {children}
          <ErrorFeedback title={errorTitle} message={error.message} />
        </>
      );
    }
    return <ErrorFeedback title={errorTitle} message={error.message} />;
  }

  if (isLoading) {
    return <ContentLoader />;
  }

  return children;
};

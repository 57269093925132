import env from "@/env";
import Cookies from "js-cookie";

const localCookies = {
  getItem: (name: string) => {
    const value = Cookies.get(name) as string | undefined;

    // To ensure it functions same as localStorage.getItem
    if (value === undefined) {
      return null;
    }
    return value;
  },
  setItem: (name: string, value: string) => {
    Cookies.set(name, value, {
      domain: getDomain(),
      secure: isSecure(),
      expires: getExpires(),
      sameSite: "None"
    });
  },
  removeItem: (name: string) => {
    Cookies.remove(name, { domain: getDomain(), secure: isSecure() });
  }
};

const getExpires = () => {
  // Cookies valid for 7 days;
  return new Date(new Date().getTime() + 1000 * 60 * 60 * 24 * 7);
};

const isSecure = () => {
  return true;
};

const getDomain = () => {
  if (!env.APP_DOMAIN || window.location.hostname === "localhost") {
    return;
  }
  return `.${env.APP_DOMAIN}`;
};

export default localCookies;

import { parse, stringify } from "qs";
import localCookies from "./cookie";
import { BlockDailyEarnings, DeviceDailyEarningsType } from "@/types";

export const isEqual = (a: unknown, b: unknown) => {
  return JSON.stringify(a) === JSON.stringify(b);
};

export const sleep = async (time: number) => {
  return new Promise((resolve) => setTimeout(resolve, time));
};

export const isObject = (value: unknown) => {
  return value !== null && typeof value === "object";
};

export const isEmpty = (value: unknown) => {
  return (
    (Array.isArray(value) && value.length === 0) ||
    value === "" ||
    value === null ||
    value === undefined
  );
};

export const filterNull = <T>(value: T) => {
  if (isObject(value)) {
    return Object.fromEntries(
      Object.entries(value as object).filter(({ 1: value }) => {
        return !isEmpty(value);
      })
    ) as T;
  }

  return value;
};

export const jsonify = <T>(value: T) => {
  return JSON.parse(JSON.stringify(value)) as T;
};

export const baseUrl = () => {
  return `${window.location.protocol}//${window.location.hostname}${
    window.location.port ? `:${window.location.port}` : ``
  }`;
};

export const addExternalScript = (url: string) => {
  return new Promise((resolve, reject) => {
    const script = document.createElement("script");
    script.type = "text/javascript";
    script.src = url;
    script.onload = () => {
      resolve(undefined);
    };
    script.onerror = (e) => {
      reject(e);
    };
    script.innerHTML;

    document.head.appendChild(script);
  });
};

export const addScript = async (innerHTML: string) => {
  return new Promise((resolve, reject) => {
    const script = document.createElement("script");
    script.type = "text/javascript";
    script.innerHTML = innerHTML;
    script.onload = () => {
      resolve(undefined);
    };
    script.onerror = (e) => {
      reject(e);
    };
    script.innerHTML;

    document.head.appendChild(script);
  });
};

export const waitForever = () => {
  return new Promise(() => {
    //
  });
};

export const getPermanentState = <T>(storageKey: string, defaultValue: T) => {
  try {
    const currentValue = JSON.parse(localCookies.getItem(storageKey) as string) as T;
    if (currentValue === null) {
      return defaultValue;
    }
    return currentValue;
  } catch (e) {
    return defaultValue;
  }
};

export const setPermanentState = <T>(storageKey: string, value: T) => {
  localCookies.setItem(storageKey, JSON.stringify(value));
};

export const waitFor = async (check: () => boolean, timeout?: number) => {
  if (typeof timeout !== "number") {
    timeout = 5000;
  }

  const reRunDelay = 200;
  let runTime = 0;

  for (;;) {
    const isReady = check();
    if (isReady) {
      return;
    }

    await sleep(reRunDelay);
    runTime += reRunDelay;
    if (runTime > timeout) {
      throw new Error("waitFor timeout exceeded");
    }
  }
};

export const toUrlWithAuthorization = (url: string, username: string, password: string) => {
  const encodedString = btoa(`${username}:${password}`);
  const { 1: urlWithoutPrefix } = url.split("://");

  return `https://${encodedString}@${urlWithoutPrefix}`;
};

export const reloadPage = () => {
  // eslint-disable-next-line no-self-assign
  window.location.href = window.location.href;
};

export const passwordify = (value: string, offset?: number) => {
  if (typeof offset !== "number") {
    offset = 0;
  }

  return `${Array.from(Array(Math.max(0, value.length - offset)).keys())
    .map(() => {
      return "*";
    })
    .join("")}${value.slice(Math.max(0, value.length - offset), value.length)}`;
};

export const urlWithCurrentQuery = (url: string, params?: Record<string, string>) => {
  const parsed = parse(window.location.search?.slice(1));
  const completeParams = {
    ...parsed,
    ...(params ? params : {})
  };

  if (Object.keys(completeParams).length === 0) {
    return url;
  }

  return `${url}?${stringify(completeParams)}`;
};

export const errorIfAborted = (abortController?: AbortController) => {
  if (abortController?.signal.aborted) {
    throw new Error("aborted");
  }
};

export const aggregateByMonth = (earnings: BlockDailyEarnings[] | DeviceDailyEarningsType[]) => {
  const earningsByMonth: Record<string, number> = {};

  earnings.forEach((data) => {
    let month = data.dateWithYear.slice(0, 7); // Extracting year and month in 'YYYY-MM' format
    month += "-02"; // Adding day to make it 'YYYY-MM-DD' format

    if (!earningsByMonth[month]) {
      earningsByMonth[month] = 0;
    }

    if ("earning" in data) {
      earningsByMonth[month] += data.earning || 0;
    } else if ("rewards" in data) {
      earningsByMonth[month] += data.rewards || 0;
    }
  });

  return earningsByMonth;
};

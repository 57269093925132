import {
  AuthContext,
  AuthEventType,
  AuthEventTypes,
  AuthGuardTypes,
  AuthStateType
} from "@/types/auth";
import { createActorContext } from "@xstate/react";
import { assign, fromPromise, setup } from "xstate";
import { UserAuthToken, UserMetaData } from "@/types/user";
import {
  Auth0CustomUserProfile,
  decodeToken,
  getAudience,
  getAuth0Client,
  getAuth0SpaClient,
  getClientId,
  getRedirectUri,
  getRefreshToken,
  getUser,
  patchUserMetadata,
  userProfile
} from "@/utils/auth0";
import { toFriendlyErrorMessage } from "@/utils/api";
import { getParams } from "@/hooks";
import { executeAPIRequest } from "@/utils/api";
import { stringify } from "qs";
import { INCLUDE_PHONE_VERIFICATION_STEP, RESET_USER_ON_LOGIN } from "@/constants/auth";
import env from "@/env";
import localCookies from "@/utils/cookie";
import { getToolUrl } from "@/utils/tool";
import { useUserStore } from "@/store/user";
import { toStringWithLoginVersionSuffix } from "@/utils/user";
import { urlWithCurrentQuery } from "@/utils";
import { initialiseSupport, shutdownSupport } from "@/utils/support";

export const GUARDS = {
  canDeployCluster: ({ profile }: { profile?: Auth0CustomUserProfile<UserMetaData> }) => {
    if (!INCLUDE_PHONE_VERIFICATION_STEP) {
      return true;
    }
    if (!profile) {
      return false;
    }
    return profile.user_metadata.phone_number_verified === true;
  },
  canConnectDevice: ({
    profile,
    skippedEnterWalletType,
    skippedEnterWalletAddress
  }: {
    profile?: Auth0CustomUserProfile<UserMetaData>;
    skippedEnterWalletType?: boolean;
    skippedEnterWalletAddress?: boolean;
  }) => {
    if (!profile) {
      return false;
    }
    if (skippedEnterWalletType && skippedEnterWalletAddress) {
      return true;
    }
    return (
      typeof profile.user_metadata.wallet_type === "string" &&
      typeof profile.user_metadata.wallet_address === "string"
    );
  },
  shouldSkipPhoneNumberStep: ({
    skippedEnterPhone,
    wantsToDeployCluster
  }: {
    skippedEnterPhone?: boolean;
    wantsToDeployCluster?: boolean;
  }) => {
    if (!INCLUDE_PHONE_VERIFICATION_STEP) {
      return true;
    }
    if (wantsToDeployCluster) {
      return false;
    }
    return skippedEnterPhone === true;
  },
  shouldSkipPhoneCodeStep: ({
    skippedEnterPhone,
    wantsToDeployCluster
  }: {
    skippedEnterPhone?: boolean;
    wantsToDeployCluster?: boolean;
  }) => {
    if (!INCLUDE_PHONE_VERIFICATION_STEP) {
      return true;
    }
    if (wantsToDeployCluster) {
      return false;
    }
    return skippedEnterPhone === true;
  },
  shouldSkipRoleStep: ({
    skippedEnterRole,
    wantsToConnectDevice,
    profile
  }: {
    skippedEnterRole?: boolean;
    wantsToConnectDevice?: boolean;
    profile?: Auth0CustomUserProfile<UserMetaData>;
  }) => {
    if (skippedEnterRole === false) {
      return false;
    }
    if (typeof profile?.user_metadata.role === "string") {
      return true;
    }
    if (wantsToConnectDevice) {
      return false;
    }
    return skippedEnterRole === true;
  },
  shouldSkipWalletTypeStep: ({
    skippedEnterWalletType,
    wantsToConnectDevice,
    profile
  }: {
    skippedEnterWalletType?: boolean;
    wantsToConnectDevice?: boolean;
    profile?: Auth0CustomUserProfile<UserMetaData>;
  }) => {
    if (skippedEnterWalletType === false) {
      return false;
    }
    if (typeof profile?.user_metadata.wallet_type === "string") {
      return true;
    }
    if (wantsToConnectDevice) {
      return false;
    }
    return skippedEnterWalletType === true;
  },
  shouldSkipWalletAddressStep: ({
    skippedEnterWalletAddress,
    wantsToConnectDevice,
    profile
  }: {
    skippedEnterWalletAddress?: boolean;
    wantsToConnectDevice?: boolean;
    profile?: Auth0CustomUserProfile<UserMetaData>;
  }) => {
    if (skippedEnterWalletAddress === false) {
      return false;
    }
    if (typeof profile?.user_metadata.wallet_address === "string") {
      return true;
    }
    if (wantsToConnectDevice) {
      return false;
    }
    return skippedEnterWalletAddress === true;
  }
};

export const authMachine = setup({
  types: {} as {
    context: AuthContext;
    events: AuthEventTypes;
    guards: AuthGuardTypes;
  },
  guards: {
    canNotConnectDevice: ({
      context: { profile, skippedEnterWalletType, skippedEnterWalletAddress }
    }) => {
      return !GUARDS.canConnectDevice({
        profile,
        skippedEnterWalletType,
        skippedEnterWalletAddress
      });
    },
    canNotDeployCluster: ({ context: { profile } }) => {
      return !GUARDS.canDeployCluster({ profile });
    },
    shouldResetUser: () => {
      return RESET_USER_ON_LOGIN;
    },
    wantsToConnectDevice: ({ context: { wantsToConnectDevice } }) => {
      return wantsToConnectDevice === true;
    },
    wantsToDeployCluster: ({ context: { wantsToDeployCluster } }) => {
      return wantsToDeployCluster === true;
    },
    isLoggedIn: ({ context }) => {
      return context.isLoggedIn === true;
    },
    shouldSkipPhoneNumberStep: ({ context: { skippedEnterPhone, wantsToDeployCluster } }) => {
      return GUARDS.shouldSkipPhoneNumberStep({ skippedEnterPhone, wantsToDeployCluster });
    },
    shouldSkipPhoneCodeStep: ({ context: { skippedEnterPhone, wantsToDeployCluster } }) => {
      return GUARDS.shouldSkipPhoneCodeStep({ skippedEnterPhone, wantsToDeployCluster });
    },
    shouldSkipRoleStep: ({ context: { skippedEnterRole, wantsToConnectDevice, profile } }) => {
      return GUARDS.shouldSkipRoleStep({ skippedEnterRole, wantsToConnectDevice, profile });
    },
    shouldSkipWalletTypeStep: ({
      context: { skippedEnterWalletType, wantsToConnectDevice, profile }
    }) => {
      return GUARDS.shouldSkipWalletTypeStep({
        skippedEnterWalletType,
        wantsToConnectDevice,
        profile
      });
    },
    shouldSkipWalletAddressStep: ({
      context: { skippedEnterWalletAddress, wantsToConnectDevice, profile }
    }) => {
      return GUARDS.shouldSkipWalletAddressStep({
        skippedEnterWalletAddress,
        wantsToConnectDevice,
        profile
      });
    }
  },
  actions: {
    log: () => {
      console.log("LOG");
    },
    reset: assign(() => {
      return {
        isLoggedIn: false,
        token: undefined,
        userId: undefined,
        authUserId: undefined,
        internalUserId: undefined,
        profile: undefined,
        ssoType: undefined,
        logoutRedirectUrl: undefined,
        newDeviceId: undefined
      };
    }),
    persist: ({ context }) => {
      try {
        // console.log("storing", JSON.stringify(context));
        localCookies.setItem(PERSISTED_STATE_KEY, JSON.stringify(context));
      } catch (e) {
        console.log(context);
        console.log(e);
      }
    }
  },
  actors: {
    updateMetaData: fromPromise(
      async ({
        input: { token, authUserId, profile, updatedMetaData }
      }: {
        input: {
          token?: string;
          authUserId?: string;
          profile?: Auth0CustomUserProfile<UserMetaData>;
          updatedMetaData?: Partial<UserMetaData>;
        };
      }) => {
        if (!token) {
          throw new Error("token not found");
        }
        if (!authUserId) {
          throw new Error("auth userId not found");
        }
        if (!profile) {
          throw new Error("profile not found");
        }

        const metaData = profile.user_metadata;

        if (updatedMetaData) {
          let auth0MetaData = {
            ...metaData,
            ...updatedMetaData
          };

          const excludedKeys = [
            ...(env.FEATUREFLAG_SOLANA_UPDATE_ENABLED
              ? ["wallet_address_solana", "wallet_address_aptos"]
              : [])
          ];
          const isExcludedKeysOnly =
            Object.keys(updatedMetaData || {}).filter((key) => {
              return !excludedKeys.includes(key);
            }).length === 0;

          for (const excludedKey of excludedKeys) {
            const value = updatedMetaData[excludedKey as keyof UserMetaData];
            if (value === undefined || value === null) {
              continue;
            }

            switch (excludedKey) {
              case "wallet_address_solana":
                await useUserStore.getState().updateSolanaAddress(value as string);
                break;
              case "wallet_address_aptos":
                await useUserStore.getState().updateAptosAddress(value as string);
                break;
            }

            auth0MetaData = {
              ...auth0MetaData,
              [excludedKey]: undefined
            };
          }

          if (!isExcludedKeysOnly) {
            await patchUserMetadata<UserMetaData>(token, authUserId, auth0MetaData);
          }

          return {
            profile: {
              ...profile,
              user_metadata: {
                ...profile.user_metadata,
                ...updatedMetaData
              }
            } as Auth0CustomUserProfile<UserMetaData>
          };
        } else {
          if (env.FEATUREFLAG_SOLANA_UPDATE_ENABLED) {
            const { wallet_address_solana } = metaData;
            if (wallet_address_solana) {
              try {
                await useUserStore.getState().updateSolanaAddress(wallet_address_solana);
              } catch (e) {
                console.log(e);
              }
            }
          }

          await patchUserMetadata<UserMetaData>(token, authUserId, metaData);

          const newProfile = await getUser<UserMetaData>(token, authUserId);

          return { profile: newProfile };
        }
      }
    ),
    sendPhoneVerificationCode: fromPromise(
      async ({
        input: { token, authUserId, profile }
      }: {
        input: {
          token?: string;
          authUserId?: string;
          profile?: Auth0CustomUserProfile<UserMetaData>;
        };
      }) => {
        if (!token) {
          throw new Error("token not found");
        }
        if (!authUserId) {
          throw new Error("auth userId not found");
        }
        if (!profile) {
          throw new Error("profile not found");
        }

        await patchUserMetadata<UserMetaData>(token, authUserId, profile.user_metadata);

        const newProfile = await getUser<UserMetaData>(token, authUserId);

        return { profile: newProfile };
      }
    ),
    validatePhoneCode: fromPromise(
      async ({
        input: { token, authUserId, profile }
      }: {
        input: {
          token?: string;
          authUserId?: string;
          profile?: Auth0CustomUserProfile<UserMetaData>;
        };
      }) => {
        if (!token) {
          throw new Error("token not found");
        }
        if (!authUserId) {
          throw new Error("auth userId not found");
        }
        if (!profile) {
          throw new Error("profile not found");
        }

        const phoneVerificationCode = profile.user_metadata.phone_code;

        // TODO - Implement proper logic
        if (phoneVerificationCode !== "111111") {
          throw new Error("invalid verification code");
        }

        await patchUserMetadata<UserMetaData>(token, authUserId, {
          phone_number_verified: true
        });

        const updatedProfile = await getUser<UserMetaData>(token, authUserId);

        return { profile: updatedProfile };
      }
    ),
    logout: fromPromise(
      async ({ input: { logoutRedirectUrl } }: { input: { logoutRedirectUrl?: string } }) => {
        useUserStore.getState().setUserId(undefined);

        shutdownSupport();

        getAuth0Client().logout({
          clientID: getClientId(),
          returnTo: getToolUrl(
            env.LOGIN_SUBDOMAIN,
            logoutRedirectUrl ? logoutRedirectUrl : `/login`
          )
        });

        localCookies.removeItem(PERSISTED_STATE_KEY);
      }
    ),
    fetchUserInternalId: fromPromise(
      async ({
        input: { userId, profile, internalUserId: currentInternalUserId }
      }: {
        input: {
          userId?: string;
          profile?: Auth0CustomUserProfile<UserMetaData>;
          internalUserId?: string;
        };
      }) => {
        if (!userId) {
          throw new Error("user id not found in auth0 profile");
        }

        let internalUserId = currentInternalUserId;

        if (!internalUserId) {
          const response = await executeAPIRequest<{
            data: {
              user_uuid: string;
            };
            status: string;
          }>({
            method: "get",
            url: `/auth/user-uuid?${stringify({
              io_id: userId
            })}`
          });

          internalUserId = response.data.user_uuid;
        }

        try {
          initialiseSupport({
            userId: internalUserId,
            name: profile?.name,
            email: profile?.email,
            createdAt: profile?.created_at ? Date.parse(profile?.created_at) : undefined
          });

          const getUpdatedProfileWithWalletAddresses = async (
            profile: Auth0CustomUserProfile<UserMetaData>
          ) => {
            if (env.FEATUREFLAG_WALLET_ADDRESS_API_ONLY) {
              try {
                useUserStore.getState().setUserId(internalUserId);

                const addresses = await useUserStore.getState().fetchWalletAddresses();

                return {
                  ...profile,
                  user_metadata: {
                    ...profile.user_metadata,
                    wallet_address_solana:
                      typeof addresses.spl_address === "string" ? addresses.spl_address : undefined,
                    wallet_address_aptos:
                      typeof addresses.aptos_address === "string"
                        ? addresses.aptos_address
                        : undefined
                  }
                };
              } catch (e) {
                console.log(e);
              }

              return profile;
            } else {
              if (profile.user_metadata.wallet_address_solana) {
                return;
              }

              try {
                useUserStore.getState().setUserId(internalUserId);

                const address = await useUserStore.getState().fetchSolanaAddress();
                if (!address) {
                  return;
                }

                return {
                  ...profile,
                  user_metadata: {
                    ...profile.user_metadata,
                    wallet_address_solana: address
                  }
                } as Auth0CustomUserProfile<UserMetaData>;
              } catch (e) {
                console.log(e);
              }
            }
          };

          if (profile) {
            const updatedProfile = await getUpdatedProfileWithWalletAddresses(profile);
            if (updatedProfile) {
              return { internalUserId, profile: updatedProfile };
            }
          }
        } catch (e) {
          console.log(e);
        }

        return { internalUserId };
      }
    ),
    loginWithSSO: fromPromise(async ({ input: { ssoType } }: { input: { ssoType?: string } }) => {
      const client = await getAuth0SpaClient();
      await client.loginWithRedirect({
        authorizationParams: {
          redirect_uri: urlWithCurrentQuery(getRedirectUri()),
          scope: env.AUTH0_SCOPE,
          connection: ssoType,
          audience: getAudience(),
          mode: "signUp",
          prompt: "select_account"
        }
      });
    }),
    checkIfLoggedIn: fromPromise(
      async ({
        input: { token: currentToken, profile: currentProfile }
      }: {
        input: { token?: string; profile?: Auth0CustomUserProfile<UserMetaData> };
      }) => {
        const params = getParams<{
          redirect?: string;
          error?: string;
          error_description?: string;
          code?: string;
        }>();

        if (params.error_description) {
          throw new Error(toFriendlyErrorMessage(params.error_description));
        }

        let hashToken: string | null = null;

        try {
          if (params.code) {
            const client = await getAuth0SpaClient();
            await client.handleRedirectCallback();
            // console.log("__redirectResult", redirectResult);

            hashToken = await getRefreshToken();
            // console.log("__hashToken", hashToken);
          }
        } catch (e) {
          console.log(e);
        }

        const newToken = hashToken ? hashToken : currentToken;

        if (!newToken) {
          return {
            isLoggedIn: false
          };
        }

        const decodedToken = decodeToken<UserAuthToken>(newToken);
        const userId = decodedToken?.["https://io.net/user"].io_id;

        if (!RESET_USER_ON_LOGIN && !hashToken && currentProfile) {
          return {
            isLoggedIn: true,
            token: newToken,
            userId
          };
        }

        let profile = await userProfile<UserMetaData>(newToken);

        if (RESET_USER_ON_LOGIN) {
          await patchUserMetadata<UserMetaData>(newToken, profile.sub, {
            name: null,
            phone_code: null,
            phone_number: null,
            phone_number_code: null,
            phone_number_verified: null,
            wallet_type: null,
            wallet_address: null,
            wallet_address_solana: null,
            wallet_address_aptos: null,
            role: null,
            viewed_io_elements_message: false,
            viewed_welcome_message: false
          });
          profile = await userProfile<UserMetaData>(newToken);
        }

        return {
          isLoggedIn: true,
          token: newToken,
          profile,
          userId
        };
      }
    ),
    refreshMetaData: fromPromise(
      async ({
        input: { authUserId, token, profile: currentProfile }
      }: {
        input: {
          authUserId?: string;
          token?: string;
          profile?: Auth0CustomUserProfile<UserMetaData>;
        };
      }) => {
        if (!authUserId) {
          throw new Error("user id not found");
        }
        if (!token) {
          throw new Error("token not found found");
        }

        if (currentProfile) {
          return { profile: currentProfile };
        }

        const profile = await getUser<UserMetaData>(token, authUserId);

        return { profile };
      }
    )
  }
}).createMachine({
  id: "authMachine",
  context: () => {
    return {
      isLoggedIn: false
    };
  },
  initial: AuthStateType.IDLE,
  on: {
    [AuthEventType.LOGOUT]: {
      guard: "isLoggedIn",
      target: `.${AuthStateType.LOGOUT}`,
      actions: assign({
        logoutRedirectUrl: ({ event }) => {
          return event.value;
        }
      })
    },
    [AuthEventType.SET_TOKEN]: {
      actions: [
        assign({
          token: ({ event }) => {
            return event.value;
          }
        }),
        "persist"
      ]
    }
  },
  states: {
    [AuthStateType.IDLE]: {
      on: {
        [AuthEventType.CHECK_IF_LOGGED_IN]: {
          target: AuthStateType.CHECK_IF_LOGGED_IN
        }
      }
    },
    [AuthStateType.REDIRECT]: {
      on: {
        [AuthEventType.AUTHORISED]: {
          target: AuthStateType.AUTHORISED
        },
        [AuthEventType.CONNECT_DEVICE]: {
          target: AuthStateType.CONNECT_DEVICE,
          actions: assign({
            newDeviceId: ({ event }) => {
              return event.value;
            }
          })
        },
        [AuthEventType.DEPLOY_CLUSTER]: {
          actions: assign({
            clusterType: ({ event }) => {
              return event.value;
            }
          }),
          target: AuthStateType.DEPLOY_CLUSTER
        }
      }
    },
    [AuthStateType.AUTHORISED]: {
      always: {
        actions: ["persist"]
      },
      on: {
        [AuthEventType.CONNECT_DEVICE]: {
          target: AuthStateType.CONNECT_DEVICE,
          actions: assign({
            newDeviceId: ({ event }) => {
              return event.value;
            }
          })
        },
        [AuthEventType.DEPLOY_CLUSTER]: {
          actions: assign({
            clusterType: ({ event }) => {
              return event.value;
            }
          }),
          target: AuthStateType.DEPLOY_CLUSTER
        },
        [AuthEventType.SET_METADATA]: {
          actions: assign({
            failedError: undefined,
            profile: ({ event, context: { profile } }) => {
              if (env.FEATUREFLAG_WALLET_ADDRESS_API_ONLY) {
                return profile;
              }

              if (!profile) {
                return;
              }

              return {
                ...profile,
                user_metadata: {
                  ...profile.user_metadata,
                  ...event.value
                }
              };
            },
            updatedMetaData: ({ event }) => {
              if (!env.FEATUREFLAG_WALLET_ADDRESS_API_ONLY) {
                return;
              }
              return event.value;
            }
          }),
          target: AuthStateType.SUBMITTING_METADATA
        }
      }
    },
    [AuthStateType.LOGOUT]: {
      invoke: {
        src: "logout",
        input: ({ context }) => {
          const { logoutRedirectUrl } = context;

          return {
            logoutRedirectUrl
          };
        },
        onDone: {
          target: AuthStateType.UNAUTHORISED,
          actions: ["reset"]
        },
        onError: {
          target: AuthStateType.UNAUTHORISED,
          actions: assign({
            failedError: ({ event }) => {
              console.log("logout failed", event.error);

              return (event.error as Error).message;
            }
          })
        }
      }
    },
    [AuthStateType.UNAUTHORISED]: {
      entry: assign({
        isLoggedIn: false,
        isInitialised: true
      }),
      always: {
        actions: ["persist"]
      },
      on: {
        [AuthEventType.SET_SSO_TYPE]: {
          target: AuthStateType.LOGIN_WITH_SSO,
          actions: assign({
            ssoType: ({ event }) => {
              return event.value;
            }
          })
        }
      }
    },
    [AuthStateType.LOGIN_WITH_SSO]: {
      invoke: {
        src: "loginWithSSO",
        input: ({ context }) => {
          const { ssoType } = context;

          return {
            ssoType
          };
        },
        onDone: {
          target: AuthStateType.UNAUTHORISED
        },
        onError: {
          target: AuthStateType.UNAUTHORISED,
          actions: assign({
            failedError: ({ event }) => {
              console.log("loginWithSSO failed", event.error);

              return (event.error as Error).message;
            }
          })
        }
      }
    },
    [AuthStateType.CHECK_IF_LOGGED_IN]: {
      invoke: {
        src: "checkIfLoggedIn",
        input: ({ context }) => {
          const { token, profile } = context;

          return {
            token,
            profile
          };
        },
        onDone: {
          target: AuthStateType.CHECK_IF_LOGGED_IN_COMPLETE,
          actions: assign({
            failedError: undefined,
            token: ({ event }) => {
              const token = event.output.token;

              return token;
            },
            isLoggedIn: ({ event }) => {
              return event.output.isLoggedIn;
            },
            userId: ({ event, context }) => {
              // const { profile } = event.output;
              // if (!profile) {
              //   return context.userId;
              // }
              // return profile?.["https://io.net/user"]?.io_id;

              const { userId } = event.output;
              if (!userId) {
                return context.userId;
              }
              return userId;
            },
            authUserId: ({ event, context }) => {
              const { profile } = event.output;
              if (!profile) {
                return context.authUserId;
              }
              return profile?.sub;
            },
            wantsToConnectDevice: false,
            wantsToDeployCluster: false,
            ...(RESET_USER_ON_LOGIN
              ? {
                  skippedEnterPhone: false,
                  skippedEnterRole: false,
                  skippedEnterWallet: false
                }
              : {})
          })
        },
        onError: {
          target: AuthStateType.UNAUTHORISED,
          actions: assign({
            failedError: ({ event }) => {
              console.log("checkIfLoggedIn failed", event.error);

              return (event.error as Error).message;
            }
          })
        }
      }
    },
    [AuthStateType.CHECK_IF_LOGGED_IN_COMPLETE]: {
      always: [
        {
          target: AuthStateType.REFRESH_USER_META_DATA,
          guard: "isLoggedIn"
        },
        {
          target: AuthStateType.UNAUTHORISED
        }
      ]
    },
    [AuthStateType.REFRESH_USER_META_DATA]: {
      invoke: {
        src: "refreshMetaData",
        input: ({ context }) => {
          const { authUserId, token, profile } = context;

          return {
            authUserId,
            token,
            profile
          };
        },
        onDone: {
          target: AuthStateType.FETCH_USER_INTERNAL_ID,
          actions: assign({
            profile: ({ event }) => {
              return event.output.profile;
            }
          })
        },
        onError: {
          target: AuthStateType.UNAUTHORISED,
          actions: assign({
            failedError: ({ event }) => {
              console.log("refreshMetaData failed", event.error);

              return (event.error as Error).message;
            }
          })
        }
      }
    },
    [AuthStateType.FETCH_USER_INTERNAL_ID]: {
      invoke: {
        src: "fetchUserInternalId",
        input: ({ context }) => {
          const { userId, profile, internalUserId } = context;

          return {
            userId,
            profile,
            internalUserId
          };
        },
        onDone: {
          target: AuthStateType.RESET_USER,
          actions: assign({
            isInitialised: true,
            internalUserId: ({ event }) => {
              return event.output.internalUserId;
            },
            profile: ({ event, context: { profile } }) => {
              if (event.output.profile) {
                return event.output.profile;
              }
              return profile;
            }
          })
        },
        onError: {
          target: AuthStateType.UNAUTHORISED,
          actions: assign({
            failedError: ({ event }) => {
              console.log("fetchUserInternalId failed", event.error);

              return (event.error as Error).message;
            },
            internalUserId: undefined
          })
        }
      }
    },
    [AuthStateType.RESET_USER]: {
      always: [
        {
          guard: "shouldResetUser",
          actions: assign({
            skippedEnterPhone: undefined,
            skippedEnterRole: undefined,
            skippedEnterWalletType: undefined,
            skippedEnterWalletAddress: undefined
          }),
          target: INCLUDE_PHONE_VERIFICATION_STEP
            ? AuthStateType.ENTER_PHONE_NUMBER
            : AuthStateType.ENTER_ROLE
        },
        {
          target: INCLUDE_PHONE_VERIFICATION_STEP
            ? AuthStateType.ENTER_PHONE_NUMBER
            : AuthStateType.ENTER_ROLE
        }
      ]
    },
    [AuthStateType.ENTER_PHONE_NUMBER]: {
      entry: ["persist"],
      always: [
        {
          target: AuthStateType.ENTER_PHONE_CODE,
          guard: "shouldSkipPhoneNumberStep"
        }
      ],
      on: {
        [AuthEventType.SET_PHONE_NUMBER]: {
          actions: assign({
            failedError: undefined,
            profile: ({ event, context: { profile } }) => {
              if (!profile) {
                return;
              }

              const { code, number } = event.value;

              return {
                ...profile,
                user_metadata: {
                  ...profile.user_metadata,
                  phone_number_code: code,
                  phone_number: number
                }
              };
            }
          }),
          target: AuthStateType.SUBMITTING_PHONE_NUMBER
        },
        [AuthEventType.SKIP_STEP]: {
          actions: assign({
            failedError: undefined,
            profile: ({ context: { profile } }) => {
              if (!profile) {
                return;
              }

              return {
                ...profile,
                user_metadata: {
                  ...profile.user_metadata,
                  phone_number_code: "",
                  phone_number: ""
                }
              };
            },
            skippedEnterPhone: true
          }),
          target: AuthStateType.SUBMITTING_PHONE_CODE
        },
        [AuthEventType.PREVIOUS_STEP]: {
          target: AuthStateType.LOGOUT
        }
      }
    },
    [AuthStateType.SUBMITTING_PHONE_NUMBER]: {
      invoke: {
        src: "sendPhoneVerificationCode",
        input: ({ context }) => {
          return context;
        },
        onDone: {
          target: AuthStateType.ENTER_PHONE_CODE,
          actions: assign({
            profile: ({ event }) => {
              return event.output.profile;
            },
            skippedEnterPhone: undefined
          })
        },
        onError: {
          target: AuthStateType.ENTER_PHONE_NUMBER,
          actions: assign({
            failedError: ({ event }) => {
              console.log("updateMetaData failed", event.error);

              return (event.error as Error).message;
            }
          })
        }
      }
    },
    [AuthStateType.ENTER_PHONE_CODE]: {
      entry: ["persist"],
      always: [
        {
          target: AuthStateType.ENTER_ROLE,
          guard: "shouldSkipPhoneCodeStep"
        }
      ],
      on: {
        [AuthEventType.SET_PHONE_CODE]: {
          actions: assign({
            failedError: undefined,
            profile: ({ event, context: { profile } }) => {
              if (!profile) {
                return;
              }

              return {
                ...profile,
                user_metadata: {
                  ...profile.user_metadata,
                  phone_code: event.value
                }
              };
            }
          }),
          target: AuthStateType.SUBMITTING_PHONE_CODE
        },
        [AuthEventType.PREVIOUS_STEP]: {
          target: AuthStateType.ENTER_PHONE_NUMBER
        },
        [AuthEventType.SKIP_STEP]: {
          actions: assign({
            failedError: undefined,
            profile: ({ context: { profile } }) => {
              if (!profile) {
                return;
              }

              return {
                ...profile,
                user_metadata: {
                  ...profile.user_metadata,
                  phone_number_code: "",
                  phone_number: ""
                }
              };
            },
            skippedEnterPhone: true
          }),
          target: AuthStateType.SUBMITTING_PHONE_CODE
        }
      }
    },
    [AuthStateType.SUBMITTING_PHONE_CODE]: {
      invoke: {
        src: "validatePhoneCode",
        input: ({ context }) => {
          return context;
        },
        onDone: [
          {
            guard: "wantsToDeployCluster",
            target: AuthStateType.DEPLOY_CLUSTER,
            actions: assign({
              profile: ({ event }) => {
                return event.output.profile;
              },
              wantsToDeployCluster: false,
              skippedEnterPhone: undefined
            })
          },
          {
            target: AuthStateType.ENTER_ROLE,
            actions: assign({
              skippedEnterPhone: undefined
            })
          }
        ],
        onError: {
          target: AuthStateType.ENTER_PHONE_CODE,
          actions: assign({
            failedError: ({ event }) => {
              console.log("validatePhoneCode failed", event.error);

              return (event.error as Error).message;
            }
          })
        }
      }
    },
    [AuthStateType.ENTER_ROLE]: {
      entry: ["persist"],
      always: [
        {
          target: AuthStateType.ENTER_WALLET_TYPE,
          guard: "shouldSkipRoleStep"
        }
      ],
      on: {
        [AuthEventType.PREVIOUS_STEP]: {},
        [AuthEventType.SET_ROLE]: {
          actions: assign({
            failedError: undefined,
            profile: ({ event, context: { profile } }) => {
              if (!profile) {
                return;
              }

              return {
                ...profile,
                user_metadata: {
                  ...profile.user_metadata,
                  role: event.value
                }
              };
            },
            skippedEnterRole: true
          }),
          target: AuthStateType.SUBMITTING_ROLE
        },
        [AuthEventType.SKIP_STEP]: {
          actions: assign({
            failedError: undefined,
            profile: ({ context: { profile } }) => {
              if (!profile) {
                return;
              }

              return {
                ...profile,
                user_metadata: {
                  ...profile.user_metadata,
                  role: ""
                }
              };
            },
            skippedEnterRole: true
          }),
          target: AuthStateType.SUBMITTING_ROLE
        }
      }
    },
    [AuthStateType.SUBMITTING_ROLE]: {
      invoke: {
        src: "updateMetaData",
        input: ({ context }) => {
          return context;
        },
        onDone: {
          target: AuthStateType.ENTER_WALLET_TYPE,
          actions: assign({
            profile: ({ event }) => {
              return event.output.profile;
            },
            skippedEnterRole: undefined
          })
        },
        onError: {
          target: AuthStateType.ENTER_ROLE,
          actions: assign({
            failedError: ({ event }) => {
              console.log("updateMetaData failed", event.error);

              return (event.error as Error).message;
            }
          })
        }
      }
    },
    [AuthStateType.ENTER_WALLET_TYPE]: {
      entry: ["persist"],
      always: [
        {
          target: AuthStateType.ENTER_WALLET_ADDRESS,
          guard: "shouldSkipWalletTypeStep"
        }
      ],
      on: {
        [AuthEventType.SET_WALLET_TYPE]: {
          actions: assign({
            failedError: undefined,
            profile: ({ event, context: { profile } }) => {
              if (!profile) {
                return;
              }

              return {
                ...profile,
                user_metadata: {
                  ...profile.user_metadata,
                  wallet_type: event.value
                }
              };
            },
            skippedEnterWalletType: undefined
          }),
          target: AuthStateType.SUBMITTING_WALLET_TYPE
        },
        [AuthEventType.SKIP_STEP]: [
          {
            guard: "wantsToConnectDevice",
            actions: assign({
              skippedEnterWalletType: true,
              skippedEnterWalletAddress: true
            }),
            target: AuthStateType.CONNECT_DEVICE
          },
          {
            actions: assign({
              failedError: undefined,
              profile: ({ context: { profile } }) => {
                if (!profile) {
                  return;
                }

                return {
                  ...profile,
                  user_metadata: {
                    ...profile.user_metadata,
                    wallet_type: "",
                    wallet_address: ""
                  }
                };
              },
              skippedEnterWalletType: true,
              skippedEnterWalletAddress: true
            }),
            target: AuthStateType.SUBMITTING_WALLET_ADDRESS
          }
        ],
        [AuthEventType.PREVIOUS_STEP]: {
          actions: assign({
            skippedEnterRole: false
          }),
          target: AuthStateType.ENTER_ROLE
        }
      }
    },
    [AuthStateType.SUBMITTING_WALLET_TYPE]: {
      invoke: {
        src: "updateMetaData",
        input: ({ context }) => {
          return context;
        },
        onDone: {
          target: AuthStateType.ENTER_WALLET_ADDRESS,
          actions: assign({
            profile: ({ event }) => {
              return event.output.profile;
            },
            skippedEnterWalletType: undefined
          })
        },
        onError: {
          target: AuthStateType.ENTER_WALLET_TYPE,
          actions: assign({
            failedError: ({ event }) => {
              console.log("updateMetaData failed", event.error);

              return (event.error as Error).message;
            }
          })
        }
      }
    },
    [AuthStateType.ENTER_WALLET_ADDRESS]: {
      entry: ["persist"],
      always: [
        {
          target: AuthStateType.REDIRECT,
          guard: "shouldSkipWalletAddressStep"
        }
      ],
      on: {
        [AuthEventType.SET_WALLET_ADDRESS]: {
          actions: assign({
            failedError: undefined,
            profile: ({ event, context: { profile } }) => {
              if (!profile) {
                return;
              }

              const { user_metadata } = profile;

              return {
                ...profile,
                user_metadata: {
                  ...user_metadata,
                  wallet_address: event.value,
                  [`wallet_address_${user_metadata.wallet_type}`]: event.value
                }
              };
            },
            skippedEnterWalletAddress: undefined
          }),
          target: AuthStateType.SUBMITTING_WALLET_ADDRESS
        },
        [AuthEventType.SKIP_STEP]: {
          actions: assign({
            failedError: undefined,
            profile: ({ context: { profile } }) => {
              if (!profile) {
                return;
              }

              return {
                ...profile,
                user_metadata: {
                  ...profile.user_metadata,
                  wallet_address: ""
                }
              };
            },
            skippedEnterWalletAddress: true
          }),
          target: AuthStateType.SUBMITTING_WALLET_ADDRESS
        },
        [AuthEventType.PREVIOUS_STEP]: {
          actions: assign({
            skippedEnterWalletType: false
          }),
          target: AuthStateType.ENTER_WALLET_TYPE
        }
      }
    },
    [AuthStateType.SUBMITTING_WALLET_ADDRESS]: {
      invoke: {
        src: "updateMetaData",
        input: ({ context }) => {
          return context;
        },
        onDone: [
          {
            guard: "wantsToConnectDevice",
            target: AuthStateType.CONNECT_DEVICE,
            actions: assign({
              profile: ({ event }) => {
                return event.output.profile;
              },
              skippedEnterWalletAddress: undefined
            })
          },
          {
            target: AuthStateType.REDIRECT,
            actions: assign({
              profile: ({ event }) => {
                return event.output.profile;
              },
              skippedEnterWalletAddress: undefined
            })
          }
        ],
        onError: {
          target: AuthStateType.ENTER_WALLET_ADDRESS,
          actions: assign({
            failedError: ({ event }) => {
              console.log("updateMetaData failed", event.error);

              return (event.error as Error).message;
            }
          })
        }
      }
    },
    [AuthStateType.CONNECT_DEVICE]: {
      always: [
        {
          guard: "canNotConnectDevice",
          target: AuthStateType.ENTER_WALLET_TYPE,
          actions: assign({
            wantsToConnectDevice: true
          })
        },
        {
          guard: "wantsToConnectDevice",
          actions: assign({
            wantsToConnectDevice: false
          })
        }
      ],
      on: {
        [AuthEventType.EXIT_PROCESS]: {
          target: AuthStateType.REDIRECT
        }
      }
    },
    [AuthStateType.DEPLOY_CLUSTER]: {
      always: [
        {
          guard: "canNotDeployCluster",
          target: AuthStateType.ENTER_PHONE_NUMBER,
          actions: assign({
            wantsToDeployCluster: true
          })
        },
        {
          guard: "wantsToDeployCluster",
          actions: assign({
            wantsToDeployCluster: false
          })
        }
      ],
      on: {
        [AuthEventType.DEPLOY_CLUSTER]: {
          actions: assign({
            clusterType: ({ event }) => {
              return event.value;
            }
          })
        },
        [AuthEventType.EXIT_PROCESS]: {
          target: AuthStateType.REDIRECT
        }
      }
    },
    [AuthStateType.SUBMITTING_METADATA]: {
      invoke: {
        src: "updateMetaData",
        input: ({ context }) => {
          return {
            ...context
          };
        },
        onDone: {
          target: AuthStateType.REDIRECT,
          actions: assign({
            profile: ({ event }) => {
              return event.output.profile;
            },
            updatedMetaData: undefined
          })
        },
        onError: {
          target: AuthStateType.REDIRECT,
          actions: assign({
            failedError: ({ event }) => {
              console.log("updateMetaData failed", event.error);

              return (event.error as Error).message;
            },
            updatedMetaData: undefined
          })
        }
      }
    }
  }
});

export const PERSISTED_STATE_KEY = toStringWithLoginVersionSuffix("authMachinePersistedState");

let restoredSnapshot: ReturnType<typeof authMachine.resolveState> | null = null;
let restoreEvent: undefined | { type: AuthEventType.CHECK_IF_LOGGED_IN };

try {
  const storedContext: AuthContext = JSON.parse(
    localCookies.getItem(PERSISTED_STATE_KEY) as string
  );

  if (storedContext) {
    restoredSnapshot = authMachine.resolveState({
      value: AuthStateType.IDLE,
      context: {
        ...storedContext,
        isInitialised: false
      }
    });
  }
  restoreEvent = { type: AuthEventType.CHECK_IF_LOGGED_IN };
} catch (e) {
  //
}

export const AuthMachineContext = createActorContext(authMachine, {
  systemId: "authMachineMachine",
  ...(restoredSnapshot
    ? {
        snapshot: restoredSnapshot
      }
    : {})
});

let globalActor: ReturnType<typeof AuthMachineContext.useActorRef> | null = null;

export const restoreMachine = (actor: ReturnType<typeof AuthMachineContext.useActorRef>) => {
  globalActor = actor;
  if (!restoreEvent) {
    return;
  }
  actor.send(restoreEvent);
};

export const getAuthActor = () => {
  return globalActor!;
};

export const getAuthContext = () => {
  // eslint-disable-next-line @typescript-eslint/no-non-null-asserted-optional-chain
  return globalActor?.getSnapshot().context!;
};

import { IconProps } from ".";

export const ErrorCircleIcon: React.FC<IconProps> = (props) => {
  return (
    <svg viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M8 0C3.55556 0 0 3.55556 0 8C0 12.4444 3.55556 16 8 16C12.4444 16 16 12.4444 16 8C16 3.55556 12.4444 0 8 0ZM11.1111 12L4 4.88889L4.88889 4L12 11.1111L11.1111 12Z"
        fill="currentColor"
      />
    </svg>
  );
};

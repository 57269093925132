import { reuleaux } from "ldrs";
import { useMemo } from "react";

reuleaux.register();

type LoaderProps = {
  className?: string;
  color?: string;
};

export const Loader: React.FC<LoaderProps> = ({ className = "", color = "#000" }) => {
  const size = useMemo(() => {
    const match = /w\-\[([0-9]+)px\]/gm.exec(className);

    if (!Array.isArray(match)) {
      return DEFAULT_SIZE;
    }
    const parsedSize = parseInt(match[1], 10);

    if (isNaN(parsedSize)) {
      return DEFAULT_SIZE;
    }
    return parsedSize;
  }, [className]);

  return (
    <l-reuleaux
      size={size}
      stroke="5"
      stroke-length="0.15"
      bg-opacity="0.1"
      speed="0.7"
      color={color}
      data-testid="Loader"
    ></l-reuleaux>
  );
};

const DEFAULT_SIZE = 40;

import { IconProps } from ".";

export const WorkerIcon: React.FC<IconProps> = (props) => {
  return (
    <svg viewBox="0 0 78 8" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M11.4955 7.9994H8.97062C7.93101 7.9994 7.2229 7.5892 6.90186 6.83253C6.57058 7.58667 5.87246 7.9994 4.83308 7.9994H2.30821C0.83295 7.9994 0 7.16373 0 5.69112V0.195602C0 0.0707411 0.0685847 0.00195312 0.206208 0.00195312H0.675096C0.799933 0.00195312 0.868746 0.0707411 0.868746 0.195602V5.63507C0.868746 6.67456 1.42924 7.24526 2.46862 7.24526H4.86865C5.90826 7.24526 6.46875 6.67456 6.46875 5.63507V0.195602C6.46875 0.0707411 6.53757 0.00195312 6.6624 0.00195312H7.14129C7.26613 0.00195312 7.33495 0.0707411 7.33495 0.195602V5.63507C7.33495 6.67456 7.89288 7.24526 8.93482 7.24526H11.3349C12.3745 7.24526 12.935 6.67456 12.935 5.63507V0.195602C12.935 0.0707411 13.0038 0.00195312 13.1312 0.00195312H13.5998C13.7374 0.00195312 13.8063 0.0707411 13.8063 0.195602V5.69112C13.8037 7.16373 12.9705 7.9994 11.4955 7.9994Z"
        fill="currentColor"
      />
      <path
        d="M16.6855 5.69112V2.31023C16.6855 0.837619 17.5085 0.00195312 18.981 0.00195312H24.3873C25.86 0.00195312 26.6958 0.837619 26.6958 2.31023V5.69112C26.6958 7.16373 25.8626 7.9994 24.3873 7.9994H18.981C17.5059 7.9994 16.6855 7.16373 16.6855 5.69112ZM25.827 5.63253V2.36373C25.827 1.32425 25.2689 0.753557 24.2269 0.753557H19.1544C18.102 0.753557 17.5415 1.32425 17.5415 2.36373V5.63253C17.5415 6.67201 18.102 7.2427 19.1544 7.2427H24.2269C25.2663 7.24526 25.827 6.67201 25.827 5.63253Z"
        fill="currentColor"
      />
      <path
        d="M30.2496 7.9994H29.7704C29.643 7.9994 29.5742 7.93061 29.5742 7.80577V0.195602C29.5742 0.0707411 29.643 0.00195312 29.7704 0.00195312H36.6265C38.2266 0.00195312 39.0953 0.562465 39.0953 2.13953V2.48094C39.0953 3.60196 38.6495 4.2185 37.8035 4.4682C38.6495 4.73061 39.0953 5.33699 39.0953 6.45546V7.80321C39.0953 7.92805 39.0265 7.99684 38.9017 7.99684H38.4328C38.3079 7.99684 38.2391 7.92805 38.2391 7.80321V6.35099C38.2391 5.31151 37.7475 4.84271 36.4786 4.84271H30.4455V7.80321C30.4432 7.93061 30.3744 7.9994 30.2496 7.9994ZM38.2368 2.19304C38.2368 1.19941 37.7679 0.753557 36.5449 0.753557H30.443V4.09113H36.5449C37.7679 4.09113 38.2368 3.65545 38.2368 2.65164V2.19304Z"
        fill="currentColor"
      />
      <path
        d="M42.8436 3.62293H47.129L51.1059 0.114655C51.1977 0.0356845 51.2537 0 51.4016 0H52.0181C52.1531 0 52.1887 0.1376 52.0971 0.22931L47.8448 3.97708L52.1404 7.77069C52.232 7.86242 52.199 8 52.0613 8H51.4092C51.2486 8 51.1926 7.96434 51.1008 7.88535L47.1239 4.37707H42.8385V7.80382C42.8385 7.92865 42.7697 7.99744 42.6423 7.99744H42.1634C42.0385 7.99744 41.9697 7.92865 41.9697 7.80382V0.193649C41.9697 0.0688116 42.0385 0 42.1634 0H42.6423C42.7697 0 42.8385 0.0688116 42.8385 0.193649V3.62293H42.8436Z"
        fill="currentColor"
      />
      <path
        d="M55.9282 5.63311C55.9282 6.67261 56.4887 7.2433 57.5411 7.2433H64.4302C64.5576 7.2433 64.6238 7.31209 64.6238 7.43693V7.80382C64.6238 7.92865 64.555 7.99744 64.4302 7.99744H57.3677C55.8952 7.99744 55.0723 7.16178 55.0723 5.68918V2.30828C55.0723 0.835666 55.8952 0 57.3677 0H64.4302C64.5576 0 64.6238 0.0688116 64.6238 0.193649V0.560512C64.6238 0.68535 64.555 0.754161 64.4302 0.754161H57.5411C56.4887 0.754161 55.9282 1.32486 55.9282 2.36433V3.6204H63.5156C63.6405 3.6204 63.7093 3.68918 63.7093 3.81402V4.18089C63.7093 4.30572 63.6405 4.37453 63.5156 4.37453H55.9282V5.63311Z"
        fill="currentColor"
      />
      <path
        d="M68.1786 7.9994H67.6995C67.5747 7.9994 67.5059 7.93061 67.5059 7.80577V0.195602C67.5059 0.0707411 67.5747 0.00195312 67.6995 0.00195312H74.5556C76.1557 0.00195312 77.0218 0.562465 77.0218 2.13953V2.48094C77.0218 3.60196 76.576 4.2185 75.7302 4.4682C76.576 4.73061 77.0218 5.33699 77.0218 6.45546V7.80321C77.0218 7.92805 76.953 7.99684 76.8282 7.99684H76.3595C76.2347 7.99684 76.1659 7.92805 76.1659 7.80321V6.35099C76.1659 5.31151 75.674 4.84271 74.4054 4.84271H68.3723V7.80321C68.3723 7.93061 68.3035 7.9994 68.1786 7.9994ZM76.1659 2.19304C76.1659 1.19941 75.697 0.753557 74.4742 0.753557H68.3723V4.09113H74.4742C75.697 4.09113 76.1659 3.65545 76.1659 2.65164V2.19304Z"
        fill="currentColor"
      />
    </svg>
  );
};

import { IconProps } from "@/components/Icons/index";

export const DeployClusterIcon: React.FC<IconProps> = (props) => (
  <svg viewBox="0 0 42 42" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M21.0602 17.1786L23.7754 15.7481L23.7405 15.7113C24.6135 15.2513 25.6612 15.6193 26.0977 16.5392C26.5343 17.4592 26.185 18.5631 25.3294 19.0231L22.7976 20.357V23.8252C22.7976 24.8371 22.0118 25.6651 21.0514 25.6651C20.0736 25.6651 19.3053 24.8371 19.3053 23.8252V20.3662L16.756 19.0231C15.9004 18.5631 15.5512 17.4592 15.9877 16.5576C16.4242 15.6561 17.4719 15.2881 18.345 15.7481L21.0602 17.1786Z"
      fill="white"
    />
    <path
      d="M21.8023 2.83199L25.2945 4.67188L25.2596 4.63509C26.1152 5.09506 26.4644 6.199 26.0454 7.11895C25.6088 8.02049 24.5612 8.38847 23.7056 7.9469L20.9991 6.52098L18.2926 7.9469C17.4195 8.40687 16.3719 8.03889 15.9528 7.13734C15.5163 6.2174 15.8655 5.11346 16.7385 4.67188L20.2308 2.83199C20.7197 2.556 21.2959 2.556 21.8023 2.83199Z"
      fill="white"
    />
    <path
      d="M18.2751 34.0734L19.2355 34.5794V32.9327C19.2355 31.9023 20.0038 31.0928 20.9816 31.0928C21.942 31.0928 22.7277 31.9023 22.7277 32.9327V34.5334L23.6706 34.0366C24.5437 33.5766 25.5914 33.9446 26.0279 34.8646C26.4644 35.7845 26.1152 36.8885 25.2596 37.3484L21.7674 39.1883L21.7585 39.179C21.5512 39.2884 21.3207 39.3555 21.0773 39.3696C20.7763 39.3939 20.4665 39.337 20.1784 39.1883L16.6862 37.3484C15.8306 36.8885 15.4813 35.7845 15.9179 34.883C16.3544 33.9814 17.4021 33.6134 18.2751 34.0734Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M33.9978 10.3395L31.11 11.8609L30.7084 11.6493C29.8528 11.1894 29.5036 10.0854 29.9401 9.18387C30.3767 8.28232 31.4243 7.91434 32.2974 8.37432L35.4897 10.0562C35.9375 10.1969 36.3306 10.5263 36.5579 11.0054C36.5693 11.0293 36.5802 11.0535 36.5905 11.0777C36.6928 11.3119 36.75 11.5738 36.75 11.8517V16.4515C36.75 17.4634 35.9642 18.2914 35.0039 18.2914C34.0261 18.2914 33.2578 17.4634 33.2578 16.4515V14.8232L32.2974 15.3291C31.4243 15.7891 30.3767 15.4211 29.9576 14.5196C29.5211 13.5996 29.8703 12.4957 30.7433 12.0541L31.11 11.8609L33.2578 12.9925V11.8517C33.2578 11.2189 33.5476 10.6693 33.9978 10.3395ZM8.77716 14.7956V16.4147C8.77716 17.4266 7.99141 18.2546 7.03104 18.2546C6.05322 18.2546 5.28492 17.4266 5.28492 16.4147V12.0213C5.25899 11.8057 5.26932 11.584 5.31935 11.3666C5.39032 11.0545 5.53888 10.7646 5.7547 10.5302C5.92982 10.3375 6.14071 10.1896 6.37075 10.0929L9.73753 8.31912C10.6106 7.85914 11.6583 8.22712 12.0948 9.14707C12.5313 10.067 12.1821 11.171 11.3265 11.6309L10.9685 11.8195L11.309 11.9989C12.1646 12.4589 12.5139 13.5628 12.0948 14.4828C11.6583 15.3843 10.6106 15.7523 9.75499 15.3107L8.77716 14.7956ZM8.74224 29.0271L11.2741 30.3611L11.2567 30.3243C12.1123 30.7842 12.4615 31.8882 12.0424 32.8081C11.6059 33.7097 10.5582 34.0777 9.7026 33.6361L6.21037 31.7962C5.61669 31.4834 5.25 30.8394 5.25 30.1587V25.5589C5.25 24.5286 6.01829 23.719 6.99612 23.719C7.95649 23.719 8.74224 24.5286 8.74224 25.5589V29.0271ZM35.7198 31.7962L32.2276 33.6361L32.2101 33.5993C31.337 34.0593 30.2894 33.6913 29.8703 32.7897C29.4338 31.8698 29.783 30.7658 30.656 30.3243L33.1879 28.9903V25.5405C33.1879 24.5102 33.9562 23.7006 34.934 23.7006C35.8944 23.7006 36.6802 24.5102 36.6802 25.5405V30.1403C36.6802 30.8394 36.296 31.4834 35.7198 31.7962Z"
      fill="#999999"
    />
    <path
      d="M33.9978 10.3395L31.11 11.8609L33.2578 12.9925V11.8517C33.2578 11.2189 33.5476 10.6693 33.9978 10.3395Z"
      fill="#999999"
    />
  </svg>
);

import { IconProps } from ".";

export const LogOutIcon: React.FC<IconProps> = (props) => {
  return (
    <svg viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.91667 2.1875C2.72328 2.1875 2.53781 2.26432 2.40107 2.40107C2.26432 2.53781 2.1875 2.72328 2.1875 2.91667V11.0833C2.1875 11.2767 2.26432 11.4622 2.40107 11.5989C2.53781 11.7357 2.72328 11.8125 2.91667 11.8125H5.25C5.49162 11.8125 5.6875 12.0084 5.6875 12.25C5.6875 12.4916 5.49162 12.6875 5.25 12.6875H2.91667C2.49121 12.6875 2.08319 12.5185 1.78235 12.2177C1.48151 11.9168 1.3125 11.5088 1.3125 11.0833V2.91667C1.3125 2.49122 1.48151 2.08319 1.78235 1.78235C2.08319 1.48151 2.49122 1.3125 2.91667 1.3125H5.25C5.49162 1.3125 5.6875 1.50838 5.6875 1.75C5.6875 1.99162 5.49162 2.1875 5.25 2.1875H2.91667ZM9.02397 3.77397C9.19483 3.60312 9.47184 3.60312 9.64269 3.77397L12.5594 6.69064C12.7302 6.86149 12.7302 7.1385 12.5594 7.30936L9.64269 10.226C9.47184 10.3969 9.19483 10.3969 9.02397 10.226C8.85312 10.0552 8.85312 9.77816 9.02397 9.60731L11.1938 7.4375H5.25C5.00838 7.4375 4.8125 7.24162 4.8125 7C4.8125 6.75838 5.00838 6.5625 5.25 6.5625H11.1938L9.02397 4.39269C8.85312 4.22184 8.85312 3.94483 9.02397 3.77397Z"
        fill="currentColor"
      />
    </svg>
  );
};

import { useUserStore } from "@/store/user";
import { useCallback, useState } from "react";
import { BellIcon } from "@/components/Icons/BellIcon";
import { Button } from "@/components/ui/button";
import { NOTIFICATION_MAP } from "@/utils/mapping";
import { AsyncSuspense } from "../AsyncSuspense/AsyncSuspense";
import { Popover, PopoverContent, PopoverTrigger } from "@/components/ui/popover";
import { NotificationType } from "@/types/user";
import { UserNotificationResults } from "../UserNotificationResults/UserNotificationResults";
import { useToast } from "@/components/ui/use-toast";
import { cn } from "@/lib/utils";

export const UserNotificationsButton = () => {
  const [open, setOpen] = useState(false);
  const fetchNotificationCount = useUserStore((store) => store.fetchNotificationCount);
  const [fetchNotificationsError, setFetchNotificationsError] = useState<Error>();
  const [notificationCount, setNotificationCount] = useState<number>();
  const removeAllNotifications = useUserStore((store) => store.removeAllNotifications);
  const [activeTab, setActiveTab] = useState(NotificationType.SUCCESS);
  const { toast } = useToast();
  const [isRemovingNotifications, setIsRemovingNotifications] = useState(false);

  const refreshNotifications = useCallback(async () => {
    try {
      setNotificationCount(undefined);

      const newNotificationCount = await fetchNotificationCount();
      setNotificationCount(newNotificationCount);
      setFetchNotificationsError(undefined);
    } catch (e) {
      if (e instanceof Error) {
        setFetchNotificationsError(e);
      }
    }
  }, [fetchNotificationCount]);

  const handleRemoveAll = async () => {
    try {
      setIsRemovingNotifications(true);

      await removeAllNotifications();
      await refreshNotifications();

      toast({ title: "All notifications removed", variant: "error" });
    } catch (e) {
      if (e instanceof Error) {
        toast({ title: e.message, variant: "error" });
      }
    } finally {
      setIsRemovingNotifications(false);
    }
  };

  return (
    <Popover
      open={open}
      onOpenChange={(open) => {
        setOpen(open);

        if (open) {
          refreshNotifications();
        }
      }}
    >
      <PopoverTrigger asChild>
        <button
          data-testid={"UserNotificationsButton_trigger"}
          className="flex flex-row items-center gap-2 text-sm text-gray focus:outline-none"
        >
          <div className="relative flex h-[32px] w-[32px] items-center justify-center rounded-full border border-gray-dark-1200">
            <BellIcon
              data-testid={"UserNotificationsButton_bellIcon"}
              className={`${open ? `text-white` : `text-gray-dark-1000`} h-[18px]`}
            />
            {(notificationCount || 0) > 0 && (
              <div className="absolute bottom-[-2px] right-[-2px] h-[12px] w-[12px] rounded-full border-2 border-black bg-red-light-800"></div>
            )}
          </div>
        </button>
      </PopoverTrigger>
      <PopoverContent
        className="flex min-h-[260px] w-auto flex-col rounded border border-gray-dark-900 p-0"
        align="end"
      >
        <div
          className="flex w-screen flex-1 flex-col overflow-hidden rounded bg-white dark:border dark:border-gray-dark-900 dark:bg-black md:w-[415px]"
          style={{
            boxShadow:
              "0px 8px 8px -4px rgba(16, 24, 40, 0.03), 0px 10px 24px -4px rgba(16, 24, 40, 0.16)"
          }}
        >
          <AsyncSuspense
            error={fetchNotificationsError}
            isLoading={notificationCount === undefined || isRemovingNotifications}
          >
            {typeof notificationCount === "number" && (
              <>
                <div className="flex flex-row items-center justify-between border-b border-b-gray-dark-900 p-4">
                  <h3>Notifications ({notificationCount})</h3>
                  {notificationCount > 0 && (
                    <Button
                      variant="link"
                      onClick={handleRemoveAll}
                      className={cn(
                        "h-auto gap-2 p-0 text-sm text-gray-dark-1000 underline",
                        isRemovingNotifications ? "pointer-events-none" : ""
                      )}
                    >
                      <span>Clear All</span>
                    </Button>
                  )}
                </div>
                <div className="p-4">
                  <div className="mb-4 flex flex-row justify-stretch gap-1 rounded-lg border border-gray-dark-900 p-1">
                    {Object.keys(NOTIFICATION_MAP).map((item) => {
                      const Icon = NOTIFICATION_MAP[item].icon;
                      return (
                        <Button
                          variant="ghost"
                          key={item}
                          onClick={() => setActiveTab(item as NotificationType)}
                          className={`${item === activeTab ? "pointer-events-none bg-gray-dark-1100" : "bg-none"} flex-1 rounded`}
                        >
                          <Icon className={`mr-2 h-[16px] ${NOTIFICATION_MAP[item].color}`} />
                          <span className="text-sm capitalize text-gray-dark-1000">
                            {NOTIFICATION_MAP[item].label}
                          </span>
                        </Button>
                      );
                    })}
                  </div>
                  <UserNotificationResults type={activeTab} key={activeTab} />
                </div>
              </>
            )}
          </AsyncSuspense>
        </div>
      </PopoverContent>
    </Popover>
  );
};

import { IconProps } from ".";

export const AmdIcon: React.FC<IconProps> = (props) => {
  return (
    <svg viewBox="0 0 12 11" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M3.58573 2.99344L2.09239 1.49672L0.599052 0H6.04635H11.4937L11.4982 5.12582C11.5007 7.94503 11.5006 10.4015 11.4979 10.5848L11.4929 10.9179L9.99994 9.42172L8.50693 7.9255V5.45946V2.99344H6.04634H3.58573Z"
        fill="currentColor"
      />
      <path
        d="M0.5 6.69925L2.04291 5.15287L3.58582 3.60649L3.59038 5.76144L3.59496 7.91639H5.74952H7.90409L6.36575 9.4582L4.82741 11H2.6637H0.5V8.84963V6.69925Z"
        fill="currentColor"
      />
    </svg>
  );
};

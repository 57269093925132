import { IconProps } from ".";

export const BellIcon: React.FC<IconProps> = (props) => {
  return (
    <svg fill="none" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M14.4004 10.2667L13.0004 8.96667V6.66667C13.0004 4.06667 11.1004 1.96667 8.50041 1.66667V0.666672H7.50041V1.66667C5.00041 1.96667 3.00041 4.06667 3.00041 6.66667V8.96667L1.60041 10.2667C1.50041 10.3667 1.40041 10.4667 1.50041 10.6667V12.1667C1.50041 12.4667 1.70041 12.6667 1.90041 12.6667C1.90041 12.6667 1.90041 12.6667 2.00041 12.6667H5.50041C5.50041 14.0667 6.60041 15.1667 8.00041 15.1667C9.40041 15.1667 10.5004 14.0667 10.5004 12.6667H14.0004C14.3004 12.6667 14.5004 12.4667 14.5004 12.2667C14.5004 12.2667 14.5004 12.2667 14.5004 12.1667V10.6667C14.5004 10.5667 14.4004 10.3667 14.4004 10.2667ZM8.00041 14.1667C7.20041 14.1667 6.50041 13.4667 6.50041 12.6667H9.50041C9.50041 13.4667 8.80041 14.1667 8.00041 14.1667ZM13.5004 11.6667H2.50041V10.8667L3.80041 9.46667C3.90041 9.46667 4.00041 9.26667 4.00041 9.16667V6.66667C4.00041 4.46667 5.80041 2.66667 8.00041 2.66667C10.2004 2.66667 12.0004 4.46667 12.0004 6.66667V9.16667C12.0004 9.26667 12.1004 9.46667 12.1004 9.56667L13.5004 10.8667V11.6667Z"
        fill="currentColor"
      />
    </svg>
  );
};

import { Auth0CustomUserProfile } from "@/utils/auth0";
import { UserMetaData } from "./user";
import { ClusterType } from ".";

export type AuthContext = {
  isInitialised?: boolean;
  isLoggedIn: boolean;
  token?: string;
  userId?: string;
  authUserId?: string;
  internalUserId?: string;
  profile?: Auth0CustomUserProfile<UserMetaData>;
  updatedMetaData?: Partial<UserMetaData>;
  failedError?: string;
  ssoType?: string;
  clusterType?: ClusterType;
  skippedEnterPhone?: boolean;
  skippedEnterRole?: boolean;
  skippedEnterWalletType?: boolean;
  skippedEnterWalletAddress?: boolean;
  wantsToDeployCluster?: boolean;
  wantsToConnectDevice?: boolean;
  logoutRedirectUrl?: string;
  newDeviceId?: string;
};

export enum AuthStateType {
  IDLE = "idle",
  UNAUTHORISED = "unauthorised",
  SIGNUP = "signup",
  REDIRECT = "redirect",
  AUTHORISED = "authorised",
  LOGIN_WITH_SSO = "loginWithSSO",
  LOGIN_WITH_SSO_COMPLETE = "loginWithSSOComplete",
  CHECK_IF_LOGGED_IN = "checkIfLoggedIn",
  CHECK_IF_LOGGED_IN_COMPLETE = "checkIfLoggedInComplete",
  REFRESH_USER_META_DATA = "refreshUserMetaData",
  REFRESH_USER_META_DATA_COMPLETE = "refreshUserMetaDataComplete",
  FETCH_USER_INTERNAL_ID = "fetchUserInternalId",
  FETCH_USER_INTERNAL_ID_COMPLETE = "fetchUserInternalIdComplete",
  LOGOUT = "logout",
  LOGGING_OUT = "loggingOut",
  ENTER_PHONE_NUMBER = "enterPhoneNumber",
  SUBMITTING_PHONE_NUMBER = "submittingPhoneNumber",
  ENTER_PHONE_CODE = "enterPhoneCode",
  ENTER_ROLE = "enterRole",
  ENTER_WALLET_TYPE = "enterWalletType",
  ENTER_WALLET_ADDRESS = "enterWalletAddress",
  SUBMITTING_ROLE = "submittingRole",
  SUBMITTING_PHONE_CODE = "submittingPhoneCode",
  SUBMITTING_WALLET_TYPE = "submittingWalletType",
  SUBMITTING_WALLET_ADDRESS = "submittingWalletAddress",
  CONNECT_DEVICE = "connectDevice",
  DEPLOY_CLUSTER = "deployCluster",
  RESET_USER = "userUser",
  SUBMITTING_METADATA = "submittingMetadata"
}

export enum AuthEventType {
  AUTHORISED = "authorised",
  SIGNUP = "signup",
  SET_SSO_TYPE = "setSsoType",
  CHECK_IF_LOGGED_IN = "checkIfLoggedIn",
  LOGOUT = "logout",
  ENTER_PHONE_NUMBER = "enterPhoneNumber",
  ENTER_PHONE_CODE = "enterPhoneCode",
  ENTER_ROLE = "enterRole",
  ENTER_WALLET_TYPE = "enterWalletType",
  ENTER_WALLET_ADDRESS = "enterWalletAddress",
  PREVIOUS_STEP = "previousStep",
  SKIP_STEP = "skipStep",
  SET_PHONE_NUMBER = "setPhoneNumber",
  SET_PHONE_CODE = "setPhoneCode",
  SET_ROLE = "setRole",
  SET_WALLET_TYPE = "setWalletType",
  SET_WALLET_ADDRESS = "setWalletAddress",
  CONNECT_DEVICE = "connectDevice",
  DEPLOY_CLUSTER = "deployCluster",
  EXIT_PROCESS = "exitProcess",
  SET_METADATA = "setMetadata",
  SET_TOKEN = "setToken"
}

export type AuthEventTypes =
  | { type: AuthEventType.AUTHORISED }
  | { type: AuthEventType.SET_SSO_TYPE; value: string }
  | { type: AuthEventType.CHECK_IF_LOGGED_IN }
  | { type: AuthEventType.LOGOUT; value?: string }
  | { type: AuthEventType.SKIP_STEP }
  | { type: AuthEventType.PREVIOUS_STEP }
  | { type: AuthEventType.SET_PHONE_NUMBER; value: { code: string; number: string } }
  | { type: AuthEventType.SET_PHONE_CODE; value: string }
  | { type: AuthEventType.SET_ROLE; value: string }
  | { type: AuthEventType.SET_WALLET_TYPE; value: string }
  | { type: AuthEventType.SET_WALLET_ADDRESS; value: string }
  | { type: AuthEventType.CONNECT_DEVICE; value: string }
  | { type: AuthEventType.DEPLOY_CLUSTER; value: ClusterType }
  | { type: AuthEventType.EXIT_PROCESS }
  | { type: AuthEventType.SIGNUP }
  | {
      type: AuthEventType.SET_METADATA;
      value: Partial<UserMetaData>;
    }
  | {
      type: AuthEventType.SET_TOKEN;
      value?: string;
    };

export type AuthGuardTypes =
  | { type: "isLoggedIn" }
  | { type: "shouldSkipPhoneNumberStep" }
  | { type: "shouldSkipPhoneCodeStep" }
  | { type: "shouldSkipRoleStep" }
  | { type: "shouldSkipWalletTypeStep" }
  | { type: "shouldSkipWalletAddressStep" }
  | { type: "canConnectDevice" }
  | { type: "canDeployCluster" };

export type AuthToken = {
  iss: string;
  sub: string;
  aud: string[];
  iat: number;
  exp: number;
  scope: string;
  azp: string;
};

// TODO - Only way I could get the types to work splitting the machine into seperate files

// import {
//   ActionFunction,
//   EventObject,
//   Invert,
//   IsNever,
//   ParameterizedObject,
//   UnknownActorLogic,
//   Values
// } from "xstate";
// import { GuardPredicate } from "node_modules/xstate/dist/declarations/src/guards";

// export type AuthActorMapType = {
//   [K in keyof TActors | Values<TChildrenMap>]: K extends keyof TActors ? TActors[K] : never;
// };

// export type AuthGuardMapType = {
//   [K in keyof TGuards]: GuardPredicate<
//     AuthContext,
//     AuthEventTypes,
//     TGuards[K],
//     ToParameterizedObject<TGuards>
//   >;
// };

// export type AuthActionMapType = {
//   [K in keyof TActions]: ActionFunction<
//     AuthContext,
//     AuthEventTypes,
//     AuthEventTypes,
//     TActions[K],
//     ToProvidedActor<TChildrenMap, TActors>,
//     ToParameterizedObject<TActions>,
//     ToParameterizedObject<TGuards>,
//     TDelay,
//     TEmitted
//   >;
// };

// interface TGuards extends Record<string, ParameterizedObject["params"] | undefined> {}
// interface TActions extends Record<string, UnknownActorLogic> {}
// type TDelay = string;
// interface TActors extends Record<string, UnknownActorLogic> {}
// interface TChildrenMap extends Record<string, string> {}
// interface TEmitted extends EventObject {}

// type ToParameterizedObject<
//   TParameterizedMap extends Record<string, ParameterizedObject["params"] | undefined>
// > =
//   IsNever<TParameterizedMap> extends true
//     ? never
//     : Values<{
//         [K in keyof TParameterizedMap & string]: {
//           type: K;
//           params: TParameterizedMap[K];
//         };
//       }>;

// type ToProvidedActor<
//   TChildrenMap extends Record<string, string>,
//   TActors extends Record<string, UnknownActorLogic>
// > =
//   IsNever<TActors> extends true
//     ? never
//     : Values<{
//         [K in keyof TActors & string]: {
//           src: K;
//           logic: TActors[K];
//           id: IsNever<TChildrenMap> extends true
//             ? string | undefined
//             : K extends keyof Invert<TChildrenMap>
//               ? Invert<TChildrenMap>[K] & string
//               : string | undefined;
//         };
//       }>;

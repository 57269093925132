import { IconProps } from ".";

export const UbuntuOSIcon: React.FC<IconProps> = (props) => {
  return (
    <svg viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g clipPath="url(#clip0_496_15260)">
        <path
          d="M13.98 6.98266C13.98 10.8389 10.8538 13.9652 6.99737 13.9652C3.14081 13.9652 0.0146484 10.8389 0.0146484 6.98266C0.0146484 3.12621 3.14091 0 6.99737 0C10.8539 0 13.98 3.12621 13.98 6.98266Z"
          fill="#E95420"
        />
        <path
          d="M2.24932 6.05001C1.73421 6.05001 1.31689 6.46733 1.31689 6.98243C1.31689 7.49726 1.73421 7.91463 2.24932 7.91463C2.7642 7.91463 3.18152 7.49726 3.18152 6.98243C3.18152 6.46733 2.7642 6.05001 2.24932 6.05001ZM8.90561 10.2871C8.45974 10.5447 8.30678 11.1145 8.56435 11.5602C8.82166 12.0061 9.3915 12.1589 9.83742 11.9014C10.2833 11.6441 10.4362 11.0743 10.1787 10.6283C9.92137 10.1827 9.3512 10.0298 8.90561 10.2871ZM4.27434 6.98243C4.27434 6.06116 4.73196 5.2472 5.43213 4.75435L4.75056 3.6128C3.93495 4.15804 3.32803 4.99115 3.07581 5.96694C3.37014 6.20702 3.55848 6.5726 3.55848 6.98237C3.55848 7.39193 3.37014 7.75751 3.07581 7.99765C3.3277 8.97366 3.93462 9.80687 4.75056 10.3521L5.43213 9.21023C4.73196 8.71766 4.27434 7.90375 4.27434 6.98243ZM6.99734 4.25916C8.41993 4.25916 9.58707 5.34984 9.70957 6.74087L11.0379 6.7213C10.9726 5.69437 10.524 4.77245 9.83469 4.09455C9.48021 4.22848 9.07071 4.20797 8.71688 4.00398C8.36234 3.7994 8.14004 3.45427 8.07928 3.07966C7.72688 2.98213 7.36293 2.93265 6.99728 2.93255C6.3747 2.93191 5.76042 3.07534 5.2025 3.35162L5.85027 4.51247C6.20953 4.34525 6.60107 4.25878 6.99734 4.25916ZM6.99734 9.70548C6.60109 9.70584 6.20957 9.61937 5.85032 9.45217L5.20255 10.6129C5.7604 10.8895 6.37474 11.0331 6.99739 11.0324C7.36305 11.0323 7.727 10.9827 8.07933 10.8849C8.14009 10.5103 8.36245 10.1655 8.71688 9.9606C9.07103 9.75623 9.48026 9.73611 9.83469 9.87004C10.524 9.19213 10.9726 8.27021 11.038 7.24329L9.70929 7.22371C9.58707 8.61512 8.41998 9.70548 6.99734 9.70548ZM8.90528 3.67723C9.3512 3.9347 9.9211 3.78223 10.1783 3.3363C10.4359 2.89038 10.2833 2.32048 9.83742 2.06291C9.3915 1.80566 8.82166 1.95845 8.56403 2.40437C8.30683 2.85008 8.45968 3.41992 8.90528 3.67723Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_496_15260">
          <rect width="14" height="14" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

import { IconProps } from ".";

export const ModelsIcon: React.FC<IconProps> = (props) => {
  return (
    <svg viewBox="0 0 16 18" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M8.17871 16.6208V9.7143C8.17871 9.35113 8.3756 9.01651 8.69307 8.84014L14.9491 5.36455C15.1491 5.25346 15.3948 5.39805 15.3948 5.6268V12.3502C15.3948 12.7055 15.2063 13.0341 14.8996 13.2134L8.63015 16.8798C8.43016 16.9967 8.17871 16.8525 8.17871 16.6208Z"
        fill="#9B9B9B"
      />
      <path
        d="M7.21582 16.6208V9.7143C7.21582 9.35113 7.01893 9.01651 6.70146 8.84014L0.445403 5.36455C0.245443 5.25346 -0.000290394 5.39805 -0.000290394 5.6268V12.3502C-0.000290394 12.7055 0.188211 13.0341 0.494897 13.2134L6.76438 16.8798C6.96437 16.9967 7.21582 16.8525 7.21582 16.6208Z"
        fill="#4C4C4C"
      />
      <path
        d="M8.19742 8.08471L14.5848 4.39468C14.7874 4.27765 14.784 3.98407 14.5788 3.87175L8.17729 0.368225C7.87816 0.204512 7.51622 0.204512 7.21709 0.368225L0.815587 3.87175C0.610354 3.98407 0.606964 4.27765 0.809548 4.39468L7.19696 8.08471C7.50648 8.26352 7.8879 8.26352 8.19742 8.08471Z"
        fill="currentColor"
      />
    </svg>
  );
};

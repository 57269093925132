import { IconProps } from ".";

export const CoinIcon: React.FC<IconProps> = (props) => {
  return (
    <svg
      viewBox="0 0 46 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      className={`min-w-[40px] ${props.className}`}
    >
      <path
        d="M3.18004 11.3337C1.21433 11.3337 0.117188 10.2213 0.117188 8.25556V3.74509C0.117188 1.77937 1.21433 0.666992 3.18004 0.666992H12.3686C12.5362 0.666992 12.6277 0.758421 12.6277 0.92604V1.41366C12.6277 1.58128 12.5362 1.67271 12.3686 1.67271H3.40862C2.00671 1.67271 1.26004 2.43461 1.26004 3.82128V8.17937C1.26004 9.56604 2.00671 10.3279 3.40862 10.3279H12.3686C12.5362 10.3279 12.6277 10.4194 12.6277 10.587V11.0746C12.6277 11.2422 12.5362 11.3337 12.3686 11.3337H3.18004Z"
        fill="url(#paint0_linear_7691_124199)"
      />
      <path
        d="M17.1511 11.3337C15.1854 11.3337 14.0883 10.2213 14.0883 8.25556V3.74509C14.0883 1.77937 15.1854 0.666992 17.1511 0.666992H24.3587C26.3244 0.666992 27.4368 1.77937 27.4368 3.74509V8.25556C27.4368 10.2213 26.3244 11.3337 24.3587 11.3337H17.1511ZM24.1454 10.3279C25.5321 10.3279 26.2787 9.56604 26.2787 8.17937V3.82128C26.2787 2.43461 25.5321 1.67271 24.1454 1.67271H17.3797C15.9778 1.67271 15.2311 2.43461 15.2311 3.82128V8.17937C15.2311 9.56604 15.9778 10.3279 17.3797 10.3279H24.1454Z"
        fill="url(#paint1_linear_7691_124199)"
      />
      <path
        d="M30.426 0.92604V11.0746C30.426 11.2422 30.3346 11.3337 30.1669 11.3337H29.5269C29.3593 11.3337 29.2679 11.2422 29.2679 11.0746V0.92604C29.2679 0.758421 29.3593 0.666992 29.5269 0.666992H30.1669C30.3346 0.666992 30.426 0.758421 30.426 0.92604Z"
        fill="url(#paint2_linear_7691_124199)"
      />
      <path
        d="M32.3294 3.74509C32.3294 1.77937 33.4418 0.666992 35.4075 0.666992H42.2647C44.2304 0.666992 45.3275 1.77937 45.3275 3.74509V11.0746C45.3275 11.2422 45.2361 11.3337 45.0685 11.3337H44.4437C44.2761 11.3337 44.1847 11.2422 44.1847 11.0746V3.82128C44.1847 2.43461 43.4228 1.67271 42.0361 1.67271H35.6209C34.2342 1.67271 33.4875 2.43461 33.4875 3.82128V11.0746C33.4875 11.2422 33.3961 11.3337 33.2285 11.3337H32.6037C32.4209 11.3337 32.3294 11.2422 32.3294 11.0746V3.74509Z"
        fill="url(#paint3_linear_7691_124199)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_7691_124199"
          x1="-3.16119"
          y1="2.5771"
          x2="31.911"
          y2="2.5771"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#A7A9AC" style={{ stopColor: "#A7A9AC", stopOpacity: "1" }} />
          <stop
            offset="0.0091653"
            stopColor="#AAACAF"
            style={{ stopColor: "#AAACAF", stopOpacity: "1" }}
          />
          <stop
            offset="0.1095"
            stopColor="#C9CACC"
            style={{ stopColor: "#C9CACC", stopOpacity: "1" }}
          />
          <stop
            offset="0.203"
            stopColor="#DFE0E1"
            style={{ stopColor: "#DFE0E1", stopOpacity: "1" }}
          />
          <stop
            offset="0.2859"
            stopColor="#ECEDEE"
            style={{ stopColor: "#ECEDEE", stopOpacity: "1" }}
          />
          <stop
            offset="0.35"
            stopColor="#F1F2F2"
            style={{ stopColor: "#F1F2F2", stopOpacity: "1" }}
          />
          <stop
            offset="0.4681"
            stopColor="#EAEBEC"
            style={{ stopColor: "#EAEBEC", stopOpacity: "1" }}
          />
          <stop
            offset="0.6561"
            stopColor="#D7D8DA"
            style={{ stopColor: "#D7D8DA", stopOpacity: "1" }}
          />
          <stop
            offset="0.8892"
            stopColor="#B8BABC"
            style={{ stopColor: "#B8BABC", stopOpacity: "1" }}
          />
          <stop offset="1" stopColor="#A7A9AC" style={{ stopColor: "#A7A9AC", stopOpacity: "1" }} />
        </linearGradient>
        <linearGradient
          id="paint1_linear_7691_124199"
          x1="-3.16119"
          y1="2.5771"
          x2="31.911"
          y2="2.5771"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#A7A9AC" style={{ stopColor: "#A7A9AC", stopOpacity: "1" }} />
          <stop
            offset="0.0091653"
            stopColor="#AAACAF"
            style={{ stopColor: "#AAACAF", stopOpacity: "1" }}
          />
          <stop
            offset="0.1095"
            stopColor="#C9CACC"
            style={{ stopColor: "#C9CACC", stopOpacity: "1" }}
          />
          <stop
            offset="0.203"
            stopColor="#DFE0E1"
            style={{ stopColor: "#DFE0E1", stopOpacity: "1" }}
          />
          <stop
            offset="0.2859"
            stopColor="#ECEDEE"
            style={{ stopColor: "#ECEDEE", stopOpacity: "1" }}
          />
          <stop
            offset="0.35"
            stopColor="#F1F2F2"
            style={{ stopColor: "#F1F2F2", stopOpacity: "1" }}
          />
          <stop
            offset="0.4681"
            stopColor="#EAEBEC"
            style={{ stopColor: "#EAEBEC", stopOpacity: "1" }}
          />
          <stop
            offset="0.6561"
            stopColor="#D7D8DA"
            style={{ stopColor: "#D7D8DA", stopOpacity: "1" }}
          />
          <stop
            offset="0.8892"
            stopColor="#B8BABC"
            style={{ stopColor: "#B8BABC", stopOpacity: "1" }}
          />
          <stop offset="1" stopColor="#A7A9AC" style={{ stopColor: "#A7A9AC", stopOpacity: "1" }} />
        </linearGradient>
        <linearGradient
          id="paint2_linear_7691_124199"
          x1="-3.16119"
          y1="2.5771"
          x2="31.911"
          y2="2.5771"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#A7A9AC" style={{ stopColor: "#A7A9AC", stopOpacity: "1" }} />
          <stop
            offset="0.0091653"
            stopColor="#AAACAF"
            style={{ stopColor: "#AAACAF", stopOpacity: "1" }}
          />
          <stop
            offset="0.1095"
            stopColor="#C9CACC"
            style={{ stopColor: "#C9CACC", stopOpacity: "1" }}
          />
          <stop
            offset="0.203"
            stopColor="#DFE0E1"
            style={{ stopColor: "#DFE0E1", stopOpacity: "1" }}
          />
          <stop
            offset="0.2859"
            stopColor="#ECEDEE"
            style={{ stopColor: "#ECEDEE", stopOpacity: "1" }}
          />
          <stop
            offset="0.35"
            stopColor="#F1F2F2"
            style={{ stopColor: "#F1F2F2", stopOpacity: "1" }}
          />
          <stop
            offset="0.4681"
            stopColor="#EAEBEC"
            style={{ stopColor: "#EAEBEC", stopOpacity: "1" }}
          />
          <stop
            offset="0.6561"
            stopColor="#D7D8DA"
            style={{ stopColor: "#D7D8DA", stopOpacity: "1" }}
          />
          <stop
            offset="0.8892"
            stopColor="#B8BABC"
            style={{ stopColor: "#B8BABC", stopOpacity: "1" }}
          />
          <stop offset="1" stopColor="#A7A9AC" style={{ stopColor: "#A7A9AC", stopOpacity: "1" }} />
        </linearGradient>
        <linearGradient
          id="paint3_linear_7691_124199"
          x1="-3.16119"
          y1="2.5771"
          x2="31.911"
          y2="2.5771"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#A7A9AC" style={{ stopColor: "#A7A9AC", stopOpacity: "1" }} />
          <stop
            offset="0.0091653"
            stopColor="#AAACAF"
            style={{ stopColor: "#AAACAF", stopOpacity: "1" }}
          />
          <stop
            offset="0.1095"
            stopColor="#C9CACC"
            style={{ stopColor: "#C9CACC", stopOpacity: "1" }}
          />
          <stop
            offset="0.203"
            stopColor="#DFE0E1"
            style={{ stopColor: "#DFE0E1", stopOpacity: "1" }}
          />
          <stop
            offset="0.2859"
            stopColor="#ECEDEE"
            style={{ stopColor: "#ECEDEE", stopOpacity: "1" }}
          />
          <stop
            offset="0.35"
            stopColor="#F1F2F2"
            style={{ stopColor: "#F1F2F2", stopOpacity: "1" }}
          />
          <stop
            offset="0.4681"
            stopColor="#EAEBEC"
            style={{ stopColor: "#EAEBEC", stopOpacity: "1" }}
          />
          <stop
            offset="0.6561"
            stopColor="#D7D8DA"
            style={{ stopColor: "#D7D8DA", stopOpacity: "1" }}
          />
          <stop
            offset="0.8892"
            stopColor="#B8BABC"
            style={{ stopColor: "#B8BABC", stopOpacity: "1" }}
          />
          <stop offset="1" stopColor="#A7A9AC" style={{ stopColor: "#A7A9AC", stopOpacity: "1" }} />
        </linearGradient>
      </defs>
    </svg>
  );
};

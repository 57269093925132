import { IoIcon } from "@/components/Icons/IoIcon";
import { Button } from "@/components/ui/button";
import { Separator } from "@/components/ui/separator";
import { Tool } from "@/types";
import { ArrowUpRightIcon } from "@heroicons/react/24/outline";

type ToolLogoProps = {
  tool: Tool;
  isVisibleIOIcon?: boolean;
  height?: string;
};

export const ToolLogo: React.FC<ToolLogoProps> = ({ tool, isVisibleIOIcon, height = "12px" }) => {
  return (
    <>
      {tool.href.indexOf("://") > -1 && (
        <Button
          size="icon"
          variant={"outline"}
          className="absolute right-[15px] top-[15px] size-[24px] rounded-full text-gray-dark-1000"
        >
          <ArrowUpRightIcon className="h-[11px]" />
        </Button>
      )}
      {tool.logoImage && (
        <div className="absolute bottom-0 left-0 right-0 top-0 flex flex-row items-center justify-center">
          <img className="h-[25px]" src={tool.logoImage} />
        </div>
      )}
      <div className="relative flex flex-row gap-2">
        {tool.logo && (
          <div className={`flex flex-row items-center gap-1`}>
            <IoIcon className="h-[10px]" />
            <Separator orientation="vertical" />
            {!isVisibleIOIcon && (
              <tool.logo data-testid={"ToolLogo_logo"} className={`h-[${height}]`} />
            )}
          </div>
        )}
        {tool.icon && <tool.icon className={`h-[${height}] py-[1px]`} />}
      </div>
    </>
  );
};

import { useMemo } from "react";
import { AuthMachineContext } from "@/store/machine/authMachine/authMachine";
import { getUserTimezone } from "@/utils/user";

export const useUserTimezone = () => {
  const profile = AuthMachineContext.useSelector((state) => state.context.profile);

  const userTimezone = useMemo(() => {
    return getUserTimezone(profile);
  }, [profile]);

  return userTimezone;
};
